import { RESPONSE_NG } from 'utils/constants'
import axios from './common/axios'
import { FACILITIES_BASE } from './common/url'
import { API_RESPONSE } from 'types/common/Api'
import { FacilityInfo } from 'types/infos/FacilityInfo'

export const findAll = async (): Promise<FacilityInfo[]> => {
  try {
    type retType = API_RESPONSE<FacilityInfo[]>
    const response = await axios.get<retType>(FACILITIES_BASE)

    if (response.data.status === RESPONSE_NG) {
      throw new Error(response.data.code.toString())
    }

    const data = response.data.data.map((d) => {
      d.createdAt = new Date(d.createdAt!)
      return d
    })
    return data
    //
  } catch (e) {
    console.log(e)
    throw e
  } finally {
    //
  }
}

/**
 *
 * @param params
 * @returns
 */
export const registerFacility = async (params: FacilityInfo) => {
  try {
    type retType = API_RESPONSE<void>
    const response = await axios.post<retType>(FACILITIES_BASE, params)

    if (response.data.status === RESPONSE_NG) {
      throw new Error(response.data.code.toString())
    }

    return response.data.data
    //
  } catch (e) {
    console.log(e)
    throw e
  } finally {
    //
  }
}

export const updateFacility = async (params: FacilityInfo) => {
  try {
    type retType = API_RESPONSE<void>
    const response = await axios.put<retType>(`${FACILITIES_BASE}/${params.facilityId}`, params)

    if (response.data.status === RESPONSE_NG) {
      throw new Error(response.data.code.toString())
    }

    return response.data.data
    //
  } catch (e) {
    console.log(e)
    throw e
  } finally {
    //
  }
}

export const deleteFacility = async (facilityId: number) => {
  try {
    type retType = API_RESPONSE<void>
    const response = await axios.delete<retType>(`${FACILITIES_BASE}/${facilityId}`)

    if (response.data.status === RESPONSE_NG) {
      throw new Error(response.data.code.toString())
    }

    return response.data.data
    //
  } catch (e) {
    console.log(e)
    throw e
  } finally {
    //
  }
}
