import { ReactNode, createContext, useContext, useState } from 'react'

/**
 * パスワード変更用グローバルStateContextのプロパティ定義.
 */
interface ChangePasswordStateProps {
  currPass: string
  newPass: string
  newPass2: string
  msg4CurrPass: string
  msg4NewPass: string
  msg4NewPass2: string
  setCurrPass: (inputPass: string) => void
  setNewPass: (inputPass: string) => void
  setNewPass2: (inputPass: string) => void
  update: () => void
  clear: () => void
  goBack: () => void
}

export const ChangePasswordStateContext = createContext({} as ChangePasswordStateProps)

export const useChangePasswordState = () => useContext(ChangePasswordStateContext)

/**
 *
 */
interface ChangePasswordStateProviderProps {
  children: ReactNode
}

/**
 *
 * @param props
 * @returns
 */
export const ChangePasswordStateProvider = (props: ChangePasswordStateProviderProps) => {
  const { children } = props

  const [currPass, setCurrPass] = useState('')
  const [newPass, setNewPass] = useState('')
  const [newPass2, setNewPass2] = useState('')

  const [msg4CurrPass, setMsg4CurrPass] = useState('')
  const [msg4NewPass, setMsg4NewPass] = useState('')
  const [msg4NewPass2, setMsg4NewPass2] = useState('')

  /**
   * 廃止された
   */
  const update = () => {
    // console.log("UPDATED.");
  }

  const clear = () => {
    setCurrPass(() => '')
    setNewPass(() => '')
    setNewPass2(() => '')
  }

  const goBack = () => {
    console.log('戻る')
  }

  const globalStates = {
    currPass,
    newPass,
    newPass2,
    msg4CurrPass,
    msg4NewPass,
    msg4NewPass2,
    setCurrPass,
    setNewPass,
    setNewPass2,
    setMsg4CurrPass,
    setMsg4NewPass,
    setMsg4NewPass2,
    update,
    clear,
    goBack
  }

  return (
    <ChangePasswordStateContext.Provider value={globalStates}>
      {children}
    </ChangePasswordStateContext.Provider>
  )
}
