import { Tooltip } from 'react-tooltip'
import styles from 'components/reservations/reservations/ReservationsForm.module.scss'
import { SuspendedInfo } from 'types/ui/SuspendedInfo'
import { toWarekiDatetime } from 'utils/dateUtil'
import { useWindowDimension } from 'utils/windowUtils'
import { useEffect, useState } from 'react'

interface Props {
  id: string | number
  rowIndex: number
  suspendedInfo: SuspendedInfo
}
interface CellStyle {
  top: string
  left: string
  width: string
}

/**
 *
 * @param props
 * @returns
 */
const SuspendedArea = (props: Props) => {
  const { id, rowIndex, suspendedInfo } = props

  const [winWidth] = useWindowDimension()
  const [style, setStyle] = useState<CellStyle>({ top: '0px', left: '0px', width: '0px' })

  useEffect(() => {
    const marginLeft = winWidth < 951 ? 180 : 280
    const style = {
      top: `${rowIndex * 40.5}px`,
      left: `${marginLeft + suspendedInfo.startUnit! * 20}px`,
      width: `${suspendedInfo.timeUnit! * 20}px`
    }
    setStyle(style)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [winWidth])

  return (
    <>
      <div
        className={styles.suspendedArea}
        style={style}
        data-tooltip-id={`suspended-area_${id}`}
      ></div>
      <Tooltip
        id={`suspended-area_${id}`}
        delayShow={100}
        place="top-start"
        style={{
          backgroundColor: 'gray',
          borderRadius: '10px',
          color: 'white',
          zIndex: '3'
        }}
      >
        <div>使用停止中：{suspendedInfo.reason}</div>
        <div>{toWarekiDatetime(suspendedInfo.suspendStartDatetime)}</div>
        <div>{toWarekiDatetime(suspendedInfo.suspendEndDatetime)}</div>
      </Tooltip>
    </>
  )
}

export default SuspendedArea
