import { API_RESPONSE } from 'types/common/Api'
import axios from './common/axios'
import { RESPONSE_NG } from 'utils/constants'
import { MailTemplateRow } from 'types/rows/MailTemplateRow'
import { MAIL_TEMPLATE_BASE } from './common/url'
import { MailTemplateInfo } from 'types/infos/MailTemplateInfo'

/**
 *
 * @returns
 */
export const findAll = async () => {
  try {
    type retType = API_RESPONSE<MailTemplateRow[]>
    const response = await axios.get<retType>(MAIL_TEMPLATE_BASE)

    if (response.data.status === RESPONSE_NG) {
      throw new Error(response.data.code.toString())
    }

    const data = response.data.data.map((d) => {
      return d
    })
    return data
    //
  } catch (e) {
    console.log(e)
    throw e
  } finally {
    //
  }
}

export const updateMailTemplate = async (params: MailTemplateInfo) => {
  try {
    type retType = API_RESPONSE<void>
    const response = await axios.put<retType>(
      `${MAIL_TEMPLATE_BASE}/${params.mailTemplateId}`,
      params
    )

    if (response.data.status === RESPONSE_NG) {
      throw new Error(response.data.code.toString())
    }

    return response.data.data
    //
  } catch (e) {
    console.log(e)
    throw e
  } finally {
    //
  }
}
