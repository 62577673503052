import { createContext, useContext, useEffect, useState } from 'react'

import { downloadFile, findAll } from 'api/downloadFiles'
import { useGlobalState } from 'components/common/provider/GlobalStateProvider'
import { ProviderProps } from 'types/common/ProviderProps'
import { DownloadFileInfo } from 'types/infos/DownloadFileInfo'
import { FATAL_ERROR_MESSAGE, FATAL_ERROR_TITLE } from 'utils/messageUtil'

interface DownloadFilesState {
  fileList: DownloadFileInfo[]
  isListEmpty: boolean
  selectFile: (file: DownloadFileInfo | null) => void
  refresh: () => void
  download: (fileInfo: DownloadFileInfo) => void
  dialogTitle: string
  dialogContents: string
  showErrorDialog: boolean
  closeErrorDialog: () => void
}

/**
 *
 */
export const DownloadFilesStateContext = createContext({} as DownloadFilesState)

/**
 *
 * @returns
 */
export const useDownloadFilesState = () => useContext(DownloadFilesStateContext)

/**
 *
 * @param props
 * @returns
 */
export const DownloadFilesStateProvider = (props: ProviderProps) => {
  //
  const { children } = props
  const gStates = useGlobalState()

  // State
  const [fileList, setFileList] = useState<DownloadFileInfo[]>([])
  const [targetFile, setTargetFile] = useState<DownloadFileInfo | null>(null)
  const [dialogTitle, setDialogTitle] = useState<string>('')
  const [dialogContents, setDialogContents] = useState<string>('')
  const [showErrorDialog, setShowErrorDialog] = useState<boolean>(false)

  const isListEmpty: boolean = fileList.length === 0

  useEffect(() => {
    //
    findAll(true)
      .then((list: DownloadFileInfo[]) => {
        setFileList(list)
      })
      .catch((e: unknown) => {
        // SessionTimeout処理
        if (gStates.handleSessionExpired(e)) return
        // システムエラーメッセージの表示
        showFatalErrorMessage()
      })
  }, [])

  const closeErrorDialog = () => {
    setShowErrorDialog(false)
  }

  /**
   * システムエラーメッセージを表示する
   */
  const showFatalErrorMessage = (): void => {
    setDialogTitle(FATAL_ERROR_TITLE)
    setDialogContents(FATAL_ERROR_MESSAGE)
    setShowErrorDialog(true)
  }

  const selectFile = (file: DownloadFileInfo | null) => {
    //
    setTargetFile(file)
  }

  const download = async (fileInfo: DownloadFileInfo) => {
    try {
      await downloadFile(fileInfo)
    } catch (e: unknown) {
      // SessionTimeout処理
      if (gStates.handleSessionExpired(e)) return
      // システムエラーメッセージの表示
      showFatalErrorMessage()
    }
  }

  const refresh = () => {
    //
  }

  // 外部公開ステート
  const globalStates = {
    fileList,
    isListEmpty,
    selectFile,
    refresh,
    download,
    dialogTitle,
    dialogContents,
    showErrorDialog,
    closeErrorDialog
  }

  return (
    <DownloadFilesStateContext.Provider value={globalStates}>
      {/*  */}
      {children}
    </DownloadFilesStateContext.Provider>
  )
}
