import { useLayoutEffect, useState } from 'react'

/**
 * Windowサイズの変更を検知して通知する。
 * @returns [width, height]の配列
 */
export const useWindowDimension = (): number[] => {
  const [dimension, setDimension] = useState<number[]>([0, 0])
  useLayoutEffect(() => {
    const updateDimension = (): void => {
      setDimension([window.innerWidth, window.innerHeight])
    }
    window.addEventListener('resize', updateDimension)
    updateDimension()
    return () => window.removeEventListener('resize', updateDimension)
  }, [])
  return dimension
}
