import { Helmet } from 'react-helmet-async'
import MailTemplatesForm from 'components/mailTemplates/mailTemplates/MailTemplatesForm'
import MailTemplatesStateProvider from 'components/mailTemplates/mailTemplates/MailTemplatesStateProvider'
import { TITLE_MANAGE_MAIL_TEMPLATE } from 'utils/constants'

const Notifications = () => {
  return (
    <MailTemplatesStateProvider>
      <Helmet>
        <title>{TITLE_MANAGE_MAIL_TEMPLATE}</title>
      </Helmet>
      <MailTemplatesForm />
    </MailTemplatesStateProvider>
  )
}

export default Notifications
