import axios from 'api/common/axios'
import { FACILITIES_ELEMENTS_URL } from 'api/common/url'
import { API_RESPONSE } from 'types/common/Api'
import { ListElement } from 'types/common/ListElement'
import { UNIT_TO_TIME_ARRAY } from 'utils/constants'
import { toTimeUnit } from 'utils/dateUtil'

// キャッシュ種別
const CacheTypeKey = {
  OFFICES: 'OFFICES',
  COOP_TYPES: 'COOP_TYPES',
  FACILITIES: 'FACILITIES'
}

// キャッシュ型
interface CacheType {
  [key: string]: Array<ListElement>
}

// データキャッシュ
const CACHE = {} as CacheType

/**
 *
 * @returns
 */
export const getCoopTypeList = async (): Promise<Array<ListElement>> => {
  const ret = CACHE[CacheTypeKey.COOP_TYPES]
  if (!ret) await refreshCache()
  return CACHE[CacheTypeKey.COOP_TYPES]
}

/**
 *
 * @returns
 */
export const getFacilityList = async (): Promise<Array<ListElement>> => {
  type resType = API_RESPONSE<ListElement[]>
  const res = await axios.get<resType>(FACILITIES_ELEMENTS_URL)
  return res.data.data
}

/**
 *
 * @returns
 */
export const getTimeUnits = (): Array<ListElement> => {
  return UNIT_TO_TIME_ARRAY
}

/**
 *
 * @param current
 * @returns
 */
export const getTimeUnitsFor = (targetDate: Date): Array<ListElement> => {
  const base = getTimeUnits().slice(1)
  const currentTimeUnit = Math.floor(toTimeUnit(targetDate))
  const maxCount = 48 - currentTimeUnit

  if (maxCount > base.length) return base
  return base.slice(0, maxCount)
}

/**
 *
 * @returns
 */
export const getMasterCache = async (): Promise<CacheType> => {
  // 施設情報（選択肢用）を取得
  const res2 = await axios.get<ListElement[]>(FACILITIES_ELEMENTS_URL)
  const facilities = res2.data

  // 企業種別情報（選択肢用）を取得
  const coopTypes = [
    { value: 1, label: '法人' },
    { value: 2, label: '個人事業主など' }
  ] as ListElement[]

  //
  const timeUnits = getTimeUnits()

  return {
    facilities,
    coopTypes,
    timeUnits
  }
}

/**
 *
 */
export const refreshCache = async (): Promise<void> => {
  if (CACHE[CacheTypeKey.FACILITIES]) return
  // 施設情報（選択肢用）をキャッシュ
  type resType = API_RESPONSE<ListElement[]>
  const res = await axios.get<resType>(FACILITIES_ELEMENTS_URL)
  CACHE[CacheTypeKey.FACILITIES] = res.data.data

  // 企業種別情報（選択肢用）をキャッシュ
  // * これは定数で良い、必要に応じてマスタ化する。
  CACHE[CacheTypeKey.COOP_TYPES] = [
    { value: 1, label: '法人' },
    { value: 2, label: '個人事業主など' }
  ] as ListElement[]
}

/**
 *
 */
export const clearCache = (): void => {
  Object.keys(CACHE).forEach((k: string) => {
    delete CACHE[k]
  })
}
