import { useState } from 'react'

import { useFacilitiesState } from './FacilitiesStateProvider'
import PageTitle from 'components/common/PageTitle'
import tStyles from 'styles/utils/table.module.scss'
import styles from './FacilitiesForm.module.scss'
import { FacilityStateProvider } from '../facility/FacilityStateProvider'
import FacilityDialog from 'components/common/dialogs/FacilityDialog'
import { EXEC_INSERT, EXEC_UPDATE } from 'utils/constants'
import { FacilityInfo } from 'types/infos/FacilityInfo'
import { EXEC_TYPE } from 'types/common/ExecType'
import { toWarekiDatetime } from 'utils/dateUtil'
import EmptyList from 'components/common/tables/EmptyList'
import ErrorDialog from 'components/common/dialogs/messages/ErrorDialog'

/**
 *
 * @returns
 */
const FacilitiesForm = () => {
  const states = useFacilitiesState()

  const [execType, setExecType] = useState<EXEC_TYPE>(EXEC_INSERT)
  const [open, setOpen] = useState<boolean>(false)

  const showDialogForInsert = () => {
    setExecType(() => EXEC_INSERT)
    openDialog()
  }

  const selectFacility = (facility: FacilityInfo) => {
    setExecType(EXEC_UPDATE)
    states.selectFacility(facility)
    openDialog()
  }

  const openDialog = () => {
    setOpen(() => true)
  }

  const closeDialog = () => {
    setOpen(() => false)
    states.refresh()
  }

  /**
   *
   */
  const closeErrorDialog = () => {
    states.closeErrorDialog()
  }

  return (
    <>
      <ErrorDialog
        title={states.dialogTitle}
        contents={states.dialogContents}
        show={states.showErrorDialog}
        onOk={closeErrorDialog}
      />
      <PageTitle title="施設一覧"></PageTitle>
      <div className={tStyles.tablePreInfo}>
        <div></div>
        <div>
          <button className={tStyles.insertButton} onClick={showDialogForInsert}>
            施設新規登録
          </button>
        </div>
      </div>
      <div className={tStyles.tableFrame}>
        <div className={`${tStyles.commonTable} ${styles.tableWidth}`}>
          <div className={tStyles.headerRow}>
            <div className={`${tStyles.headerColumn} ${styles.col1}`}>#</div>
            <div className={`${tStyles.headerColumn} ${styles.col2}`}>施設名</div>
            <div className={`${tStyles.headerColumn} ${styles.col3}`}>登録年月日</div>
            <div className={`${tStyles.headerColumn} ${styles.col4}`}>フロア</div>
            <div className={`${tStyles.headerColumn} ${styles.col5}`}>状態</div>
          </div>
          {states.facilityList.map((facility, index) => (
            <div
              className={tStyles.dataRow}
              key={facility.facilityId}
              onClick={() => selectFacility(facility)}
            >
              <div className={`${tStyles.dataColumn} ${styles.col1}`}>{facility.displayOrder}</div>
              <div className={`${tStyles.dataColumn} ${styles.col2}`}>{facility.facilityName}</div>
              <div className={`${tStyles.dataColumn} ${styles.col3}`}>
                {toWarekiDatetime(facility.createdAt!)}
              </div>
              <div className={`${tStyles.dataColumn} ${styles.col4}`}>{facility.floorNum}F</div>
              <div className={`${tStyles.dataColumn} ${styles.col5}`}>
                {facility.isAvailable ? '公開中' : '使用不可'}
              </div>
            </div>
          ))}
          <EmptyList isEmpty={states.isListEmpty} />
        </div>
      </div>
      {/* ==== オフィス登録ダイアログ ==== */}
      {/* ダイアログ全体でStateを使いたい.ここでProviderで提供しておく */}
      <FacilityStateProvider>
        <FacilityDialog
          execType={execType}
          facilityInfo={states.targetFacility!}
          show={open}
          onClose={closeDialog}
        />
      </FacilityStateProvider>
      {/* ここまで */}
    </>
  )
}

export default FacilitiesForm
