import { useLoginState } from 'components/common/provider/LoginStateProvider'
import AdminLayout from 'layouts/AdminLayout'
import ContractorLayout from 'layouts/ContractorLayout'

/**
 *
 * @returns
 */
const CommonLayout = () => {
  const { isAdmin } = useLoginState()

  return <>{isAdmin() ? <AdminLayout /> : <ContractorLayout />}</>
}

export default CommonLayout
