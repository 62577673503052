import styles from 'components/common/tables/ReservationTableHeader.module.scss'

const ReservationTableHeader = () => {
  const colCount = 24

  return (
    <>
      <div className={styles.headerRow}>
        {[...Array(colCount)].map((_, idx) => (
          <div className={styles.col} key={`reaservation-table-header-${idx}`}>
            {idx}
          </div>
        ))}
      </div>
    </>
  )
}

export default ReservationTableHeader
