import { Helmet } from 'react-helmet-async'
import ReservationSuspendsForm from 'components/facilities/suspends/ReservationSuspendsForm'
import { ReservationSuspendsStateProvider } from 'components/facilities/suspends/ReservationSuspendsStateProvider'
import { TITLE_MANAGE_FACILITY_SUSPEND } from 'utils/constants'

/**
 *
 * @returns
 */
const FacilitySuspends = () => {
  return (
    <ReservationSuspendsStateProvider>
      <Helmet>
        <title>{TITLE_MANAGE_FACILITY_SUSPEND}</title>
      </Helmet>
      <ReservationSuspendsForm />
    </ReservationSuspendsStateProvider>
  )
}

export default FacilitySuspends
