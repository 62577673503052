import styles from 'components/mailTemplates/mailTemplate/PrecedentsForm.module.scss'
import tStyles from 'styles/utils/table.module.scss'

const PrecedentsForm = () => {
  return (
    <div className={tStyles.tableFrame}>
      <div className={tStyles.commonTable} style={{ width: '100%' }}>
        <div className={tStyles.headerRow}>
          <div className={`${tStyles.headerColumn} ${styles.col1}`}>#</div>
          <div className={`${tStyles.headerColumn} ${styles.col2}`}>記号</div>
          <div className={`${tStyles.headerColumn} ${styles.col3}`}>出力項目</div>
          <div className={`${tStyles.headerColumn} ${styles.col4}`}>表示内容</div>
        </div>
        <div className={tStyles.dataRow}>
          <div className={`${tStyles.dataColumn} ${styles.col1}`}>1</div>
          <div className={`${tStyles.dataColumn} ${styles.col2}`}>
            &lt;{'{'}fname{'}'}&gt;
          </div>
          <div className={`${tStyles.dataColumn} ${styles.col3}`}>施設名称</div>
          <div className={`${tStyles.dataColumn} ${styles.col4}`}>
            対象施設の名称
            <br />
            (予約変更時)変更後の対象施設の名称
          </div>
        </div>
        <div className={tStyles.dataRow}>
          <div className={`${tStyles.dataColumn} ${styles.col1}`}>2</div>
          <div className={`${tStyles.dataColumn} ${styles.col2}`}>
            &lt;{'{'}p_fname{'}'}&gt;
          </div>
          <div className={`${tStyles.dataColumn} ${styles.col3}`}>(変更前)施設名称</div>
          <div className={`${tStyles.dataColumn} ${styles.col4}`}>
            (予約変更時)変更前の対象施設の名称
          </div>
        </div>
        <div className={tStyles.dataRow}>
          <div className={`${tStyles.dataColumn} ${styles.col1}`}>3</div>
          <div className={`${tStyles.dataColumn} ${styles.col2}`}>
            &lt;{'{'}uCount{'}'}&gt;
          </div>
          <div className={`${tStyles.dataColumn} ${styles.col3}`}>使用人数</div>
          <div className={`${tStyles.dataColumn} ${styles.col4}`}>
            使用人数の値
            <br />
            (予約変更時)変更後の使用人数の値
          </div>
        </div>
        <div className={tStyles.dataRow}>
          <div className={`${tStyles.dataColumn} ${styles.col1}`}>4</div>
          <div className={`${tStyles.dataColumn} ${styles.col2}`}>
            &lt;{'{'}p_uCount{'}'}&gt;
          </div>
          <div className={`${tStyles.dataColumn} ${styles.col3}`}>(変更前)使用人数</div>
          <div className={`${tStyles.dataColumn} ${styles.col4}`}>
            (予約変更時)変更前の使用人数の値
          </div>
        </div>
        <div className={tStyles.dataRow}>
          <div className={`${tStyles.dataColumn} ${styles.col1}`}>5</div>
          <div className={`${tStyles.dataColumn} ${styles.col2}`}>
            &lt;{'{'}sdate{'}'}&gt;
          </div>
          <div className={`${tStyles.dataColumn} ${styles.col3}`}>利用時間開始時刻</div>
          <div className={`${tStyles.dataColumn} ${styles.col4}`}>
            利用時間の開始時刻
            <br />
            (予約変更時)変更後の利用時間の開始時刻
          </div>
        </div>
        <div className={tStyles.dataRow}>
          <div className={`${tStyles.dataColumn} ${styles.col1}`}>6</div>
          <div className={`${tStyles.dataColumn} ${styles.col2}`}>
            &lt;{'{'}edate{'}'}&gt;
          </div>
          <div className={`${tStyles.dataColumn} ${styles.col3}`}>利用時間終了時刻</div>
          <div className={`${tStyles.dataColumn} ${styles.col4}`}>
            利用時間の終了時刻
            <br />
            (予約変更時)変更後の利用時間の終了時刻
          </div>
        </div>
        <div className={tStyles.dataRow}>
          <div className={`${tStyles.dataColumn} ${styles.col1}`}>7</div>
          <div className={`${tStyles.dataColumn} ${styles.col2}`}>
            &lt;{'{'}p_sdate{'}'}&gt;
          </div>
          <div className={`${tStyles.dataColumn} ${styles.col3}`}>(変更前)利用時間開始時刻</div>
          <div className={`${tStyles.dataColumn} ${styles.col4}`}>
            (予約変更時)変更前の利用時間の開始時刻
          </div>
        </div>
        <div className={tStyles.dataRow}>
          <div className={`${tStyles.dataColumn} ${styles.col1}`}>8</div>
          <div className={`${tStyles.dataColumn} ${styles.col2}`}>
            &lt;{'{'}p_edate{'}'}&gt;
          </div>
          <div className={`${tStyles.dataColumn} ${styles.col3}`}>(変更前)利用時間終了時刻</div>
          <div className={`${tStyles.dataColumn} ${styles.col4}`}>
            (予約変更時)変更前の利用時間の終了時刻
          </div>
        </div>
        <div className={tStyles.dataRow}>
          <div className={`${tStyles.dataColumn} ${styles.col1}`}>8</div>
          <div className={`${tStyles.dataColumn} ${styles.col2}`}>
            &lt;{'{'}name{'}'}&gt;
          </div>
          <div className={`${tStyles.dataColumn} ${styles.col3}`}>申請者氏名</div>
          <div className={`${tStyles.dataColumn} ${styles.col4}`}>
            申請者の氏名
            <br />
            (予約変更時)変更後の申請者の氏名
          </div>
        </div>
        <div className={tStyles.dataRow}>
          <div className={`${tStyles.dataColumn} ${styles.col1}`}>9</div>
          <div className={`${tStyles.dataColumn} ${styles.col2}`}>
            &lt;{'{'}p_name{'}'}&gt;
          </div>
          <div className={`${tStyles.dataColumn} ${styles.col3}`}>(変更前)申請者氏名</div>
          <div className={`${tStyles.dataColumn} ${styles.col4}`}>
            (予約変更時)変更前の申請者の氏名
          </div>
        </div>
        <div className={tStyles.dataRow}>
          <div className={`${tStyles.dataColumn} ${styles.col1}`}>10</div>
          <div className={`${tStyles.dataColumn} ${styles.col2}`}>
            &lt;{'{'}email{'}'}&gt;
          </div>
          <div className={`${tStyles.dataColumn} ${styles.col3}`}>申請者メールアドレス</div>
          <div className={`${tStyles.dataColumn} ${styles.col4}`}>
            申請者のメールアドレス
            <br />
            (変更処理時は)変更後の申請者のメールアドレス
          </div>
        </div>
        <div className={tStyles.dataRow}>
          <div className={`${tStyles.dataColumn} ${styles.col1}`}>11</div>
          <div className={`${tStyles.dataColumn} ${styles.col2}`}>
            &lt;{'{'}p_email{'}'}&gt;
          </div>
          <div className={`${tStyles.dataColumn} ${styles.col3}`}>(変更前)申請者メールアドレス</div>
          <div className={`${tStyles.dataColumn} ${styles.col4}`}>
            (予約変更時)変更前の申請者のメールアドレス
          </div>
        </div>
        <div className={tStyles.dataRow}>
          <div className={`${tStyles.dataColumn} ${styles.col1}`}>12</div>
          <div className={`${tStyles.dataColumn} ${styles.col2}`}>
            &lt;{'{'}detail{'}'}&gt;
          </div>
          <div className={`${tStyles.dataColumn} ${styles.col3}`}>予約概要</div>
          <div className={`${tStyles.dataColumn} ${styles.col4}`}>
            予約概要
            <br />
            (予約変更時)変更後の予約概要
          </div>
        </div>
        <div className={tStyles.dataRow}>
          <div className={`${tStyles.dataColumn} ${styles.col1}`}>13</div>
          <div className={`${tStyles.dataColumn} ${styles.col2}`}>
            &lt;{'{'}p_detail{'}'}&gt;
          </div>
          <div className={`${tStyles.dataColumn} ${styles.col3}`}>(変更前)予約概要</div>
          <div className={`${tStyles.dataColumn} ${styles.col4}`}>(予約変更時)変更前の予約概要</div>
        </div>
        <div className={tStyles.dataRow}>
          <div className={`${tStyles.dataColumn} ${styles.col1}`}>14</div>
          <div className={`${tStyles.dataColumn} ${styles.col2}`}>
            &lt;{'{'}note{'}'}&gt;
          </div>
          <div className={`${tStyles.dataColumn} ${styles.col3}`}>連絡事項</div>
          <div className={`${tStyles.dataColumn} ${styles.col4}`}>
            連絡事項
            <br />
            (予約変更時)変更後の連絡事項
          </div>
        </div>
        <div className={tStyles.dataRow}>
          <div className={`${tStyles.dataColumn} ${styles.col1}`}>15</div>
          <div className={`${tStyles.dataColumn} ${styles.col2}`}>
            &lt;{'{'}p_note{'}'}&gt;
          </div>
          <div className={`${tStyles.dataColumn} ${styles.col3}`}>(変更前)連絡事項</div>
          <div className={`${tStyles.dataColumn} ${styles.col4}`}>(予約変更時)変更前の連絡事項</div>
        </div>
      </div>
    </div>
  )
}

export default PrecedentsForm
