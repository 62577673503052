import { createContext, useContext, useEffect, useState } from 'react'
import { findAll } from 'api/reservationSuspended'
import { useGlobalState } from 'components/common/provider/GlobalStateProvider'
import { ProviderProps } from 'types/common/ProviderProps'
import { ReservationSuspendListElement } from 'types/ui/ReservationSuspendListElement'
import { FATAL_ERROR_MESSAGE, FATAL_ERROR_TITLE } from 'utils/messageUtil'

interface ReservationSuspendsState {
  suspendedList: ReservationSuspendListElement[]
  targetReservationSuspend: ReservationSuspendListElement | null
  isListEmpty: boolean
  selectReservationSuspend: (val: ReservationSuspendListElement) => void
  refresh: () => void
  dialogTitle: string
  dialogContents: string
  showErrorDialog: boolean
  closeErrorDialog: () => void
}

/**
 *
 */
export const ReservationSuspendsStateContext = createContext({} as ReservationSuspendsState)

/**
 *
 * @returns
 */
export const useReservationSuspendsState = () => useContext(ReservationSuspendsStateContext)

/**
 *
 * @param props
 * @returns
 */
export const ReservationSuspendsStateProvider = (props: ProviderProps) => {
  const { children } = props
  const gStates = useGlobalState()

  // State
  const [suspendedList, setSuspendedList] = useState<ReservationSuspendListElement[]>([])
  const [targetReservationSuspend, setSelectReservationSuspend] =
    useState<ReservationSuspendListElement | null>(null)
  const [dialogTitle, setDialogTitle] = useState<string>('')
  const [dialogContents, setDialogContents] = useState<string>('')
  const [showErrorDialog, setShowErrorDialog] = useState<boolean>(false)

  const isListEmpty: boolean = suspendedList.length === 0

  const selectReservationSuspend = (val: ReservationSuspendListElement) => {
    setSelectReservationSuspend(val)
  }

  const refresh = () => {
    findAll()
      .then((list) => {
        setSuspendedList(list)
      })
      .catch((e: unknown) => {
        // SessionTimeout処理
        if (gStates.handleSessionExpired(e)) return
        // システムエラーメッセージの表示
        showFatalErrorMessage()
      })
  }

  const closeErrorDialog = () => {
    setShowErrorDialog(false)
  }

  /**
   * システムエラーメッセージを表示する
   */
  const showFatalErrorMessage = (): void => {
    setDialogTitle(FATAL_ERROR_TITLE)
    setDialogContents(FATAL_ERROR_MESSAGE)
    setShowErrorDialog(true)
  }

  // 初期表示処理
  useEffect(() => {
    refresh()
  }, [])

  const globalStates = {
    suspendedList,
    targetReservationSuspend,
    isListEmpty,
    selectReservationSuspend,
    refresh,
    dialogTitle,
    dialogContents,
    showErrorDialog,
    closeErrorDialog
  } as ReservationSuspendsState

  return (
    <ReservationSuspendsStateContext.Provider value={globalStates}>
      {/*  */}
      {children}
    </ReservationSuspendsStateContext.Provider>
  )
}
