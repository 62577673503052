/* eslint-disable jsx-a11y/anchor-is-valid */
import { ReactNode } from 'react'
import styles from 'components/reservations/reservations/ReservationsForm.module.scss'
import ReservationCell from './ReservationCell'
import { ReservationInsertInfo } from 'types/ui/ReservedInfo'
import { DateListElement } from 'types/rows/DateListElement'
import { calcSelectDatetime, isSameDay, toTimeUnit } from 'utils/dateUtil'

interface Props {
  children?: ReactNode
  facilityId: number
  items: DateListElement[]
  selectDate: (date: Date) => void
  onClick?: (params: ReservationInsertInfo) => void
}

/**
 *
 * @param param0
 * @returns
 */
const ReservationDateTable = ({ children, facilityId, items, selectDate, onClick }: Props) => {
  const rowCount = items.length
  const colCount = 24 * (60 / 30)

  /**
   *
   * @param rowIndex
   * @param colIndex
   */
  const clickHandler = (rowIndex: number, colIndex: number) => {
    // デフォルトの予約時間数は１時間
    const timeUnit = 2
    // 月日は行の年月日を採用
    const date = items[rowIndex].date
    // colIndexと対象年月日から予約日時を解決
    const reservationDatetime = calcSelectDatetime(date, colIndex)

    const params: ReservationInsertInfo = {
      facilityId,
      reservationDatetime,
      timeUnit
    }

    onClick!(params)
  }

  /**
   * 指定された日付（予約一覧の行）のどこまでの時間帯までが使用不可であるか？を算出する。
   * @param target
   * @returns
   */
  const disabledIndex = (target: Date) => {
    const current = new Date()
    // 対象日が操作日なら、現在の時刻を含まない30分単位の時刻単位を返却
    if (isSameDay(target, current)) return Math.floor(toTimeUnit(current)) - 1
    // 操作日以外の場合、操作日の前日なら全てのセルは使用不可（48）、後日なら全てのセルは使用可能（-1）
    return target.getTime() < current.getTime() ? 48 : -1
  }

  return (
    <div className={styles.bodyRows}>
      {[...Array(rowCount)].map((_, rIdx) => (
        <div className={styles.bodyRow} key={`reservation-table-row-${rIdx}`}>
          <div className={`${styles.body} ${styles.colFix1} ${styles.index}`}>{rIdx + 1}</div>
          <div className={`${styles.body} ${styles.colFix2} ${styles.titleColumn}`}>
            <a href="#" onClick={(_) => selectDate(items[rIdx].date)}>
              {items[rIdx].wareki}
            </a>
          </div>
          <div className={styles.timeCellArea}>
            {[...Array(colCount)].map((_, cIdx) => (
              <ReservationCell
                rowIndex={rIdx}
                colIndex={cIdx}
                dateInfo={items[rIdx]}
                onClick={clickHandler}
                disabledIndex={disabledIndex(items[rIdx].date)}
                key={`reservation-table-cell-${rIdx}-${cIdx}`}
              />
            ))}
          </div>
        </div>
      ))}
      {children}
    </div>
  )
}

export default ReservationDateTable
