import { Helmet } from 'react-helmet-async'
import LoginForm from 'components/login/LoginUiForm'
import { LoginStateProvider } from 'components/login/LoginUiStateProvider'
import { TITLE_LOGIN } from 'utils/constants'

/**
 * ログイン画面
 */
const Login = () => {
  return (
    <LoginStateProvider>
      <Helmet>
        <title>{TITLE_LOGIN}</title>
      </Helmet>
      <LoginForm />
    </LoginStateProvider>
  )
}

export default Login
