/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'

import ReservationArea from 'components/common/tables/ReservationArea'
import { useFacilityReservationsState } from './FacilityReservationsStateProvider'
import PageTitle from 'components/common/PageTitle'
import D1pDatePicker from 'components/common/atoms/D1pDatePicker'
import DropDown from 'components/common/atoms/DropDown'
import styles from 'components/reservations/reservations/ReservationsForm.module.scss'
import ReservationTableHeader from 'components/common/tables/ReservationTableHeader'
import ReservationTable from 'components/common/tables/ReservationDateTable'
import { EXEC_INSERT, EXEC_UPDATE } from 'utils/constants'
import SuspendedArea from 'components/common/tables/SuspendedArea'
import { ReservationInsertInfo, ReservedInfo } from 'types/ui/ReservedInfo'
import { EXEC_TYPE } from 'types/common/ExecType'
import { ReservationDialogStateProvider } from 'components/reservations/reservation/ReservationStateProvider'
import ReservationDialog from 'components/common/dialogs/ReservationDialog'
import { isSameDay, nearestDatetime, toTimeUnit } from 'utils/dateUtil'
import { useWindowDimension } from 'utils/windowUtils'
import ErrorDialog from 'components/common/dialogs/messages/ErrorDialog'

/**
 *
 * @returns
 */
const FacilityReservationsForm = () => {
  //
  const navigate = useNavigate()
  const [params] = useSearchParams()
  const states = useFacilityReservationsState()
  const [winWidth] = useWindowDimension()

  const [open, setOpen] = useState(false)
  const [execType, setExecType] = useState<EXEC_TYPE>(EXEC_INSERT)
  const [insertParams, setInsertParams] = useState<ReservationInsertInfo | null>(null)
  const [ddFontSize, setDdFontSize] = useState<number>(20)
  const [ddWidth, setDdWidth] = useState<number>(250)
  const [linkCaptions, setLinkCaptions] = useState<string[]>(['当月', '翌月', '翌々月'])
  const [insertBtnCaption, setInsertButtonCaption] = useState<string>('')

  const [dialogTitle, setDialogTitle] = useState<string>('')
  const [dialogContents, setDialogContents] = useState<string>('')
  const [showErrorDialog, setShowErrorDialog] = useState<boolean>(false)

  useEffect(() => {
    const id = params.get('id')
    const facilityId = id === null ? null : parseInt(id, 10)
    states.changeTargetFacilityId(facilityId)
  }, [])

  useEffect(() => {
    setDdWidth(winWidth < 600 ? 160 : winWidth < 800 ? 200 : 250)
    setDdFontSize(winWidth < 800 ? 16 : 20)
    setLinkCaptions(winWidth < 700 ? ['当月', '>', '>>'] : ['当月', '翌月', '翌々月'])
    setInsertButtonCaption(winWidth < 600 ? '＋' : '予約新規登録')
  }, [winWidth])

  /**
   * 「新規予約登録」イベントハンドラ
   */
  const showDialogForCreate = () => {
    setExecType(() => EXEC_INSERT)
    setInsertParams({
      facilityId: 0,
      reservationDatetime: nearestDatetime(new Date()),
      timeUnit: 2
    })
    openDialog()
  }

  /**
   * 「テーブルセル（予約なし）」クリックイベントハンドラ
   * @param params
   */
  const showDialogForInsert = (params: ReservationInsertInfo) => {
    if (states.checkCurrentTime(params.reservationDatetime) === false) {
      return
    }
    setExecType(() => EXEC_INSERT)
    setInsertParams(params)
    openDialog()
  }

  /**
   * 「年月日」列リンククリックイベントハンドラ
   * @param targetDate
   */
  const selectDate = (targetDate: Date) => {
    const time = targetDate.getTime()
    navigate(`/reservations/daily?t=${time}`)
  }

  /**
   * 「予約済みセル」クリックイベントハンドラ
   * @param params
   */
  const showDialogForUpdate = (params: ReservedInfo) => {
    setExecType(() => EXEC_UPDATE)
    states.selectTargetReservation(params)
    openDialog()
  }

  const openDialog = () => {
    setOpen(() => true)
  }

  const onCloseReservationDialog = async () => {
    setOpen(() => false)
    states.refreshAll()
  }

  const goNextMonth = async () => {
    try {
      states.goNextMonth()
    } finally {
    }
  }

  /**
   * 指定された日付（予約一覧の行）のどこまでの時間帯までが使用不可であるか？を算出する。
   * @param target
   * @returns
   */
  const disabledIndex = (target: Date) => {
    const current = new Date()
    // 対象日が操作日なら、現在の時刻を含まない30分単位の時刻単位を返却
    if (isSameDay(target, current)) return Math.floor(toTimeUnit(current)) - 1
    // 操作日以外の場合、操作日の前日なら全てのセルは使用不可（48）、後日なら全てのセルは使用可能（-1）
    return target.getTime() < current.getTime() ? 48 : -1
  }

  const closeErrorDialog = () => {
    states.closeErrorDialog()
  }

  return (
    <>
      <ErrorDialog
        title={states.dialogTitle}
        contents={states.dialogContents}
        show={states.showErrorDialog}
        onOk={closeErrorDialog}
      />
      <PageTitle title="施設別予約一覧" />
      <div className={styles.tablePreInfo}>
        {/* <div>予約したい時間帯をクリックして下さい</div> */}
        <div>
          <DropDown
            id="targetFacilityId"
            size={ddWidth}
            fontSize={ddFontSize}
            list={states.facilitiesList}
            value={states.targetFacilityId ? states.targetFacilityId.toString() : ''}
            onChange={(event) => states.changeTargetFacilityId(parseInt(event.target.value, 10))}
          />
        </div>
        <div className={styles.controlContainer}>
          <div className={styles.link}>
            <a href="#" onClick={states.goCurrentMonth}>
              {linkCaptions[0]}
            </a>
          </div>
          <div className={styles.link}>
            <a href="#" onClick={goNextMonth}>
              {linkCaptions[1]}
            </a>
          </div>
          <div className={styles.link}>
            <a href="#" onClick={states.goMonthAfterNext}>
              {linkCaptions[2]}
            </a>
          </div>
          <div className={styles.datePicker}>
            <D1pDatePicker
              id="targetDate"
              showYearMonth
              width={100}
              selected={states.targetMonth}
              onChange={states.changeTargetMonth}
            />
          </div>
        </div>
        <div>
          <button className={styles.insertButton} onClick={showDialogForCreate}>
            {insertBtnCaption}
          </button>
        </div>
      </div>
      <div className={styles.tableFrame}>
        <div className={styles.table}>
          {/* テーブルヘッダー行 */}
          <div className={styles.headerRow}>
            <div className={`${styles.header} ${styles.colFix1} ${styles.index}`}>
              <div className={styles.headerCell}>#</div>
            </div>
            <div className={`${styles.header} ${styles.colFix2} ${styles.titleColumn}`}>
              <div className={styles.headerCell}>年月日</div>
            </div>
            <div className={`${styles.header} ${styles.reservationList}`}>
              <ReservationTableHeader />
            </div>
          </div>
          {/* テーブルデータ行 */}
          <ReservationTable
            facilityId={states.targetFacilityId ?? 0}
            items={states.dateList}
            selectDate={selectDate}
            onClick={showDialogForInsert}
          >
            {/* 予約済みセルの描画 */}
            {states.reservedList.map((r, index) => (
              <ReservationArea
                key={`daily-reservation-${index}`}
                id={index}
                rowIndex={r.rowIndex}
                type="facility"
                reservation={r}
                disabledIndex={disabledIndex(r.reservationDatetime)}
                onClick={showDialogForUpdate}
              />
            ))}
            {/* 利用停止セルの描画 */}
            {states.suspendedList.map((s, index) => (
              <SuspendedArea
                key={`suspended-${index}`}
                id={index}
                rowIndex={s.rowIndex!}
                suspendedInfo={s}
              />
            ))}
          </ReservationTable>
        </div>
      </div>

      {/* ==== 施設予約登録ダイアログ ==== */}
      {/* ダイアログ全体でStateを使いたい.ここでProviderで提供しておく */}
      <ReservationDialogStateProvider>
        <ReservationDialog
          show={open}
          insertParams={insertParams}
          updateParams={states.targetReservation}
          execType={execType}
          onClose={onCloseReservationDialog}
        />
      </ReservationDialogStateProvider>
      {/* ここまで */}
    </>
  )
}

export default FacilityReservationsForm
