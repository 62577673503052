import { createContext, useContext, useState } from 'react'

import { deleteNotification, registerNotification, updateNotification } from 'api/notifications'
import { useGlobalState } from 'components/common/provider/GlobalStateProvider'
import { NotificationInfo } from 'types/infos/NotificationInfo'
import { EXEC_TYPE } from 'types/common/ExecType'
import { ProviderProps } from 'types/common/ProviderProps'
import { NotificationRow } from 'types/rows/NotificationRow'
import { EXEC_DELETE, EXEC_INSERT, EXEC_UPDATE } from 'utils/constants'
import {
  COMMON_ERROR_MESSAGE,
  CONTAIN_ERROR_TITLE,
  FATAL_ERROR_MESSAGE,
  FATAL_ERROR_TITLE,
  getConfirmMessage,
  getConfirmTitle,
  getNotifyMessage,
  getNotifyTitle,
  lengthLessThanMessage,
  requiredMessage
} from 'utils/messageUtil'
import { hasNotValue, invalidMaxLength } from 'utils/validators'
import { toWarekiDatetime } from 'utils/dateUtil'

interface NotificationState {
  categoryList: any[]

  notificationId: number | null
  contractorId: number | null
  categoryId: number
  title: string
  description: string
  updatedAtString: string

  msg4CategoryId: string
  msg4Title: string
  msg4Description: string

  dialogTitle: string
  dialogContents: string
  showErrorDialog: boolean
  showConfirmDialog: boolean
  showNotifyDialog: boolean

  changeCategoryId: (val: number) => void
  changeTitle: (val: string) => void
  changeDescription: (val: string) => void

  closeErrorDialog: () => void
  closeConfirmDialog: () => void
  closeNotifyDialog: () => void

  refresh: (type: EXEC_TYPE, info?: NotificationRow) => void
  insert: () => void
  update: () => void
  remove: () => void
  clear: () => void
  doCallback: () => Promise<void>
}

export const NotificationStateContext = createContext({} as NotificationState)

export const useNotificationState = () => useContext(NotificationStateContext)

export const NotificationStateProvider = (props: ProviderProps) => {
  const { children } = props
  const gStates = useGlobalState()

  // State
  const [categoryList, setCategoryList] = useState<any[]>([])

  const [openMode, setOpenMode] = useState<EXEC_TYPE>(EXEC_INSERT)
  const [execType, setExecType] = useState<EXEC_TYPE | null>(null)
  const [originalNotification, setOriginalNotification] = //
    useState<NotificationRow | null>(null)

  const [notificationId, setNotificationId] = useState<number | null>(null)
  const [contractorId, setContractorId] = useState<number | null>(null)
  const [categoryId, setCategoryId] = useState<number>(0)
  const [title, setTitle] = useState<string>('')
  const [description, setDescription] = useState<string>('')
  const [updatedAt, setUpdatedAt] = useState<Date | null>(null)

  const [msg4CategoryId, setMsg4CategoryId] = useState<string>('')
  const [msg4Title, setMsg4Title] = useState<string>('')
  const [msg4Description, setMsg4Description] = useState<string>('')

  const [dialogTitle, setDialogTitle] = useState<string>('')
  const [dialogContents, setDialogContents] = useState<string>('')
  const [showErrorDialog, setShowErrorDialog] = useState<boolean>(false)
  const [showConfirmDialog, setShowConfirmDialog] = useState<boolean>(false)
  const [showNotifyDialog, setShowNotifyDialog] = useState<boolean>(false)

  const updatedAtString = updatedAt ? toWarekiDatetime(updatedAt) : ''

  //
  const changeCategoryId = (val: number) => {
    //
  }

  const changeTitle = (val: string) => {
    setTitle(val)
    setMsg4Title('')
  }
  const changeDescription = (val: string) => {
    setDescription(val)
    setMsg4Description('')
  }

  const closeErrorDialog = (): void => {
    setShowErrorDialog(false)
  }
  const closeConfirmDialog = (): void => {
    setShowConfirmDialog(false)
  }
  const closeNotifyDialog = (): void => {
    setShowNotifyDialog(false)
  }

  /**
   * システムエラーメッセージを表示する
   */
  const showFatalErrorMessage = (): void => {
    setDialogTitle(FATAL_ERROR_TITLE)
    setDialogContents(FATAL_ERROR_MESSAGE)
    setShowErrorDialog(true)
  }

  /**
   *
   * @param type
   * @param info
   * @returns
   */
  const refresh = (type: EXEC_TYPE, info?: NotificationRow) => {
    setOpenMode(type)

    if (type === EXEC_INSERT) {
      setOriginalNotification(null)

      setNotificationId(null)
      setContractorId(null)
      setCategoryId(0)
      setTitle('')
      setDescription('')
      setUpdatedAt(null)
      return
    }

    if (type === EXEC_UPDATE) {
      setOriginalNotification(info!)

      setNotificationId(info!.notificationId)
      setContractorId(info!.contractorId ?? null)
      setCategoryId(info!.categoryId)
      setTitle(info!.title)
      setDescription(info!.description)
      setUpdatedAt(new Date(info!.updatedAt))
    }
  }

  /**
   *
   * @returns
   */
  const insert = () => {
    if (checkAll() === false) {
      showErrorMessage()
      return
    }
    // 実行確認
    setExecType(EXEC_INSERT)
    showConfirmMessage(EXEC_INSERT)
  }
  const doInsert = async () => {
    closeConfirmDialog()
    const params = createParameters()
    try {
      const result = await registerNotification(params)
      showNotifyMessage(EXEC_INSERT)
    } catch (e) {
      // SessionTimeout処理
      if (gStates.handleSessionExpired(e)) return
      // 致命的エラー処理
      showFatalErrorMessage()
    }
  }

  /**
   *
   * @returns
   */
  const update = () => {
    if (checkAll() === false) {
      showErrorMessage()
      return
    }
    // 実行確認
    setExecType(EXEC_UPDATE)
    showConfirmMessage(EXEC_UPDATE)
  }
  const doUpdate = async () => {
    closeConfirmDialog()
    const params = createParameters()
    try {
      const result = await updateNotification(params)
      showNotifyMessage(EXEC_UPDATE)
    } catch (e) {
      // SessionTimeout処理
      if (gStates.handleSessionExpired(e)) return
      // 致命的エラー処理
      showFatalErrorMessage()
    }
  }

  /**
   *
   */
  const remove = () => {
    // 実行確認
    setExecType(EXEC_DELETE)
    showConfirmMessage(EXEC_DELETE)
  }
  const doRemove = async () => {
    closeConfirmDialog()
    try {
      const result = await deleteNotification(notificationId!)
      showNotifyMessage(EXEC_DELETE)
    } catch (e) {
      // SessionTimeout処理
      if (gStates.handleSessionExpired(e)) return
      // 致命的エラー処理
      showFatalErrorMessage()
    }
  }

  /**
   *
   */
  const clear = () => {
    clearMsgs()
    if (openMode === EXEC_INSERT) {
      clearValues()
    }
    if (openMode === EXEC_UPDATE) {
      restoreValues()
    }
  }

  const doCallback = async () => {
    if (!execType) throw new Error('実行種別が指定されていません。')

    if (execType === EXEC_INSERT) await doInsert()
    if (execType === EXEC_UPDATE) await doUpdate()
    if (execType === EXEC_DELETE) await doRemove()
  }

  /**
   * エラーメッセージを表示する
   */
  const showErrorMessage = (): void => {
    setDialogTitle(CONTAIN_ERROR_TITLE)
    setDialogContents(COMMON_ERROR_MESSAGE)
    setShowErrorDialog(true)
  }

  /**
   * 確認メッセージを表示する
   * @param type
   */
  const showConfirmMessage = (type: EXEC_TYPE): void => {
    setDialogTitle(getConfirmTitle(type))
    setDialogContents(getConfirmMessage(type, '通知情報'))
    setShowConfirmDialog(true)
  }

  /**
   * 通知メッセージを表示する
   * @param type
   */
  const showNotifyMessage = (type: EXEC_TYPE): void => {
    setDialogTitle(getNotifyTitle(type))
    setDialogContents(getNotifyMessage(type, '通知情報'))
    setShowNotifyDialog(true)
  }

  const checkAll = (): boolean => {
    const resultAll: boolean[] = []
    // resultAll.push(checkCategoryId())
    resultAll.push(checkTitle())
    resultAll.push(checkDescription())
    return resultAll.includes(false) === false
  }

  /**
   *
   * @returns
   */
  const checkTitle = (): boolean => {
    // 必須入力
    if (hasNotValue(title)) {
      setMsg4Title(requiredMessage('タイトル'))
      return false
    }
    // 桁数チェック
    if (invalidMaxLength(title, 100)) {
      setMsg4Title(lengthLessThanMessage('タイトル', 100))
      return false
    }
    return true
  }

  /**
   *
   * @returns
   */
  const checkDescription = (): boolean => {
    // 必須入力
    if (hasNotValue(description)) {
      setMsg4Description(requiredMessage('メッセージ'))
      return false
    }
    // 桁数チェック
    if (invalidMaxLength(description, 800)) {
      setMsg4Description(lengthLessThanMessage('メッセージ', 800, description.length))
      return false
    }

    return true
  }

  const clearValues = (): void => {
    setContractorId(null)
    setCategoryId(0)
    setTitle('')
    setDescription('')
  }

  const restoreValues = (): void => {
    setContractorId(originalNotification!.contractorId ?? null)
    setCategoryId(originalNotification!.categoryId)
    setTitle(originalNotification!.title)
    setDescription(originalNotification!.description)
  }

  const clearMsgs = (): void => {
    setMsg4CategoryId('')
    setMsg4Title('')
    setMsg4Description('')
  }

  const createParameters = (): NotificationInfo => {
    return {
      notificationId,
      categoryId,
      contractorId,
      title,
      description
    }
  }

  const globalStates = {
    categoryList,

    notificationId,
    contractorId,
    categoryId,
    title,
    description,
    updatedAtString,

    msg4CategoryId,
    msg4Title,
    msg4Description,

    dialogTitle,
    dialogContents,
    showErrorDialog,
    showConfirmDialog,
    showNotifyDialog,

    changeCategoryId,
    changeTitle,
    changeDescription,

    closeErrorDialog,
    closeConfirmDialog,
    closeNotifyDialog,

    refresh,
    insert,
    update,
    remove,
    clear,
    doCallback
  }

  return (
    <NotificationStateContext.Provider value={globalStates}>
      {/*  */}
      {children}
    </NotificationStateContext.Provider>
  )
}
