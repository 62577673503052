import { createContext, useContext, useEffect, useState } from 'react'

import { findAll } from 'api/contractors'
import type { ProviderProps } from 'types/common/ProviderProps'
import type { ContractorInfo } from 'types/infos/ContractorInfo'
import { useGlobalState } from 'components/common/provider/GlobalStateProvider'
import { FATAL_ERROR_MESSAGE, FATAL_ERROR_TITLE } from 'utils/messageUtil'

interface ContextProps {
  contractorList: ContractorInfo[]
  targetContractor: ContractorInfo | null
  selectContractor: (contractor: ContractorInfo) => void
  refresh: () => void
  dialogTitle: string
  dialogContents: string
  showErrorDialog: boolean
  closeErrorDialog: () => void
}

/**
 *
 */
export const ContractorsStateContext = createContext({} as ContextProps)

/**
 *
 * @returns
 */
export const useContractorsState = () => useContext(ContractorsStateContext)

/**
 *
 * @param props
 * @returns
 */
export const ContractorsStateProvider = (props: ProviderProps) => {
  const { children } = props
  const gStates = useGlobalState()

  // ステート
  const [contractorList, setContractorList] = useState<ContractorInfo[]>([])
  const [targetContractor, setTargetContractor] = useState<ContractorInfo | null>(null)
  const [dialogTitle, setDialogTitle] = useState<string>('')
  const [dialogContents, setDialogContents] = useState<string>('')
  const [showErrorDialog, setShowErrorDialog] = useState<boolean>(false)

  useEffect(() => {
    refresh()
  }, [])

  // クリックイベント
  const selectContractor = (contractor: ContractorInfo) => {
    setTargetContractor(contractor)
  }

  const refresh = (): void => {
    findAll()
      .then((contractors) => {
        setContractorList(contractors)
      })
      .catch((e: unknown) => {
        // SessionTimeout処理
        if (gStates.handleSessionExpired(e)) return
        // システムエラーメッセージの表示
        showFatalErrorMessage()
      })
  }

  const closeErrorDialog = () => {
    setShowErrorDialog(false)
  }

  /**
   * システムエラーメッセージを表示する
   */
  const showFatalErrorMessage = (): void => {
    setDialogTitle(FATAL_ERROR_TITLE)
    setDialogContents(FATAL_ERROR_MESSAGE)
    setShowErrorDialog(true)
  }

  // 公開ステート
  const globalStates = {
    contractorList,
    targetContractor,
    selectContractor,
    refresh,
    dialogTitle,
    dialogContents,
    showErrorDialog,
    closeErrorDialog
  }

  return (
    <ContractorsStateContext.Provider value={globalStates}>
      {/*  */}
      {children}
    </ContractorsStateContext.Provider>
  )
}
