import { createContext, useContext, useState } from 'react'

import { deleteFacility, registerFacility, updateFacility } from 'api/facilities'
import { useGlobalState } from 'components/common/provider/GlobalStateProvider'
import { EXEC_TYPE } from 'types/common/ExecType'
import { ProviderProps } from 'types/common/ProviderProps'
import { FacilityInfo } from 'types/infos/FacilityInfo'
import { EXEC_DELETE, EXEC_INSERT, EXEC_UPDATE } from 'utils/constants'
import {
  CONTAIN_ERROR_TITLE,
  COMMON_ERROR_MESSAGE,
  getConfirmTitle,
  getConfirmMessage,
  getNotifyTitle,
  getNotifyMessage,
  requiredMessage,
  lengthLessThanMessage,
  valueLessThanMessage,
  FATAL_ERROR_TITLE,
  FATAL_ERROR_MESSAGE,
  numValueRangeMessage
} from 'utils/messageUtil'
import { hasNotValue, invalidMaxLength, isInteger } from 'utils/validators'

interface FacilityState {
  facilityName: string
  floorNum: number
  floorNumString: string
  isAvailable: boolean
  displayOrder: number | null
  displayOrderString: string

  msg4FacilityName: string
  msg4FloorNum: string
  msg4IsAvailable: string
  msg4DisplayOrder: string

  dialogTitle: string
  dialogContents: string
  showErrorDialog: boolean
  showConfirmDialog: boolean
  showNotifyDialog: boolean

  changeFacilityName: (val: string) => void
  changeFloorNum: (val: number) => void
  changeFloorNumString: (val: string) => void
  changeIsAvailable: (val: boolean) => void
  changeDisplayOrder: (val: number) => void
  changeDisplayOrderString: (val: string) => void
  closeErrorDialog: () => void
  closeConfirmDialog: () => void
  closeNotifyDialog: () => void
  setOrigDisplayOrder: (val: number) => void

  refresh: (type: EXEC_TYPE, info?: FacilityInfo) => void
  insert: () => void
  update: () => void
  remove: () => void
  clear: () => void
  doCallback: () => Promise<void>
}

export const FacilityStateContext = createContext({} as FacilityState)
export const useFacilityState = () => useContext(FacilityStateContext)

/**
 *
 * @param props
 * @returns
 */
export const FacilityStateProvider = (props: ProviderProps) => {
  //
  const { children } = props
  const gStates = useGlobalState()

  // State
  const [openMode, setOpenMode] = useState<EXEC_TYPE>(EXEC_INSERT)
  const [execType, setExecType] = useState<EXEC_TYPE | null>(null)

  const [originalFacility, setOriginalFacility] = useState<FacilityInfo | null>(null)
  const [facilityId, setFacilityId] = useState<number>(0)
  const [facilityName, setFacilityName] = useState<string>('')
  const [floorNum, setFloorNum] = useState<number | null>(null)
  const [isAvailable, setIsAvailable] = useState<boolean>(true)
  const [displayOrder, setDisplayOrder] = useState<number | null>(null)
  const [origDisplayOrder, setOrigDisplayOrder] = useState<number | null>(null)

  const [msg4FacilityName, setMsg4FacilityName] = useState<string>('')
  const [msg4FloorNum, setMsg4FloorNum] = useState<string>('')
  const [msg4IsAvailable, setMsg4IsAvailable] = useState<string>('')
  const [msg4DisplayOrder, setMsg4DisplayOrder] = useState<string>('')

  const [dialogTitle, setDialogTitle] = useState<string>('')
  const [dialogContents, setDialogContents] = useState<string>('')
  const [showErrorDialog, setShowErrorDialog] = useState<boolean>(false)
  const [showConfirmDialog, setShowConfirmDialog] = useState<boolean>(false)
  const [showNotifyDialog, setShowNotifyDialog] = useState<boolean>(false)

  const floorNumString = floorNum ?? ''
  const displayOrderString = displayOrder ?? ''

  const changeFacilityName = (val: string) => {
    setFacilityName(val)
    setMsg4FacilityName('')
  }

  const changeFloorNum = (val: number | null) => {
    setFloorNum(val)
    setMsg4FloorNum('')
  }

  const changeFloorNumString = (val: string) => {
    if (isInteger(val)) {
      setFloorNum(parseInt(val, 10))
    } else {
      setFloorNum(null)
    }
    setMsg4FloorNum('')
  }

  const changeIsAvailable = (val: boolean) => {
    setIsAvailable(val)
    setMsg4IsAvailable('')
  }

  const changeDisplayOrder = (val: number | null) => {
    setDisplayOrder(val)
    setMsg4DisplayOrder('')
  }

  const changeDisplayOrderString = (val: string) => {
    if (isInteger(val)) {
      setDisplayOrder(parseInt(val, 10))
    } else {
      setDisplayOrder(null)
    }
    setMsg4DisplayOrder('')
  }

  const closeErrorDialog = () => {
    setShowErrorDialog(false)
  }

  const closeConfirmDialog = () => {
    setShowConfirmDialog(false)
  }

  const closeNotifyDialog = () => {
    setShowNotifyDialog(false)
  }

  // --【click イベントハンドラ】------------------------------------------

  const refresh = (type: EXEC_TYPE, info?: FacilityInfo) => {
    setOpenMode(type)

    if (type === EXEC_INSERT) {
      setOriginalFacility(null)

      setFacilityId(0)
      changeFacilityName('')
      changeFloorNum(null)
      changeDisplayOrder(null)
      changeIsAvailable(true)
    }

    if (type === EXEC_UPDATE) {
      setOriginalFacility(info!)

      setFacilityId(info!.facilityId!)
      changeFacilityName(info!.facilityName!)
      changeFloorNum(info!.floorNum)
      changeDisplayOrder(info!.displayOrder)
      changeIsAvailable(info!.isAvailable)
      return
    }
  }

  /**
   * 「新規登録」イベントリスナ
   */
  const insert = () => {
    // エラーチェック
    if (checkAll() === false) {
      showErrorMessage()
      return
    }
    setExecType(EXEC_INSERT)
    showConfirmMessage(EXEC_INSERT)
  }
  const doInsert = async () => {
    closeConfirmDialog()
    const params = createParameters()
    try {
      const result = await registerFacility(params)
      showNotifyMessage(EXEC_INSERT)
    } catch (e) {
      // SessionTimeout処理
      if (gStates.handleSessionExpired(e)) return
      // 致命的エラー処理
      showFatalErrorMessage()
    }
  }

  /**
   * 「更新」イベントリスナ
   */
  const update = async () => {
    if (checkAll() === false) {
      showErrorMessage()
      return
    }
    setExecType(EXEC_UPDATE)
    showConfirmMessage(EXEC_UPDATE)
  }
  const doUpdate = async () => {
    closeConfirmDialog()
    const params = createParameters()
    try {
      const result = await updateFacility(params)
      showNotifyMessage(EXEC_UPDATE)
    } catch (e) {
      // SessionTimeout処理
      if (gStates.handleSessionExpired(e)) return
      // 致命的エラー処理
      showFatalErrorMessage()
    }
  }

  /**
   * 「削除」イベントリスナ
   */
  const remove = async () => {
    setExecType(EXEC_DELETE)
    showConfirmMessage(EXEC_DELETE)
  }
  const doRemove = async () => {
    closeConfirmDialog()
    try {
      const result = await deleteFacility(facilityId)
      showNotifyMessage(EXEC_DELETE)
    } catch (e) {
      // SessionTimeout処理
      if (gStates.handleSessionExpired(e)) return
      // 致命的エラー処理
      showFatalErrorMessage()
    }
  }

  /**
   *
   */
  const clear = () => {
    clearMsgs()
    if (openMode === EXEC_INSERT) {
      //
      clearValues()
    }

    if (openMode === EXEC_UPDATE) {
      //
      restoreValues()
    }
  }

  const doCallback = async () => {
    if (!execType) throw new Error('実行種別が指定されていません。')

    if (execType === EXEC_INSERT) {
      await doInsert()
    }
    if (execType === EXEC_UPDATE) {
      await doUpdate()
    }
    if (execType === EXEC_DELETE) {
      await doRemove()
    }
  }

  /**
   * エラーメッセージを表示する
   */
  const showErrorMessage = (): void => {
    setDialogTitle(CONTAIN_ERROR_TITLE)
    setDialogContents(COMMON_ERROR_MESSAGE)
    setShowErrorDialog(true)
  }

  /**
   * システムエラーメッセージを表示する
   */
  const showFatalErrorMessage = (): void => {
    setDialogTitle(FATAL_ERROR_TITLE)
    setDialogContents(FATAL_ERROR_MESSAGE)
    setShowErrorDialog(true)
  }

  /**
   * 確認メッセージを表示する
   * @param type
   */
  const showConfirmMessage = (type: EXEC_TYPE): void => {
    setDialogTitle(getConfirmTitle(type))
    setDialogContents(getConfirmMessage(type, '施設情報'))
    setShowConfirmDialog(true)
  }

  /**
   * 通知メッセージを表示する
   * @param type
   */
  const showNotifyMessage = (type: EXEC_TYPE): void => {
    setDialogTitle(getNotifyTitle(type))
    setDialogContents(getNotifyMessage(type, '施設情報'))
    setShowNotifyDialog(true)
  }

  const clearValues = () => {
    //
    setFacilityName('')
    setDisplayOrder(origDisplayOrder)
    setFloorNum(null)
    setIsAvailable(true)
  }

  const restoreValues = () => {
    //
    setFacilityName(originalFacility!.facilityName)
    setDisplayOrder(originalFacility!.displayOrder)
    setFloorNum(originalFacility!.floorNum)
    setIsAvailable(originalFacility!.isAvailable)
  }

  const clearMsgs = () => {
    //
    setMsg4FacilityName('')
    setMsg4DisplayOrder('')
    setMsg4FloorNum('')
    setMsg4IsAvailable('')
  }

  const checkAll = (): boolean => {
    const resultAll: boolean[] = []
    resultAll.push(checkFacilityName())
    resultAll.push(checkDisplayOrder())
    resultAll.push(checkFloorNum())
    return resultAll.includes(false) === false
  }

  const checkFacilityName = (): boolean => {
    // 必須入力
    if (hasNotValue(facilityName)) {
      setMsg4FacilityName(requiredMessage('施設名'))
      return false
    }
    // 桁数
    if (invalidMaxLength(facilityName, 30)) {
      setMsg4FacilityName(lengthLessThanMessage('施設名', 30))
      return false
    }
    return true
  }
  const checkDisplayOrder = (): boolean => {
    // 必須入力
    if (hasNotValue(displayOrder)) {
      if (displayOrder === 0) {
        setMsg4DisplayOrder(numValueRangeMessage('並び順', 0, 99))
        return false
      } else {
        setMsg4DisplayOrder(requiredMessage('並び順'))
        return false
      }
    }

    // 最大値
    if (displayOrder! >= 100) {
      setMsg4DisplayOrder(valueLessThanMessage('並び順', 99))
      return false
    }

    // 数値型
    // 入力時に整数値ではない場合は、値がクリアされているはずなので、チェック不要

    return true
  }
  const checkFloorNum = (): boolean => {
    // 必須入力
    if (hasNotValue(floorNum)) {
      if (floorNum === 0) {
        setMsg4FloorNum(numValueRangeMessage('フロア', 0, 99))
        return false
      } else {
        setMsg4FloorNum(requiredMessage('フロア'))
        return false
      }
    }

    // 最大値
    if (floorNum! >= 100) {
      setMsg4FloorNum(valueLessThanMessage('フロア', 99))
      return false
    }
    // 数値型
    // 入力時に整数値ではない場合は、値がクリアされているはずなので、チェック不要

    return true
  }

  const createParameters = (): FacilityInfo => {
    const dOrder = displayOrder!
    const fNum = floorNum!
    return {
      facilityId,
      facilityName,
      floorNum: fNum,
      displayOrder: dOrder,
      isAvailable
    }
  }

  const globalStates = {
    facilityName,
    floorNum,
    floorNumString,
    isAvailable,
    displayOrder,
    displayOrderString,

    msg4FacilityName,
    msg4FloorNum,
    msg4IsAvailable,
    msg4DisplayOrder,

    dialogTitle,
    dialogContents,
    showErrorDialog,
    showConfirmDialog,
    showNotifyDialog,

    changeFacilityName,
    changeFloorNum,
    changeFloorNumString,
    changeIsAvailable,
    changeDisplayOrder,
    changeDisplayOrderString,
    setOrigDisplayOrder,

    closeErrorDialog,
    closeConfirmDialog,
    closeNotifyDialog,

    refresh,
    insert,
    update,
    remove,
    clear,
    doCallback
  } as FacilityState

  return (
    <FacilityStateContext.Provider value={globalStates}>
      {/*  */}
      {children}
    </FacilityStateContext.Provider>
  )
}
