import axios from 'api/common/axios'
import {
  RESERVATIONS_BASE,
  RESERVATIONS_FOR_CONTRACTOR,
  RESERVATIONS_FOR_DATE,
  RESERVATIONS_FOR_FACILITY
} from 'api/common/url'
import { API_RESPONSE } from 'types/common/Api'
import { ReservationInfo } from 'types/infos/ReservationInfo'
import { ReservedInfo } from 'types/ui/ReservedInfo'
import { RESPONSE_NG } from 'utils/constants'

/**
 * 指定された施設予約を１件取得する。
 * @param reservationId
 */
export const findOne = async (reservationId: number) => {
  try {
    const response = await axios.get(`${RESERVATIONS_BASE}/${reservationId}`)
    // エラーチェックは読み出し元で実施する
    return response.data
    //
  } catch (e) {
    console.log(e)
    throw e
  } finally {
    //
  }
}

/**
 * 指定された日付の全ての施設の予約情報を取得する。
 * @param targetDate
 * @param contractorId
 * @returns
 */
export const findDailyReservedList = async (
  targetDate: Date,
  contractorId: number
): Promise<ReservedInfo[]> => {
  try {
    const timeValue = targetDate.getTime()
    type retType = API_RESPONSE<ReservedInfo[]>
    const response = await axios.get<retType>(`${RESERVATIONS_FOR_DATE}/${timeValue}`)

    if (response.data.status === RESPONSE_NG) {
      throw new Error(response.data.code.toString())
    }

    const data = response.data.data.map((e: any) => {
      const dateValue = new Date(e.reservationDatetime)
      e.reservationDatetime = dateValue
      e.isOwner = e.contractorId === contractorId
      return e
    })

    // console.log('予約リスト？', response.data.data)
    return data
  } catch (e) {
    console.log(e)
    throw e
  } finally {
    //
  }
}

/**
 * 指定された施設に対して、指定範囲の月日における予約情報を取得する
 * @param facilityId
 * @param from
 * @param to
 * @param contractorId
 * @returns
 */
export const findFacilityReservedList = async (
  facilityId: number,
  from: Date,
  to: Date
): Promise<ReservedInfo[]> => {
  try {
    const fromValue = from.getTime()
    const toValue = to.getTime()
    const params = { from: fromValue, to: toValue }
    type retType = API_RESPONSE<ReservedInfo[]>
    const response = await axios.get<retType>(`${RESERVATIONS_FOR_FACILITY}/${facilityId}`, {
      params
    })

    if (response.data.status === RESPONSE_NG) {
      throw new Error(response.data.code.toString())
    }

    // console.log('予約リスト？', response.data.data)
    return response.data.data
  } catch (e) {
    console.log(e)
    throw e
  } finally {
    //
  }
}

/**
 *
 * @param contractorId
 * @returns
 */
export const findContractorReservationsList = async (
  contractorId: number
): Promise<ReservedInfo[]> => {
  try {
    type retType = API_RESPONSE<ReservedInfo[]>
    const response = await axios.get<retType>(`${RESERVATIONS_FOR_CONTRACTOR}/${contractorId}`)

    if (response.data.status === RESPONSE_NG) {
      throw new Error(response.data.code.toString())
    }

    return response.data.data
    //
  } catch (e) {
    console.log(e)
    throw e
  } finally {
    //
  }
}

/**
 * 施設予約を新規に登録する。
 * @param params
 */
export const registerReservation = async (params: ReservationInfo) => {
  //
  try {
    type retType = API_RESPONSE<null>
    const response = await axios.post<retType>(RESERVATIONS_BASE, params)

    // エラーチェックは読み出し元で実施する
    return response.data
  } catch (e) {
    console.log(e)
    throw e
  } finally {
    //
  }
}

/**
 * 施設予約を更新する。
 * @param params
 */
export const updateReservation = async (params: ReservationInfo) => {
  //
  try {
    type retType = API_RESPONSE<null>
    const response = await axios.put<retType>(
      `${RESERVATIONS_BASE}/${params.reservationId}`,
      params
    )

    if (response.data.status === RESPONSE_NG) {
      throw new Error(response.data.code.toString())
    }
  } catch (e) {
    console.log(e)
    throw e
  } finally {
    //
  }
}

/**
 * 施設予約を削除する。
 * @param reservationId
 */
export const removeReservation = async (reservationId: number) => {
  //
  try {
    type retType = API_RESPONSE<null>
    const response = await axios.delete<retType>(`${RESERVATIONS_BASE}/${reservationId}`)

    if (response.data.status === RESPONSE_NG) {
      throw new Error(response.data.code.toString())
    }
  } catch (e) {
    console.log(e)
    throw e
  } finally {
    //
  }
}
