import styles from 'components/common/atoms/OutputTextArea.module.scss'

interface OutputTextAreaProps {
  cols: number
  rows: number
  value: string // 表示値
}

const OutputTextArea = ({ cols, rows, value }: OutputTextAreaProps) => {
  // デフォルト値
  const colCount = cols ?? 20
  const rowCount = rows ?? 3

  return (
    <>
      <textarea
        className={styles.outputTextArea}
        cols={colCount}
        rows={rowCount}
        value={value}
        readOnly
      ></textarea>
    </>
  )
}

export default OutputTextArea
