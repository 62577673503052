import { Route, Routes } from 'react-router-dom'

import Header from 'components/common/Header'
import styles from 'layouts/CommonLayout.module.scss'
import AdminTop from 'pages/admin/Top'
import Login from 'pages/common/Login'
import Contractors from 'pages/admin/contractors/Contractors'
import Facilities from 'pages/admin/facilities/Facilities'
import Notifications from 'pages/admin/notifications/Notifications'
import FacilitySuspends from 'pages/admin/facilities/FacilitySuspends'
import UploadFiles from 'pages/admin/uploads/UploadFiles'
import MailTemplates from 'pages/admin/mailTemplates/MailTemplates'
import DailyReservations from 'pages/reservation/DailyReservations'
import FacilityReservations from 'pages/reservation/FacilityReservations'
import Footer from 'components/common/Footer'

/**
 * 管理者用画面レイアウト.
 * @returns
 */
const AdminLayout = () => {
  return (
    <>
      <header className={styles.header}>
        <Header />
      </header>
      <main className={styles.main}>
        <Routes>
          <Route path="/" element={<AdminTop />}></Route>
          <Route path="/login" element={<Login />}></Route>
          <Route path="/contractors" element={<Contractors />}></Route>
          <Route path="/facilities/suspends" element={<FacilitySuspends />}></Route>
          <Route path="/facilities" element={<Facilities />}></Route>
          <Route path="/notifications" element={<Notifications />}></Route>
          <Route path="/uploadFiles" element={<UploadFiles />}></Route>
          <Route path="/mailTemplates" element={<MailTemplates />}></Route>
          <Route path="/reservations/daily" element={<DailyReservations />}></Route>
          <Route path="/reservations/by-facility" element={<FacilityReservations />}></Route>
        </Routes>
      </main>
      <footer className={styles.footer}>
        <Footer />
      </footer>
    </>
  )
}

export default AdminLayout
