import PageTitle from 'components/common/PageTitle'

/**
 * ユーザー別予約状況一覧画面
 */
const UserReservations = () => {
  return (
    <>
      <PageTitle title="ユーザー別予約状況一覧" />
    </>
  )
}

export default UserReservations
