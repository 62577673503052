import { Tooltip } from 'react-tooltip'
import styles from 'components/reservations/reservations/ReservationsForm.module.scss'
import { ReservedInfo } from 'types/ui/ReservedInfo'
import { useWindowDimension } from 'utils/windowUtils'
import { useEffect, useState } from 'react'
import { useLoginState } from '../provider/LoginStateProvider'
import { toDateStartTimeEndTime } from 'utils/dateUtil'
import { hasValue } from 'utils/validators'

interface Props {
  id: string | number
  rowIndex: number
  type: string
  reservation: ReservedInfo
  disabledIndex: number
  onClick?: (info: ReservedInfo) => void
}

interface CellStyle {
  top: string
  left: string
  width: string
}

/**
 *
 * @param props
 * @returns
 */
const ReservationArea = (props: Props) => {
  const { id, rowIndex, type, reservation, disabledIndex, onClick } = props

  const lStates = useLoginState()
  const isAdmin = lStates.isAdmin()

  const [winWidth] = useWindowDimension()
  const [style, setStyle] = useState<CellStyle>({ top: '0px', left: '0px', width: '0px' })

  const reserveDatetime = reservation.reservationDatetime
  const hours = reserveDatetime.getHours()
  const minutes = reserveDatetime.getMinutes()

  const startIndex = hours * 2 + (minutes === 0 ? 0 : 1)
  const endIndex = startIndex + reservation.timeUnit - 1

  const clickHandler = () => {
    if (onClick === undefined) return
    if (isDisabled) return
    onClick(reservation)
  }

  // 当該セルの予約が自身の予約である
  const isOwner = reservation.isOwner
  // 当該セルが過去日時である
  const isPast = endIndex <= disabledIndex!
  // 使用可能：管理者、または、「契約者で過去予約ではなく自分自身の予約」
  const isDisabled = !(isAdmin || (!isPast && isOwner))

  //
  let valueForType = type === 'facility' ? '' : reservation.facilityName
  if (type === 'facility') {
    const [ymd, start, end] = toDateStartTimeEndTime(
      reservation.reservationDatetime,
      reservation.timeUnit
    )
    valueForType = ymd + start + ' ~ ' + end
  }
  const applicant = hasValue(reservation.applicant) ? reservation.applicant : reservation.repName

  // 自身の予約なら「赤」、その他なら「緑」
  const ownerStyle = isOwner ? styles.reservationArea : styles.reservationAreaOthers
  // 使用不可なら「カーソル不変」「ホバー色なし」
  const isDisabledStyle = isDisabled ? styles.disabled : ''

  useEffect(() => {
    const marginLeft = winWidth < 951 ? 180 : 280
    const style = {
      top: `${rowIndex * 40.5}px`,
      left: `${marginLeft + startIndex * 20}px`,
      width: `${reservation.timeUnit * 20}px`
    }
    setStyle(style)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [winWidth])

  console.log('RES : ', reservation)

  return (
    <>
      <div
        className={`${ownerStyle} ${isDisabledStyle}`}
        style={style}
        data-tooltip-id={`reservation-area_${id}`}
        onClick={clickHandler}
      ></div>
      <Tooltip
        id={`reservation-area_${id}`}
        delayShow={100}
        place="top-start"
        style={{
          backgroundColor: 'gray',
          borderRadius: '10px',
          color: 'white',
          zIndex: '3'
        }}
      >
        <div>{valueForType}</div>
        <div>{reservation.contractorName}</div>
        <div>{applicant}</div>
      </Tooltip>
    </>
  )
}

export default ReservationArea
