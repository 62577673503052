import PageTitle from 'components/common/PageTitle'
import tStyles from 'styles/utils/table.module.scss'
import styles from 'components/downloads/downloadFiles/DownloadFilesForm.module.scss'
import { useDownloadFilesState } from './DownloadFilesStateProvider'
import { DownloadFileInfo } from 'types/infos/DownloadFileInfo'
import { toWarekiTillSeconds } from 'utils/dateUtil'
import EmptyList from 'components/common/tables/EmptyList'
import ErrorDialog from 'components/common/dialogs/messages/ErrorDialog'

const DownloadFilesForm = ({ forContractor = false }) => {
  //
  const states = useDownloadFilesState()

  const downloadFile = (file: DownloadFileInfo) => {
    states.download(file)
  }

  const scrollable = forContractor ? tStyles.scrollable : ''

  /**
   *
   */
  const closeErrorDialog = () => {
    states.closeErrorDialog()
  }

  return (
    <>
      <ErrorDialog
        title={states.dialogTitle}
        contents={states.dialogContents}
        show={states.showErrorDialog}
        onOk={closeErrorDialog}
      />
      <PageTitle title="資料ダウンロード" />
      <div className={tStyles.tablePreInfo}></div>

      <div className={`${tStyles.tableFrame} ${scrollable}`}>
        <div className={`${tStyles.commonTable}  ${styles.tableWidth}`}>
          <div className={tStyles.headerRow}>
            <div className={`${tStyles.headerColumn} ${styles.col1}`}>#</div>
            <div className={`${tStyles.headerColumn} ${styles.col2}`}>公開名</div>
            <div className={`${tStyles.headerColumn} ${styles.col3}`}>更新日時</div>
            <div className={`${tStyles.headerColumn} ${styles.col4}`}>ダウンロード</div>
          </div>
          {states.fileList.map((file, index) => (
            <div className={`${tStyles.dataRow} ${tStyles.notHover}`} key={file.downloadFileId}>
              <div className={`${tStyles.dataColumn} ${styles.col1}`}>{index + 1}</div>
              <div className={`${tStyles.dataColumn} ${styles.col2}`}>{file.publicName}</div>
              <div className={`${tStyles.dataColumn} ${styles.col3}`}>
                {toWarekiTillSeconds(file.updatedAt, true)}
              </div>
              <div className={`${tStyles.dataColumn} ${styles.col4}`}>
                <button className={tStyles.button} onClick={() => downloadFile(file)}>
                  ダウンロード
                </button>
              </div>
            </div>
          ))}
          <EmptyList isEmpty={states.isListEmpty} msg="ダウンロードファイルはございません" />
        </div>
      </div>
    </>
  )
}

export default DownloadFilesForm
