import PageTitle from 'components/common/PageTitle'
import { useMailTemplatesState } from './MailTemplatesStateProvider'

import styles from 'components/mailTemplates/mailTemplates/MailTemplatesForm.module.scss'
import tStyles from 'styles/utils/table.module.scss'
import { EXEC_TYPE } from 'types/common/ExecType'
import { EXEC_INSERT, EXEC_UPDATE } from 'utils/constants'
import EmptyList from 'components/common/tables/EmptyList'
import MailTemplateStateProvider from '../mailTemplate/MailTemplateStateProvider'
import MailTemplateDialog from 'components/common/dialogs/MailTemplateDialog'
import { MailTemplateRow } from 'types/rows/MailTemplateRow'
import { useState } from 'react'
import ErrorDialog from 'components/common/dialogs/messages/ErrorDialog'

/**
 *
 * @returns
 */
const MailTemplatesForm = () => {
  //
  const states = useMailTemplatesState()

  // States
  const [execType, setExecType] = useState<EXEC_TYPE>(EXEC_INSERT)
  const [open, setOpen] = useState<boolean>(false)

  /**
   *
   * @param notification
   */
  const selectMailTemplate = (mailTemplate: MailTemplateRow) => {
    setExecType(() => EXEC_UPDATE)
    states.selectMailTemplate(mailTemplate)
    openDialog()
  }

  // ==== ダイアログ制御系 ====

  /**
   *
   */
  const showDialogForCreate = () => {
    setExecType(EXEC_INSERT)
    openDialog()
  }

  /**
   *
   */
  const openDialog = () => {
    setOpen(() => true)
  }

  /**
   *
   */
  const closeDialog = () => {
    states.refresh()
    setOpen(() => false)
  }

  /**
   *
   */
  const closeErrorDialog = () => {
    states.closeErrorDialog()
  }

  return (
    <>
      <ErrorDialog
        title={states.dialogTitle}
        contents={states.dialogContents}
        show={states.showErrorDialog}
        onOk={closeErrorDialog}
      />
      <PageTitle title="メールテンプレート一覧"></PageTitle>
      <div className={tStyles.tableFrame}>
        <div className={tStyles.commonTable}>
          <div className={tStyles.headerRow}>
            <div className={`${tStyles.headerColumn} ${styles.col1}`}>#</div>
            <div className={`${tStyles.headerColumn} ${styles.col2}`}>テンプレート種別</div>
            <div className={`${tStyles.headerColumn} ${styles.col3}`}>タイトル</div>
            <div className={`${tStyles.headerColumn} ${styles.col4}`}>本文</div>
          </div>

          {states.mailTemplateList.map((mailTemplate: MailTemplateRow, index: number) => {
            return (
              <div
                className={tStyles.dataRow}
                key={mailTemplate.mailTemplateId}
                onClick={(_) => selectMailTemplate(mailTemplate)}
              >
                <div className={`${tStyles.dataColumn} ${styles.col1}`}>{index + 1}</div>
                <div className={`${tStyles.dataColumn} ${styles.col2}`}>
                  {mailTemplate.templateTypeLabel}
                </div>
                <div className={`${tStyles.dataColumn} ${styles.col3}`}>{mailTemplate.title}</div>
                <div className={`${tStyles.dataColumn} ${styles.col4}`}>{mailTemplate.body}</div>
              </div>
            )
          })}
          <EmptyList isEmpty={states.isListEmpty} />
        </div>
      </div>

      {/* ==== メールテンプレート登録ダイアログ ==== */}
      {/* ダイアログ全体でStateを使いたい.ここでProviderで提供しておく */}
      <MailTemplateStateProvider>
        <MailTemplateDialog
          execType={execType}
          mailTemplateInfo={states.targetMailTemplate!} // TODO
          show={open}
          onClose={closeDialog}
        />
      </MailTemplateStateProvider>
      {/* ここまで */}
    </>
  )
}

export default MailTemplatesForm
