import { API_RESPONSE } from 'types/common/Api'
import fileDownload from 'js-file-download'

import { DownloadFileInfo } from 'types/infos/DownloadFileInfo'
import axios from './common/axios'
import {
  DOWNLOAD_FILES_BASE,
  DOWNLOAD_FILES_NAMES,
  DOWNLOAD_FILES_ATTRS,
  DOWNLOAD_FILES_FILE
} from './common/url'
import { UploadedFileAttrs } from 'types/infos/UploadedFileAttrs'
import { toJpDate } from 'utils/dateUtil'
import { RESPONSE_NG } from 'utils/constants'

/**
 *
 * @returns
 */
export const findAll = async (notShowSuspended: boolean = false): Promise<DownloadFileInfo[]> => {
  try {
    type retType = API_RESPONSE<DownloadFileInfo[]>
    const response = await axios.get<retType>(DOWNLOAD_FILES_BASE)

    if (response.data.status === RESPONSE_NG) {
      throw new Error(response.data.code.toString())
    }

    const data = response.data.data.map((d) => {
      d.updatedAt = toJpDate(new Date(d.updatedAt))
      d.isSuspended = !!d.isSuspended
      return d
    })
    if (notShowSuspended === false) return data

    const filtered = data.filter((d) => d.isSuspended === false)
    return filtered
    //
  } catch (e) {
    console.log(e)
    throw e
  } finally {
    //
  }
}

/**
 *
 * @param fileInfo
 * @returns
 */
export const downloadFile = async (fileInfo: DownloadFileInfo) => {
  try {
    const response = await axios.get(
      `${DOWNLOAD_FILES_BASE}/download-file/${fileInfo.downloadFileId}`,
      {
        responseType: 'blob'
      }
    )
    if (response.data.status === RESPONSE_NG) {
      throw new Error(response.data.code.toString())
    }

    fileDownload(response.data, fileInfo.originalName)

    return response.data.data

    //
  } catch (e) {
    console.log(e)
    throw e
  } finally {
    //
  }
}

/**
 *
 * @param file
 */
export const insertDownloadFile = async (
  file: File,
  publicName: string,
  originalName: string,
  isSuspended: boolean
) => {
  try {
    type retType = { newId: number }
    type responseType = API_RESPONSE<retType>
    const params = { file }
    const myHeader = {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }
    const response = await axios.post<responseType>(DOWNLOAD_FILES_BASE, params, myHeader)

    if (response.data.status === RESPONSE_NG) {
      throw new Error(response.data.code.toString())
    }

    const params2 = {
      downloadFileId: response.data.data.newId,
      publicName,
      originalName,
      isSuspended
    }

    const response2 = await axios.put<API_RESPONSE<void>>(`${DOWNLOAD_FILES_NAMES}`, params2)

    if (response2.data.status === RESPONSE_NG) {
      throw new Error(response.data.code.toString())
    }

    //
  } catch (e) {
    console.log(e)
    throw e
  } finally {
    //
  }
}

export const updateDownloadFile = async (
  downloadFileId: number,
  file: File,
  publicName: string,
  isSuspended: boolean
) => {
  try {
    // 実ファイルが未指定 => 公開名や一時停止フラグのみの更新 => テーブルレコードの更新のみで良い
    if (file === null) {
      type responseType = API_RESPONSE<void>
      const params = { downloadFileId, publicName, isSuspended }
      const response = await axios.put<responseType>(DOWNLOAD_FILES_ATTRS, params)

      if (response.data.status === RESPONSE_NG) {
        throw new Error(response.data.code.toString())
      }

      if (response.data.code) {
        //
      }
      return
    } else {
      type responseType = API_RESPONSE<UploadedFileAttrs>
      const params = { file }
      const myHeader = {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
      const response = await axios.put<responseType>(DOWNLOAD_FILES_FILE, params, myHeader)

      if (response.data.status === RESPONSE_NG) {
        throw new Error(response.data.code.toString())
      }

      const result = response.data.data

      type responseType2 = API_RESPONSE<UploadedFileAttrs>
      const params2 = {
        downloadFileId,
        file,
        publicName,
        originalName: file.name,
        isSuspended,
        filename: result.fileName,
        filepath: result.filePath
      }
      const url2 = `${DOWNLOAD_FILES_FILE}/${downloadFileId}`
      const response2 = await axios.put<responseType2>(url2, params2)

      if (response2.data.status === RESPONSE_NG) {
        throw new Error(response.data.code.toString())
      }

      const result2 = response2.data.data

      return
    }
    //
  } catch (e) {
    console.log(e)
    throw e
  } finally {
    //
  }
}
/**
 *
 * @param downloadFileId
 * @returns
 */
export const removeDownloadFile = async (downloadFileId: number) => {
  try {
    const response = await axios.delete(`${DOWNLOAD_FILES_BASE}/${downloadFileId}`)

    if (response.data.status === RESPONSE_NG) {
      throw new Error(response.data.code.toString())
    }

    return response.data.data

    //
  } catch (e) {
    console.log(e)
    throw e
  } finally {
    //
  }
}
