import { ChangeEvent } from 'react'
import { Tooltip } from 'react-tooltip'
import styles from 'components/common/atoms/InputText.module.scss'

/**
 *
 */
interface InputTextProps {
  id: string
  type?: string // passwordの時だけ"password"を指定。デフォルト："text"
  size?: number // デフォルト値：50
  maxLength?: number //
  align?: 'right' | 'center' | 'left'
  placeholder?: string // 任意指定
  disabled?: boolean

  value: string // 表示値
  onChange: (event: ChangeEvent<HTMLInputElement>) => void // 入力イベントハンドラ
  onBlur?: (event: ChangeEvent<HTMLInputElement>) => void

  // エラー情報
  errorMsg?: string
}

/**
 *
 * @param props
 * @returns
 */
const InputText = (props: InputTextProps) => {
  // プロパティを展開
  const {
    id,
    type,
    size,
    maxLength,
    align,
    placeholder,
    disabled,
    value,
    onChange,
    onBlur,
    errorMsg
  } = props
  // 属性
  const inputType = type ?? 'text'
  const inputSize = size ?? 50
  const inputLength = maxLength ?? 99999
  // style属性
  const bgColor = !!errorMsg ? 'pink' : ''
  const textAlign = align ?? 'left'
  // ツールチップ制御
  const isShowTooltip = !errorMsg
  // onblurイベントハンドラ
  const doOnBlur = (event: ChangeEvent<HTMLInputElement>) => {
    if (onBlur) onBlur(event)
  }

  return (
    <>
      <input
        className={styles.inputText}
        type={inputType}
        style={{ textAlign: textAlign, backgroundColor: bgColor }}
        size={inputSize}
        maxLength={inputLength}
        placeholder={placeholder}
        value={value}
        disabled={disabled}
        onChange={(event) => onChange(event)}
        onBlur={(event) => doOnBlur(event)}
        data-tooltip-id={`input-text-tooltip_${id}`}
        // data-tooltip-content="Hello World!!"
        // data-tooltip-place="top"
        // data-tooltip-delay-hide={500}
      ></input>
      <Tooltip
        id={`input-text-tooltip_${id}`}
        delayShow={300}
        hidden={isShowTooltip}
        place="top-start"
        style={{
          backgroundColor: 'lightgray',
          borderRadius: '10px',
          color: 'red'
        }}
      >
        <h3>{errorMsg}</h3>
      </Tooltip>
    </>
  )
}

export default InputText
