import { ChangeEvent } from 'react'
import { Tooltip } from 'react-tooltip'
import styles from 'components/common/atoms/CheckBox.module.scss'

interface Props {
  id: string
  label: string

  checked: boolean
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void // 入力イベントハンドラ
  noLeftMargin?: boolean

  // エラー情報
  errorMsg?: string
}

/**
 *
 * @param param0
 * @returns
 */
const CheckBox = (props: Props) => {
  //
  const { id, label, checked, onChange, noLeftMargin, errorMsg } = props
  //
  const bgColor = !!errorMsg ? { backgroundColor: 'pink' } : {}
  const isShowTooltip = !errorMsg
  const marginClass = noLeftMargin ? styles.noLeftMargin : ''

  return (
    <div
      data-tooltip-id={`checkbox-tooltip_${id}`}
      className={`${styles.checkboxContainer} ${marginClass}`}
      style={bgColor}
    >
      <label className={styles.label}>
        <input
          type="checkbox"
          className={styles.checkbox}
          checked={checked}
          onChange={(event) => onChange!(event)}
        />
        {label}
      </label>
      <Tooltip
        id={`checkbox-tooltip_${id}`}
        delayShow={300}
        hidden={isShowTooltip}
        place="right-end"
        style={{
          backgroundColor: 'var(--common-control-color)',
          borderRadius: '10px',
          color: 'red'
        }}
      >
        <h3>{errorMsg}</h3>
      </Tooltip>
    </div>
  )
}
export default CheckBox
