import { Link } from 'react-router-dom'
import { useLoginState } from './provider/LoginStateProvider'
/**
 * メインメニュー
 */
const MainMenu = () => {
  const { isLogin, isAdmin } = useLoginState()

  return (
    <>
      {isLogin && (
        <ul>
          {!isAdmin() && (
            <>
              <Link to="/">
                <li>トップ</li>
              </Link>
              <Link to="/reservations/daily">
                <li>年月日から予約 </li>
              </Link>
              <Link to="/reservations/by-facility">
                <li>施設から予約</li>
              </Link>
              <Link to="/contractor">
                <li>契約者情報</li>
              </Link>
            </>
          )}
          {isAdmin() && (
            <>
              <Link to="/">
                <li>管理者トップ</li>
              </Link>
              <Link to="/contractors">
                <li>契約者管理</li>
              </Link>
              <Link to="/reservations/daily">
                <li>年月日から予約 </li>
              </Link>
              <Link to="/reservations/by-facility">
                <li>施設から予約</li>
              </Link>
              <Link to="/facilities">
                <li>施設管理</li>
              </Link>
              <Link to="/notifications">
                <li>お知らせ管理</li>
              </Link>
              <Link to="/facilities/suspends">
                <li>施設利用停止</li>
              </Link>
              <Link to="/uploadFiles">
                <li>ファイル管理</li>
              </Link>
              <Link to="/mailTemplates">
                <li>メール管理</li>
              </Link>
            </>
          )}
        </ul>
      )}
      {!isLogin && <div style={{ height: '60px', backgroundColor: 'lightgray' }}></div>}
    </>
  )
}

export default MainMenu
