import { useEffect, useState } from 'react'

import { useNotificationState } from './NotificationStateProvider'
import ConfirmDialog from 'components/common/dialogs/messages/ConfirmDialog'
import ErrorDialog from 'components/common/dialogs/messages/ErrorDialog'
import NotifyDialog from 'components/common/dialogs/messages/NotifyDialog'
import fStyles from 'styles/utils/form.module.scss'
import InputText from 'components/common/atoms/InputText'
import TextArea from 'components/common/atoms/TextArea'
import { EXEC_TYPE } from 'types/common/ExecType'
import { EXEC_INSERT, EXEC_UPDATE } from 'utils/constants'
import Label from 'components/common/atoms/Label'
import { NotificationRow } from 'types/rows/NotificationRow'
import { useLoginState } from 'components/common/provider/LoginStateProvider'
import OutputText from 'components/common/atoms/OutputText'
import OutputTextArea from 'components/common/atoms/OutputTextArea'
import { useWindowDimension } from 'utils/windowUtils'

interface NotificationFormProps {
  execType: EXEC_TYPE
  notificationInfo: NotificationRow | null
  doClose?: () => void
}

const NotificationForm = (props: NotificationFormProps) => {
  //
  const { execType, notificationInfo, doClose } = props
  //
  const lStates = useLoginState()
  const states = useNotificationState()
  const [winWidth] = useWindowDimension()
  const [textareaCols, setTextareaCols] = useState<number>(0)

  const readOnly = lStates.isAdmin() === false

  const isInsert = execType === EXEC_INSERT
  const isUpdate = execType === EXEC_UPDATE

  // 画面初期化
  useEffect(() => {
    // 新規登録時
    if (execType === EXEC_INSERT) {
      states.refresh(execType)
      return
    }

    // 更新時
    if (execType === EXEC_UPDATE) {
      states.refresh(execType, notificationInfo!)
    }
  }, [])

  useEffect(() => {
    setTextareaCols(60)
  }, [winWidth])

  /**
   *
   */
  const doOnConfirmOk = async () => {
    await states.doCallback()
  }
  /**
   *
   */
  const doOnConfirmCancel = () => {
    states.closeConfirmDialog()
  }

  /**
   *
   */
  const closeErrorDialog = () => {
    states.closeErrorDialog()
  }
  /**
   *
   */
  const closeNotifyDialog = () => {
    states.closeNotifyDialog()
    doClose!()
  }

  return (
    <>
      <ConfirmDialog
        title={states.dialogTitle}
        contents={states.dialogContents}
        show={states.showConfirmDialog}
        onOk={doOnConfirmOk}
        onCancel={doOnConfirmCancel}
      />
      <ErrorDialog
        title={states.dialogTitle}
        contents={states.dialogContents}
        show={states.showErrorDialog}
        onOk={closeErrorDialog}
      />
      <NotifyDialog
        title={states.dialogTitle}
        contents={states.dialogContents}
        show={states.showNotifyDialog}
        onOk={closeNotifyDialog}
      />

      <section className={fStyles.detailForm}>
        <div className={fStyles.topMargin} />
        <div className={fStyles.row}>
          <span className={fStyles.label}>
            <Label title="掲載日" required={!readOnly} />
          </span>
          <span className={fStyles.input}>
            <OutputText width={230} value={states.updatedAtString} />
            {!readOnly && (
              <span className={fStyles.appendLabel}>
                システムにより設定されます（登録日時 / 更新日時）
              </span>
            )}
          </span>
        </div>

        <div className={fStyles.row}>
          <span className={fStyles.label}>
            <Label title="タイトル" required={!readOnly} />
          </span>
          <span className={fStyles.input}>
            {!readOnly && (
              <InputText
                id="roomNo"
                size={45}
                maxLength={100}
                value={states.title}
                onChange={(event) => states.changeTitle(event.target.value)}
                errorMsg={states.msg4Title}
              />
            )}
            {readOnly && <OutputText width={300} value={states.title} />}
          </span>
        </div>

        <div className={fStyles.row}>
          <span className={fStyles.label}>
            <Label title="メッセージ" required={!readOnly} />
          </span>
          <span className={fStyles.input}>
            {!readOnly && (
              <TextArea
                id="description"
                rows={5}
                cols={textareaCols}
                value={states.description}
                onChange={(event) => states.changeDescription(event.target.value)}
                errorMsg={states.msg4Description}
              />
            )}
            {readOnly && <OutputTextArea rows={5} cols={70} value={states.description} />}
          </span>
        </div>
        {!readOnly && (
          <div className={fStyles.footerButtons}>
            {isInsert && (
              <button className={fStyles.button} onClick={states.insert}>
                新規登録
              </button>
            )}
            {isUpdate && (
              <>
                <button className={fStyles.button} onClick={states.update}>
                  更新
                </button>
                <button className={fStyles.button} onClick={states.remove}>
                  削除
                </button>
              </>
            )}
            <button className={fStyles.button} onClick={states.clear}>
              クリア
            </button>
          </div>
        )}
      </section>
    </>
  )
}

export default NotificationForm
