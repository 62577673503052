import { Helmet } from 'react-helmet-async'
import DailyReservationsForm from 'components/reservations/reservations/daily/DailyReservationsForm'
import { DailyReservationsStateProvider } from 'components/reservations/reservations/daily/DailyReservationsStateProvider'
import { TITLE_RESERVE_BY_DATE } from 'utils/constants'

/**
 * 日別予約状況一覧画面
 */
const DailyReservations = () => {
  console.log('daily reservations')
  return (
    <DailyReservationsStateProvider>
      <Helmet>
        <title>{TITLE_RESERVE_BY_DATE}</title>
      </Helmet>
      <DailyReservationsForm />
    </DailyReservationsStateProvider>
  )
}

export default DailyReservations
