import { createContext, useContext, useEffect, useState } from 'react'

import { getFacilityList } from 'api/common/masterCache'
import {
  insert as insertIntoDb,
  update as updateDb,
  remove as removeFromDb
} from 'api/reservationSuspended'
import { useGlobalState } from 'components/common/provider/GlobalStateProvider'
import { ReservationSuspendInfo } from 'types/infos/ReservationSuspendInfo'
import { EXEC_TYPE } from 'types/common/ExecType'
import { ListElement } from 'types/common/ListElement'
import { ProviderProps } from 'types/common/ProviderProps'
import { ReservationSuspendListElement } from 'types/ui/ReservationSuspendListElement'
import { EXEC_DELETE, EXEC_INSERT, EXEC_UPDATE } from 'utils/constants'
import { clearTimes, currentDatetime, endTime } from 'utils/dateUtil'
import {
  CONTAIN_ERROR_TITLE,
  COMMON_ERROR_MESSAGE,
  getConfirmTitle,
  getConfirmMessage,
  getNotifyTitle,
  getNotifyMessage,
  requiredMessage,
  lengthLessThanMessage,
  equalOrReserveOrderMessage,
  FATAL_ERROR_TITLE,
  FATAL_ERROR_MESSAGE
} from 'utils/messageUtil'
import { hasNotValue, invalidMaxLength, isSessionExpired } from 'utils/validators'

/**
 *
 */
interface ReservationSuspendState {
  facilitiesList: ListElement[]
  facilityId: number | null
  facilityIdString: string
  isAllFacilities: boolean
  isAllDay: boolean
  suspendAllDatetime: Date | null
  suspendStartDatetime: Date | null
  suspendEndDatetime: Date | null
  reason: string
  //
  msg4FacilityId: string
  msg4SuspendAllDatetime: string
  msg4SuspendStartDatetime: string
  msg4SuspendEndDatetime: string
  msg4Reason: string
  //
  dialogTitle: string
  dialogContents: string
  showErrorDialog: boolean
  showConfirmDialog: boolean
  showNotifyDialog: boolean
  //
  changeIsAllFacilities: (val: boolean) => void
  changeIsAllDay: (val: boolean) => void
  changeFacilityId: (val: number) => void
  changeSuspendAllDatetime: (val: Date | null) => void
  changeSuspendStartDatetime: (val: Date | null) => void
  changeSuspendEndDatetime: (val: Date | null) => void
  changeReason: (val: string) => void
  //
  closeErrorDialog: () => void
  closeConfirmDialog: () => void
  closeNotifyDialog: () => void
  //
  refresh: (type: EXEC_TYPE, info?: ReservationSuspendListElement) => void
  insert: () => void
  update: () => void
  remove: () => void
  clear: () => void
  clearAll: () => void
  doCallback: () => Promise<void>
}

/**
 *
 */
export const ReservationSuspendStateContext = createContext({} as ReservationSuspendState)

/**
 *
 * @returns
 */
export const useReservationSuspendState = () => useContext(ReservationSuspendStateContext)

/**
 *
 * @param props
 * @returns
 */
export const ReservationSuspendStateProvider = (props: ProviderProps) => {
  const { children } = props
  const gStates = useGlobalState()

  // States
  const [facilitiesList, setFacilitiesList] = useState<ListElement[]>([])
  // 値
  const [openMode, setOpenMode] = useState<EXEC_TYPE>(EXEC_INSERT)
  const [execType, setExecType] = useState<EXEC_TYPE | null>(null)

  const [originalSuspend, setOriginalSuspend] = useState<ReservationSuspendListElement | null>(null)
  const [reservationSuspendId, setReservationSuspendId] = useState<number | null>(null)
  const [facilityId, setFacilityId] = useState<number | null>(null)
  const [isAllFacilities, setIsAllFacilities] = useState<boolean>(false)
  const [isAllDay, setIsAllDay] = useState<boolean>(false)
  const [suspendAllDatetime, setSuspendAllDatetime] = useState<Date | null>(currentDatetime())
  const [suspendStartDatetime, setSuspendStartDatetime] = useState<Date | null>(currentDatetime())
  const [suspendEndDatetime, setSuspendEndDatetime] = useState<Date | null>(currentDatetime(true))
  const [reason, setReason] = useState<string>('')

  // メッセージ
  const [msg4FacilityId, setMsg4FacilityId] = useState<string>('')
  const [msg4SuspendAllDatetime, setMsg4SuspendAllDatetime] = useState<string>('')
  const [msg4SuspendStartDatetime, setMsg4SuspendStartDatetime] = useState<string>('')
  const [msg4SuspendEndDatetime, setMsg4SuspendEndDatetime] = useState<string>('')
  const [msg4Reason, setMsg4Reason] = useState<string>('')

  const [dialogTitle, setDialogTitle] = useState<string>('')
  const [dialogContents, setDialogContents] = useState<string>('')
  const [showErrorDialog, setShowErrorDialog] = useState<boolean>(false)
  const [showConfirmDialog, setShowConfirmDialog] = useState<boolean>(false)
  const [showNotifyDialog, setShowNotifyDialog] = useState<boolean>(false)

  //
  const facilityIdString = hasNotValue(facilityId) ? '' : facilityId?.toString()

  // 初期化
  useEffect(() => {
    const func = async () => {
      const list = await getFacilityList()
      setFacilitiesList(list)
    }
    func()
  }, [])

  // イベントハンドラ（change）
  const changeIsAllFacilities = (val: boolean) => {
    setIsAllFacilities(val)
    setMsg4FacilityId('')
    // 全ての施設を停止 => 特定の施設の選択を解除
    if (val) {
      setFacilityId(null)
    }
  }

  const changeIsAllDay = (val: boolean) => {
    setIsAllDay(() => val)
    setMsg4SuspendAllDatetime('')
    setMsg4SuspendStartDatetime('')
    setMsg4SuspendEndDatetime('')
  }

  const changeFacilityId = (val: number) => {
    setFacilityId(val)
    setMsg4FacilityId('')
  }
  const changeSuspendAllDatetime = (val: Date | null) => {
    setSuspendAllDatetime(val)
    setMsg4SuspendAllDatetime('')
  }
  const changeSuspendStartDatetime = (val: Date | null) => {
    setSuspendStartDatetime(val)
    setMsg4SuspendStartDatetime('')
    setMsg4SuspendEndDatetime('')
  }
  const changeSuspendEndDatetime = (val: Date | null) => {
    setSuspendEndDatetime(val)
    setMsg4SuspendStartDatetime('')
    setMsg4SuspendEndDatetime('')
  }
  const changeReason = (val: string) => {
    setReason(val)
    setMsg4Reason('')
  }

  // イベントハンドラ（dialog）
  const closeErrorDialog = () => {
    setShowErrorDialog(false)
  }

  const closeConfirmDialog = () => {
    setShowConfirmDialog(false)
  }

  const closeNotifyDialog = () => {
    setShowNotifyDialog(false)
  }

  /**
   * システムエラーメッセージを表示する
   */
  const showFatalErrorMessage = (): void => {
    setDialogTitle(FATAL_ERROR_TITLE)
    setDialogContents(FATAL_ERROR_MESSAGE)
    setShowErrorDialog(true)
  }

  // イベントハンドラ（click）
  /**
   *
   * @param type
   * @param info
   * @returns
   */
  const refresh = (type: EXEC_TYPE, info?: ReservationSuspendListElement) => {
    setOpenMode(type)

    if (type === EXEC_INSERT) {
      setOriginalSuspend(null)

      setReservationSuspendId(null)
      changeFacilityId(0)
      changeIsAllFacilities(false)
      changeSuspendStartDatetime(null)
      changeSuspendEndDatetime(null)
      changeSuspendAllDatetime(null)
      changeIsAllDay(false)
      changeReason('')
    }

    if (type === EXEC_UPDATE) {
      setOriginalSuspend(info!)

      if (!info) {
        throw new Error('画面に表示するデータが指定されていません。')
      }

      setReservationSuspendId(info.reservationSuspendedId)
      changeFacilityId(info.facilityId)
      changeIsAllFacilities(info.facilityId === null)
      changeSuspendStartDatetime(info.suspendStartDatetime)
      changeSuspendEndDatetime(info.suspendEndDatetime)
      if (info.isAllDay) {
        changeSuspendAllDatetime(info.suspendStartDatetime)
      }
      changeIsAllDay(info.isAllDay)
      changeReason(info.reason)
      return
    }
  }

  /**
   * 登録処理
   */
  const insert = () => {
    if (checkAll() === false) {
      showErrorMessage()
      return
    }
    setExecType(EXEC_INSERT)
    showConfirmMessage(EXEC_INSERT)
  }
  const doInsert = async () => {
    closeConfirmDialog()
    const params = createParameters()
    try {
      await insertIntoDb(params)
      showNotifyMessage(EXEC_INSERT)
    } catch (e) {
      // SessionTimeout処理
      if (gStates.handleSessionExpired(e)) return
      // 致命的エラー処理
      showFatalErrorMessage()
    }
  }

  /**
   * 更新処理
   * @returns
   */
  const update = () => {
    if (checkAll() === false) {
      showErrorMessage()
      return
    }
    setExecType(EXEC_UPDATE)
    showConfirmMessage(EXEC_UPDATE)
  }
  const doUpdate = async () => {
    closeConfirmDialog()
    const params = createParameters()
    try {
      await updateDb(params)
      showNotifyMessage(EXEC_UPDATE)
    } catch (e) {
      // SessionTimeout処理
      if (gStates.handleSessionExpired(e)) return
      // 致命的エラー処理
      showFatalErrorMessage()
    }
  }

  /**
   * 削除処理
   */
  const remove = () => {
    // 実行確認
    setExecType(EXEC_DELETE)
    showConfirmMessage(EXEC_DELETE)
  }
  const doRemove = async () => {
    closeConfirmDialog()
    try {
      await removeFromDb(reservationSuspendId!)
      showNotifyMessage(EXEC_DELETE)
    } catch (e) {
      // SessionTimeout処理
      if (gStates.handleSessionExpired(e)) return
      // 致命的エラー処理
      showFatalErrorMessage()
    }
  }

  /**
   *
   */
  const clear = () => {
    if (openMode === EXEC_INSERT) {
      clearValues()
    } else {
      restoreValues()
    }
    clearMsgs()
  }

  const clearAll = () => {
    clearValues()
    clearMsgs()
  }

  const doCallback = async () => {
    if (!execType) throw new Error('実行種別が指定されていません。')

    if (execType === EXEC_INSERT) {
      await doInsert()
    }
    if (execType === EXEC_UPDATE) {
      await doUpdate()
    }
    if (execType === EXEC_DELETE) {
      await doRemove()
    }
  }

  /**
   * エラーメッセージを表示する
   */
  const showErrorMessage = (): void => {
    setDialogTitle(CONTAIN_ERROR_TITLE)
    setDialogContents(COMMON_ERROR_MESSAGE)
    setShowErrorDialog(true)
  }

  /**
   * 確認メッセージを表示する
   * @param type
   */
  const showConfirmMessage = (type: EXEC_TYPE): void => {
    setDialogTitle(getConfirmTitle(type))
    setDialogContents(getConfirmMessage(type, '利用停止情報'))
    setShowConfirmDialog(true)
  }

  /**
   * 通知メッセージを表示する
   * @param type
   */
  const showNotifyMessage = (type: EXEC_TYPE): void => {
    setDialogTitle(getNotifyTitle(type))
    setDialogContents(getNotifyMessage(type, '利用停止情報'))
    setShowNotifyDialog(true)
  }

  const clearValues = () => {
    setFacilityId(0)
    setIsAllFacilities(false)
    setIsAllDay(false)
    setSuspendAllDatetime(null)
    setSuspendStartDatetime(null)
    setSuspendEndDatetime(null)
    setReason('')
  }

  const restoreValues = () => {
    if (originalSuspend === null) {
      clearValues()
      return
    }

    setReservationSuspendId(originalSuspend.reservationSuspendedId)
    changeFacilityId(originalSuspend.facilityId)
    changeIsAllFacilities(originalSuspend.facilityId === null)
    changeSuspendStartDatetime(originalSuspend.suspendStartDatetime)
    changeSuspendEndDatetime(originalSuspend.suspendEndDatetime)
    if (originalSuspend.isAllDay) {
      changeSuspendAllDatetime(originalSuspend.suspendStartDatetime)
    }
    changeIsAllDay(originalSuspend.isAllDay)
    changeReason(originalSuspend.reason)
  }

  const clearMsgs = () => {
    setMsg4FacilityId('')
    setMsg4SuspendAllDatetime('')
    setMsg4SuspendStartDatetime('')
    setMsg4SuspendEndDatetime('')
    setMsg4Reason('')
  }

  const createParameters = (): ReservationSuspendInfo => {
    // 終日の場合：指定日の0:00:00 ~ 翌日の0:00:00を設定する
    const sDatetime = isAllDay //
      ? clearTimes(suspendAllDatetime!)
      : suspendStartDatetime!
    const eDatetime = isAllDay //
      ? endTime(suspendAllDatetime!)
      : suspendEndDatetime!
    //
    return {
      reservationSuspendId,
      facilityId,
      isAllDay,
      suspendStartDatetime: sDatetime,
      suspendEndDatetime: eDatetime,
      reason
    }
  }
  // 入力チェック
  /**
   * 全項目チェック
   * @returns
   */
  const checkAll = (): boolean => {
    const result = []
    result.push(checkFacilityId())
    result.push(checkSuspendAllDatetime())
    result.push(checkSuspendStartDatetime())
    result.push(checkSuspendEndDatetime())
    result.push(checkSuspendDateRelation())
    result.push(checkReason())
    return result.includes(false) === false
  }

  /**
   * 施設選択チェック
   * @returns
   */
  const checkFacilityId = (): boolean => {
    // 全設備が対象ではなく、かつ、施設が選択されていないならNG
    if (isAllFacilities === false && hasNotValue(facilityId)) {
      setMsg4FacilityId(requiredMessage('施設名'))
      return false
    }
    return true
  }

  /**
   * 全日：対象年月チェック
   * @returns
   */
  const checkSuspendAllDatetime = (): boolean => {
    if (isAllDay && hasNotValue(suspendAllDatetime)) {
      const msg = '終日停止の場合、停止予定日を入力して下さい'
      setMsg4SuspendAllDatetime(msg)
      return false
    }
    return true
  }

  /**
   * 全日以外：開始年月日チェック
   * @returns
   */
  const checkSuspendStartDatetime = (): boolean => {
    // 終日の場合はチェック不要
    if (isAllDay) return true
    if (hasNotValue(suspendStartDatetime)) {
      setMsg4SuspendStartDatetime(requiredMessage('停止開始時刻'))
      return false
    }
    return true
  }

  /**
   * 全日以外：終了年月日チェック
   * @returns
   */
  const checkSuspendEndDatetime = (): boolean => {
    // 終日の場合はチェック不要
    if (isAllDay) return true
    //
    if (hasNotValue(suspendEndDatetime)) {
      setMsg4SuspendEndDatetime(requiredMessage('停止終了時刻'))
      return false
    }
    // 開始日時が未入力 -> チェックせずに終了
    if (hasNotValue(suspendStartDatetime)) return true
    // 日時反転チェック：同日時はNG
    if (suspendStartDatetime! >= suspendEndDatetime!) {
      //
      return false
    }
    return true
  }

  const checkSuspendDateRelation = (): boolean => {
    // 終日の場合はチェック不要
    if (isAllDay) return true
    if (hasNotValue(suspendStartDatetime) || hasNotValue(suspendEndDatetime)) {
      return true
    }
    if (suspendStartDatetime! >= suspendEndDatetime!) {
      const msg = equalOrReserveOrderMessage('停止開始時刻', '停止終了時刻')
      setMsg4SuspendStartDatetime(msg)
      setMsg4SuspendEndDatetime(msg)
      return false
    }
    return true
  }

  /**
   * 理由チェック
   * @returns
   */
  const checkReason = (): boolean => {
    // 必須入力
    if (hasNotValue(reason)) {
      setMsg4Reason(requiredMessage('理由・その他'))
      return false
    }
    // 桁数
    if (invalidMaxLength(reason, 500)) {
      setMsg4Reason(lengthLessThanMessage('理由・その他', 500, reason.length))
      return false
    }

    return true
  }

  // ダイアログ系

  // 公開ステータス
  const globalStates = {
    facilitiesList,

    facilityId,
    facilityIdString,
    isAllFacilities,
    isAllDay,
    suspendAllDatetime,
    suspendStartDatetime,
    suspendEndDatetime,
    reason,
    //
    msg4FacilityId,
    msg4SuspendAllDatetime,
    msg4SuspendStartDatetime,
    msg4SuspendEndDatetime,
    msg4Reason,
    //
    //
    dialogTitle,
    dialogContents,
    showErrorDialog,
    showConfirmDialog,
    showNotifyDialog,
    //
    changeIsAllFacilities,
    changeIsAllDay,
    changeFacilityId,
    changeSuspendAllDatetime,
    changeSuspendStartDatetime,
    changeSuspendEndDatetime,
    changeReason,
    //
    closeErrorDialog,
    closeConfirmDialog,
    closeNotifyDialog,
    //
    refresh,
    insert,
    update,
    remove,
    clear,
    clearAll,
    doCallback
  } as ReservationSuspendState

  return (
    <ReservationSuspendStateContext.Provider value={globalStates}>
      {/*  */}
      {children}
    </ReservationSuspendStateContext.Provider>
  )
}
