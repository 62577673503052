import styles from 'components/common/atoms/RadioButtonGroup.module.scss'
import type { ListElement } from 'types/common/ListElement'
import RadioButton from './RadioButton'
import { Tooltip } from 'react-tooltip'
import { ChangeEvent } from 'react'

interface RadioButtonGroupType {
  id: string
  layout?: null | string

  name: string
  elements: ListElement[]
  value: string | number
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void

  // エラー情報
  errorMsg?: string
}

const RadioButtonGroup = (props: RadioButtonGroupType) => {
  //
  const { id, layout, name, elements, value, errorMsg, onChange } = props

  const layoutStyle = !layout
    ? styles.radioButtonHorizontal
    : layout === 'vertical'
    ? styles.radioButtonVertical
    : styles.radioButtonHorizontal

  const bgColor = !!errorMsg ? { backgroundColor: 'pink' } : {}
  const isShowTooltip = !errorMsg

  const changeRadio = (event: ChangeEvent<HTMLInputElement>) => {
    if (onChange) onChange(event)
  }
  return (
    <>
      <div
        className={layoutStyle}
        style={bgColor}
        data-tooltip-id={`radiobutton-group-tooltip_${id}`}
      >
        {elements.map((e: ListElement) => (
          <RadioButton
            key={id + '_' + e.value}
            name={name}
            label={e.label}
            value={e.value}
            selected={e.value === value}
            onChange={(event) => changeRadio(event)}
          />
        ))}
      </div>
      <Tooltip
        id={`radiobutton-group-tooltip_${id}`}
        delayShow={300}
        hidden={isShowTooltip}
        place="top-start"
        style={{
          backgroundColor: 'lightgray',
          borderRadius: '10px',
          color: 'red'
        }}
      >
        <h3>{errorMsg}</h3>
      </Tooltip>
    </>
  )
}

export default RadioButtonGroup
