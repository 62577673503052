import Copyright from 'components/common/Copyright'
import styles from 'components/common/Footer.module.scss'

/**
 * ページフッター
 * @returns
 */
const Footer = () => {
  return (
    <div className={styles.backgroundColor}>
      <Copyright />
    </div>
  )
}

export default Footer
