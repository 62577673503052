const Copyright = () => {
  return (
    <div
      style={{
        width: '100%',
        padding: '10px 0',
        textAlign: 'center',
        fontSize: '14px'
      }}
    >
      Copyright (C) Daiichi Planning Inc. All Rights Reserve.
    </div>
  )
}

export default Copyright
