import { ChangeEvent } from 'react'
import styles from 'components/common/atoms/RadioButton.module.scss'

interface RadioButtonProps {
  name: string
  value: number | string
  label: string
  selected: boolean
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void
}

const RadioButton = ({ name, value, label, selected, onChange }: RadioButtonProps) => {
  //
  const checkRadio = (event: ChangeEvent<HTMLInputElement>) => {
    if (onChange) onChange(event)
  }
  //
  return (
    <label className={styles.label}>
      <input
        type="radio"
        className={styles.radio}
        name={name}
        value={value}
        onChange={(event) => checkRadio(event)}
        checked={selected}
      />
      <span className={styles.span}>{label}</span>
    </label>
  )
}
export default RadioButton
