/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useLayoutEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'

import D1pDatePicker from 'components/common/atoms/D1pDatePicker'
import PageTitle from 'components/common/PageTitle'
import ReservationDialog from 'components/common/dialogs/ReservationDialog'
import ReservationArea from 'components/common/tables/ReservationArea'
import ReservationTable from 'components/common/tables/ReservationFacilitiesTable'
import ReservationTableHeader from 'components/common/tables/ReservationTableHeader'
import { useDailyReservationsState } from 'components/reservations/reservations/daily/DailyReservationsStateProvider'
import { ReservationDialogStateProvider } from 'components/reservations/reservation/ReservationStateProvider'

import styles from 'components/reservations/reservations/ReservationsForm.module.scss'
import type { EXEC_TYPE } from 'types/common/ExecType'
import { ReservationInsertInfo, ReservedInfo } from 'types/ui/ReservedInfo'
import { EXEC_INSERT, EXEC_UPDATE } from 'utils/constants'
import { currentDatetime, initTimes, isSameDay, nearestDatetime, toTimeUnit } from 'utils/dateUtil'
import SuspendedArea from 'components/common/tables/SuspendedArea'
import { useWindowDimension } from 'utils/windowUtils'
import ErrorDialog from 'components/common/dialogs/messages/ErrorDialog'

/**
 *
 * @returns
 */
const DailyReservationsForm = () => {
  //
  const navigate = useNavigate()
  const [params] = useSearchParams()
  const states = useDailyReservationsState()
  const [winWidth] = useWindowDimension()

  const [insertParams, setInsertParams] = useState<ReservationInsertInfo | null>(null)
  const [open, setOpen] = useState(false)
  const [execType, setExecType] = useState<EXEC_TYPE>(EXEC_INSERT)
  const [linkCaptions, setLinkCaptions] = useState<string[]>(['今日', '前日へ', '翌日へ'])
  const [insertBtnCaption, setInsertButtonCaption] = useState<string>('')
  const [calendarMonths, setCalendarMonths] = useState<number>(0)

  useLayoutEffect(() => {
    const target = document.getElementById('colFix1-0')
  }, [])

  useEffect(() => {
    const time = params.get('t')
    const dt = time ? new Date(parseInt(time, 10)) : currentDatetime()
    states.changeTargetDate(dt)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  /**
   *
   */
  useEffect(() => {
    setLinkCaptions(winWidth < 601 ? ['今日', '前日', '翌日'] : ['今日へ', '前日へ', '翌日へ'])
    setInsertButtonCaption(winWidth < 601 ? '＋' : '予約新規登録')
    // 常に３ヶ月分表示すると、カレンダーポップアップが見切れてしまうので、画面幅に合わせて表示月数を調整する。
    setCalendarMonths(winWidth <= 680 ? 1 : winWidth <= 1024 ? 2 : 3)
  }, [winWidth])

  const showDialogForCreate = () => {
    setExecType(() => EXEC_INSERT)
    setInsertParams({
      facilityId: 0,
      reservationDatetime: nearestDatetime(new Date()),
      timeUnit: 2
    })
    openDialog()
  }

  const showDialogForInsert = (params: ReservationInsertInfo) => {
    if (states.checkCurrentTime(params.reservationDatetime) === false) {
      return
    }
    setExecType(() => EXEC_INSERT)
    setInsertParams(params)
    openDialog()
  }

  const showDialogForUpdate = (params: ReservedInfo) => {
    setExecType(() => EXEC_UPDATE)
    states.selectTargetReservation(params)
    openDialog()
  }

  const openDialog = () => {
    setOpen(() => true)
  }

  const closeDialog = () => {
    setOpen(() => false)
    states.refresh()
    states.refreshSuspended()
  }

  const selectFacility = (facilityId: number) => {
    navigate(`/reservations/by-facility?id=${facilityId}`)
  }

  /**
   * 指定された日付（予約一覧の行）のどこまでの時間帯までが使用不可であるか？を算出する。
   * @param target
   * @returns
   */
  const disabledIndex = (target: Date) => {
    if (target === null) return 48
    const current = new Date()
    // 対象日が操作日なら、現在の時刻を含まない30分単位の時刻単位を返却
    if (isSameDay(target, current)) return Math.floor(toTimeUnit(current)) - 1
    // 操作日以外の場合、操作日の前日なら全てのセルは使用不可（48）、後日なら全てのセルは使用可能（-1）
    return target.getTime() < current.getTime() ? 48 : -1
  }
  const prevTimeIndex = disabledIndex(states.targetDate)

  /**
   *
   */
  const closeErrorDialog = () => {
    states.closeErrorDialog()
  }

  return (
    <>
      <ErrorDialog
        title={states.dialogTitle}
        contents={states.dialogContents}
        show={states.showErrorDialog}
        onOk={closeErrorDialog}
      />
      <PageTitle title="日別予約一覧" />
      <div className={styles.tablePreInfo}>
        <div className={styles.dummy}></div>
        <div className={styles.controlContainer}>
          <div className={styles.link}>
            <a href="#" onClick={states.goToday}>
              {linkCaptions[0]}
            </a>
          </div>
          <div className={styles.link}>
            <a href="#" onClick={states.goPrev}>
              {linkCaptions[1]}
            </a>
          </div>
          <div className={styles.datePicker}>
            <D1pDatePicker
              id="targetDate"
              months={calendarMonths}
              notShownPast
              selected={states.targetDate}
              onChange={states.changeTargetDate}
            />
          </div>
          <div className={styles.link}>
            <a href="#" onClick={states.goNext}>
              {linkCaptions[2]}
            </a>
          </div>
        </div>
        <div>
          <button className={styles.insertButton} onClick={showDialogForCreate}>
            {insertBtnCaption}
          </button>
        </div>
      </div>
      <div className={styles.tableFrame}>
        <div className={styles.table}>
          {/* テーブルヘッダー行 */}
          <div className={styles.headerRow}>
            <div className={`${styles.header} ${styles.colFix1} ${styles.index}`}>
              <div className={styles.headerCell}>#</div>
            </div>
            <div className={`${styles.header} ${styles.colFix2} ${styles.titleColumn}`}>
              <div className={styles.headerCell}>施設名</div>
            </div>
            <div className={`${styles.header} ${styles.reservationList}`}>
              <ReservationTableHeader />
            </div>
          </div>
          {/* テーブルデータ行 */}
          <ReservationTable
            items={states.facilitiesList}
            targetDate={states.targetDate}
            disabledIndex={prevTimeIndex}
            selectFacility={selectFacility}
            onClick={showDialogForInsert}
          >
            {/* 予約済みセル（フロート表示） */}
            {states.reservedList.map((r, index) => (
              <ReservationArea
                key={`daily-reservation-${index}`}
                id={index}
                rowIndex={r.rowIndex}
                type="daily"
                reservation={r}
                disabledIndex={prevTimeIndex}
                onClick={showDialogForUpdate}
              />
            ))}
            {/* 利用停止情報セル（フロート表示） */}
            {states.suspendedList.map((s, index) => (
              <SuspendedArea
                key={`suspended-${index}`}
                id={index}
                rowIndex={s.rowIndex!}
                suspendedInfo={s}
              />
            ))}
          </ReservationTable>
        </div>
      </div>

      {/* ==== 施設予約登録ダイアログ ==== */}
      {/* ダイアログ全体でStateを使いたい.ここでProviderで提供しておく */}
      <ReservationDialogStateProvider>
        <ReservationDialog
          show={open}
          insertParams={insertParams}
          updateParams={states.targetReservation}
          execType={execType}
          onClose={closeDialog}
        />
      </ReservationDialogStateProvider>
      {/* ここまで */}
    </>
  )
}

export default DailyReservationsForm
