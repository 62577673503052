import { useState, createContext, useContext } from 'react'
import { useNavigate } from 'react-router-dom'

import { useLoginState } from 'components/common/provider/LoginStateProvider'
import { LoginContractorInfo } from 'types/infos/LoginInfo'
import type { ProviderProps } from 'types/common/ProviderProps'
import { FATAL_ERROR_MESSAGE, FATAL_ERROR_TITLE, requiredMessage } from 'utils/messageUtil'
import { hasValue, hasNotValue } from 'utils/validators'
import { API_RESPONSE } from 'types/common/Api'
import { RESPONSE_NG } from 'utils/constants'
import { login } from 'api/contractors'

// ========================================================
/**
 * ログイン用グローバルStateContextのプロパティ定義.
 */
interface LoginStateProps {
  loginId: string
  password: string
  //
  msg4LoginId: string
  msg4Passwd: string
  //
  showErrorDialog: boolean
  dialogTitle: string
  dialogContents: string
  //
  changeLoginId: (inputEmail: string) => void
  changePassword: (inputPassword: string) => void
  //
  doLogin: () => void
  clear: () => void
  check: () => void
  logoutUser: () => void
  //
  closeErrorDialog: () => void
}

/**
 * 「ログイン」処理グローバルStateContext.
 * ユーザー入力値とその操作は、ここに格納される。
 */
export const LoginStateContext = createContext({} as LoginStateProps)

// ========================================================

/**
 * 「ログイン」処理グローバルStateを外部に公開するためのfunction.
 * @returns LoginStatePropsインスタンス
 */
export const useLoginUiState = () => useContext(LoginStateContext)

// ========================================================

/**
 * 「ログイン」処理グローバルStateProvider.
 * @param props ProviderProps
 * @returns
 */
export const LoginStateProvider = (props: ProviderProps) => {
  const navigate = useNavigate()
  const { setContractorInfo, isLogin, logout } = useLoginState()

  // 呼出元から渡される本Providerタグで囲まれたタグ群
  const { children } = props

  // --【ステート】------------------------------------------
  // 入力値ステート管理
  const [loginId, setLoginId] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  // エラーメッセージステート管理
  const [msg4LoginId, setMsg4LoginId] = useState<string>('')
  const [msg4Passwd, setMsg4Passwd] = useState<string>('')
  // ダイアログ系
  const [showErrorDialog, setShowErrorDialog] = useState<boolean>(false)
  const [dialogTitle, setDialogTitle] = useState<string>('')
  const [dialogContents, setDialogContents] = useState<string>('')

  // --【イベントハンドラ】------------------------------------------
  /**
   * 「メールアドレス」値チェンジイベント.
   * @param val
   */
  const changeLoginId = (val: string) => {
    setLoginId(() => val)
    // 入力値が発生したタイミングで、設定済みエラーメッセージは一旦消去する
    if (hasValue(val)) setMsg4LoginId(() => '')
  }

  /**
   * 「パスワード」値チェンジイベント.
   * @param inputPassword
   */
  const changePassword = (inputPassword: string) => {
    setPassword(() => inputPassword)
    // 入力値が発生したタイミングで、設定済みエラーメッセージは一旦消去する
    if (hasValue(password)) setMsg4Passwd(() => '')
  }

  const closeErrorDialog = (): void => {
    setShowErrorDialog(false)
  }

  /**
   * システムエラーメッセージを表示する
   */
  const showFatalErrorMessage = (): void => {
    setDialogTitle(FATAL_ERROR_TITLE)
    setDialogContents(FATAL_ERROR_MESSAGE)
    setShowErrorDialog(true)
  }

  /**
   * 「ログイン」ボタンクリックイベント
   */
  const doLogin = async () => {
    // 入力チェック
    const invalidEmail = !checkLoginId()
    const invalidPassword = !checkPassword()
    if (invalidEmail || invalidPassword) {
      setDialogTitle('エラー')
      setDialogContents('入力内容に誤りがあります。各項目をご確認ください。')
      setShowErrorDialog(true)
      return
    }

    try {
      const data: API_RESPONSE<LoginContractorInfo> = await login({ loginId, password })
      if (data.status === RESPONSE_NG) {
        const title = 'エラー'
        const message = data.msgs![0].message ?? ''
        setMsg4LoginId(message)
        setMsg4Passwd(message)

        setDialogTitle(title)
        setDialogContents(message)
        setShowErrorDialog(true)
        return
      }

      const loginInfo = data.data
      setContractorInfo(loginInfo)

      clear()

      // トップ画面へ遷移
      navigate('/')
    } catch (e) {
      // システムエラーメッセージの表示
      showFatalErrorMessage()
    }
  }

  /**
   * 入力値をクリアする.
   */
  const clear = () => {
    // 値のクリア
    setLoginId(() => '')
    setPassword(() => '')
    // エラーメッセージのクリア
    setMsg4LoginId(() => '')
    setMsg4Passwd(() => '')
  }

  const check = () => {
    console.log('IS LOGIN ? ', isLogin)
  }
  const logoutUser = async () => {
    await logout()
  }

  // --【バリデータ】------------------------------------------
  /**
   * ログインID入力チェック
   * @returns
   */
  const checkLoginId = (): boolean => {
    // 必須入力
    if (hasNotValue(loginId)) {
      setMsg4LoginId(() => requiredMessage('ログインID'))
      return false
    }

    // 桁数
    // 外部にログインIDの桁数を伏せておくため、入力チェックは実施しない

    return true
  }

  /**
   * パスワード入力チェック
   * @returns
   */
  const checkPassword = (): boolean => {
    // 必須入力
    if (hasNotValue(password)) {
      setMsg4Passwd(() => requiredMessage('パスワード'))
      return false
    }

    // 桁数範囲
    // 外部にパスワードの桁数を伏せておくため、入力チェックは実施しない

    // パスワードフォーマット
    // 外部にパスワードの入力規則を伏せておくため、入力チェックは実施しない
    // if (invalidPassword(password)) {
    //   setMsg4Passwd(() => passwordRegexpMessage())
    //   return false
    // }

    return true
  }

  // --【表示処理】------------------------------------------
  /**
   * 外部に公開するState群.
   */
  const globalStates: LoginStateProps = {
    loginId,
    password,
    msg4LoginId,
    msg4Passwd,
    showErrorDialog,
    dialogTitle,
    dialogContents,

    changeLoginId,
    changePassword,
    doLogin,
    clear,
    check,
    logoutUser,
    closeErrorDialog
  }

  return (
    // レイアウトコンポーネントを処理コンポーネントでラップする
    <LoginStateContext.Provider value={globalStates}>
      {/* children：レイアウト担当コンポーネント部 */}
      {children}
    </LoginStateContext.Provider>
  )
}
