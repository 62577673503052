import { useState } from 'react'

import PageTitle from 'components/common/PageTitle'
import { useReservationSuspendsState } from './ReservationSuspendsStateProvider'
import styles from 'components/facilities/suspends/ReservationSuspendsForm.module.scss'
import tStyles from 'styles/utils/table.module.scss'
import { toWarekiDatetime } from 'utils/dateUtil'
import { ReservationSuspendStateProvider } from '../suspend/ReservationSuspendStateProvider'
import ReservationSuspendDialog from 'components/common/dialogs/ReservationSuspendDialog'
import { EXEC_INSERT, EXEC_UPDATE } from 'utils/constants'
import { ReservationSuspendListElement } from 'types/ui/ReservationSuspendListElement'
import { EXEC_TYPE } from 'types/common/ExecType'
import EmptyList from 'components/common/tables/EmptyList'
import ErrorDialog from 'components/common/dialogs/messages/ErrorDialog'

/**
 *
 * @returns
 */
const ReservationSuspendsForm = () => {
  const states = useReservationSuspendsState()

  const [execType, setExecType] = useState<EXEC_TYPE>(EXEC_INSERT)
  const [open, setOpen] = useState<boolean>(false)

  const showDialogForInsert = () => {
    setExecType(() => EXEC_INSERT)
    openDialog()
  }

  const selectReservationSuspend = (reservationSuspend: ReservationSuspendListElement) => {
    setExecType(() => EXEC_UPDATE)
    states.selectReservationSuspend(reservationSuspend)
    openDialog()
  }

  const openDialog = () => {
    setOpen(() => true)
  }

  const closeDialog = () => {
    states.refresh()
    setOpen(false)
  }

  /**
   *
   */
  const closeErrorDialog = () => {
    states.closeErrorDialog()
  }

  return (
    <>
      <ErrorDialog
        title={states.dialogTitle}
        contents={states.dialogContents}
        show={states.showErrorDialog}
        onOk={closeErrorDialog}
      />
      <PageTitle title="施設利用停止状況"></PageTitle>
      <div className={tStyles.tablePreInfo}>
        <div></div>
        <div>
          <button
            className={tStyles.insertButton}
            style={{ width: '200px' }}
            onClick={showDialogForInsert}
          >
            利用停止情報新規登録
          </button>
        </div>
      </div>
      <div className={tStyles.tableFrame}>
        <div className={tStyles.commonTable}>
          <div className={tStyles.headerRow}>
            <div className={`${tStyles.headerColumn} ${styles.col1}`}>#</div>
            <div className={`${tStyles.headerColumn} ${styles.col2}`}>施設名</div>
            <div className={`${tStyles.headerColumn} ${styles.col3}`}>終日</div>
            <div className={`${tStyles.headerColumn} ${styles.col4}`}>停止開始日時</div>
            <div className={`${tStyles.headerColumn} ${styles.col5}`}>停止終了日時</div>
            <div className={`${tStyles.headerColumn} ${styles.col6}`}>理由</div>
          </div>
          {states.suspendedList.map((s, index) => (
            <div
              className={tStyles.dataRow}
              key={s.reservationSuspendedId}
              onClick={() => selectReservationSuspend(s)}
            >
              <div className={`${tStyles.dataColumn} ${styles.col1}`}>{index + 1}</div>
              <div className={`${tStyles.dataColumn} ${styles.col2}`}>
                {s.facilityName ? s.facilityName : '（全施設）'}
              </div>
              <div className={`${tStyles.dataColumn} ${styles.col3}`}>
                {s.isAllDay ? '終日' : ''}
              </div>
              <div className={`${tStyles.dataColumn} ${styles.col4}`}>
                {toWarekiDatetime(s.suspendStartDatetime, true)}
              </div>
              <div className={`${tStyles.dataColumn} ${styles.col5}`}>
                {toWarekiDatetime(s.suspendEndDatetime, true)}
              </div>
              <div className={`${tStyles.dataColumn} ${styles.col6}`}>{s.reason}</div>
            </div>
          ))}
          <EmptyList isEmpty={states.isListEmpty} />
        </div>
      </div>

      {/* ==== 通知登録ダイアログ ==== */}
      {/* ダイアログ全体でStateを使いたい.ここでProviderで提供しておく */}
      <ReservationSuspendStateProvider>
        <ReservationSuspendDialog
          execType={execType}
          reservationSuspendInfo={states.targetReservationSuspend!}
          show={open}
          onClose={closeDialog}
        />
      </ReservationSuspendStateProvider>
      {/* ここまで */}
    </>
  )
}

export default ReservationSuspendsForm
