import ChangePasswordForm from "components/changePassword/ChangePasswordForm";
import { ChangePasswordStateProvider } from "components/changePassword/ChangePasswordStateProvider";

const ChangePassword = () => {
  return (
    <ChangePasswordStateProvider>
      <ChangePasswordForm />
    </ChangePasswordStateProvider>
  );
};

export default ChangePassword;
