import { forwardRef } from 'react'
import DatePicker, { registerLocale } from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

import { Tooltip } from 'react-tooltip'
import ja from 'date-fns/locale/ja'

import { FORMAT_DATETIME, FORMAT_DATE, LOCALE } from 'utils/constants'
import styles from 'components/common/atoms/D1pDatePicker.module.scss'
import { nearestDatetime } from 'utils/dateUtil'

// 日本語化
registerLocale('ja', ja)

/**
 *
 */
interface Props {
  id: string
  width?: number
  showTime?: boolean
  showYearMonth?: boolean
  months?: number
  clearButton?: boolean
  notShownPast?: boolean
  // 選択日付
  selected: Date | null
  // チェンジイベントハンドラ
  onChange: (date: Date) => void
  // エラー情報
  errorMsg?: string
}

/**
 *
 * @param param0
 * @returns
 */
const D1pDatePicker = ({
  id,
  width,
  showTime,
  showYearMonth,
  months,
  clearButton,
  notShownPast,
  selected,
  onChange,
  errorMsg
}: Props) => {
  const showTimeSelect = showTime ?? false
  const showMonthYearPicker = showYearMonth ?? false
  const showMonthCount = months ?? 1
  const showClearButton = clearButton ?? false
  const minDate = notShownPast ? new Date() : null
  const datetimeFmt = showTimeSelect ? FORMAT_DATETIME : FORMAT_DATE
  const isShowTooltip = !errorMsg
  const bgColor = !!errorMsg ? { backgroundColor: 'pink' } : {}
  const specifiedWidth = width ? { width: `${width}px` } : { width: '140px' }
  const textWidth = showTimeSelect ? { width: '200px' } : specifiedWidth
  const style = { ...bgColor, ...textWidth }

  const currentDate = new Date()
  const currentHour = Math.floor(currentDate.getTime() / 1000 / 60 / 60)
  const currentMinutes = currentDate.getMinutes()

  const filterPassedTime = (selectedDate: Date) => {
    const selectedHour = Math.floor(selectedDate.getTime() / 1000 / 60 / 60)
    const diff = selectedHour - currentHour
    // 時間単位で過去は使用不可
    if (diff < 0) return false
    // 時間単位で未来は使用可能
    if (diff > 0) return true

    // 当日の場合は、現在の時刻を含む30分単位が使用可能と判定する
    // 現時刻が「0 ~ 29分」なら、xx:00とxx:30が使用可能
    if (currentMinutes < 30) return true
    // 現時刻が「30分以上」なら、xx:30のみが使用可能
    return selectedDate.getMinutes() === 30
  }

  /**
   *
   */
  const CustomInputByButton = forwardRef<HTMLButtonElement>(({ value, onClick }: any, ref) => {
    const displayValue = showYearMonth ? value.substring(0, 7) : value
    return (
      <button className={styles.customInput} style={style} onClick={onClick} ref={ref}>
        {displayValue}
      </button>
    )
  })

  return (
    <>
      <div data-tooltip-id={`date-picker-tooltip_${id}`}>
        <DatePicker
          dateFormat={datetimeFmt}
          locale={LOCALE}
          selected={selected}
          minDate={minDate}
          onChange={onChange}
          showTimeSelect={showTimeSelect}
          filterTime={filterPassedTime}
          monthsShown={showMonthCount}
          isClearable={showClearButton}
          className={styles.datePicker}
          customInput={<CustomInputByButton />}
          showMonthYearPicker={showMonthYearPicker}
        />
        {/* エラー表示用ツールチップ */}
        <Tooltip
          id={`date-picker-tooltip_${id}`}
          delayShow={300}
          hidden={isShowTooltip}
          place="top-start"
          style={{
            backgroundColor: 'lightgray',
            borderRadius: '10px',
            color: 'red'
          }}
        >
          <h3>{errorMsg}</h3>
        </Tooltip>
      </div>
    </>
  )
}

export default D1pDatePicker
