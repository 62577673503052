import { Helmet } from 'react-helmet-async'
import ContractorFormReadonly from 'components/contractors/contractor/ContractorFormReadonly'
import { ContractorStateProvider } from 'components/contractors/contractor/ContractorStateProvider'
import { TITLE_CONTRACTOR_INFO } from 'utils/constants'

/**
 *
 * @returns
 */
const Contractor = () => {
  return (
    <ContractorStateProvider>
      <Helmet>
        <title>{TITLE_CONTRACTOR_INFO}</title>
      </Helmet>
      <ContractorFormReadonly />
    </ContractorStateProvider>
  )
}

export default Contractor
