import styles from 'components/common/UserOverview.module.scss'
import { useLoginState } from './provider/LoginStateProvider'

const UserOverview = () => {
  const { isAdmin, loginContractorName } = useLoginState()
  const userName = isAdmin() ? '管理者' : loginContractorName

  return (
    <div className={styles.userOverviewContainer}>
      <div className={styles.userName}>ようこそ、{userName} 様</div>
    </div>
  )
}

export default UserOverview
