import { Helmet } from 'react-helmet-async'
import FacilitiesForm from 'components/facilities/facilities/FacilitiesForm'
import { FacilitiesStateProvider } from 'components/facilities/facilities/FacilitiesStateProvider'
import { TITLE_FACILITY_MANAGE } from 'utils/constants'

/**
 * 施設一覧画面
 */
const Facilities = () => {
  return (
    <>
      <FacilitiesStateProvider>
        <Helmet>
          <title>{TITLE_FACILITY_MANAGE}</title>
        </Helmet>
        <FacilitiesForm />
      </FacilitiesStateProvider>
    </>
  )
}

export default Facilities
