import { Helmet } from 'react-helmet-async'
import DownloadFilesForm from 'components/downloads/downloadFiles/DownloadFilesForm'
import { DownloadFilesStateProvider } from 'components/downloads/downloadFiles/DownloadFilesStateProvider'
import NotificationsForm from 'components/notifications/notifications/NotificationsForm'
import { NotificationsStateProvider } from 'components/notifications/notifications/NotificationsStateProvider'
import styles from 'pages/common/Top.module.scss'
import { TITLE_CONTRACTOR_TOP } from 'utils/constants'

const Top = () => {
  return (
    <>
      <Helmet>
        <title>{TITLE_CONTRACTOR_TOP}</title>
      </Helmet>
      <NotificationsStateProvider>
        <NotificationsForm forContractor />
      </NotificationsStateProvider>
      <div className={styles.spacer} />
      <DownloadFilesStateProvider>
        <DownloadFilesForm forContractor />
      </DownloadFilesStateProvider>
    </>
  )
}

export default Top
