import { useState } from 'react'

import styles from 'components/notifications/Notifications.module.scss'
import tStyles from 'styles/utils/table.module.scss'
import { useNotificationsState } from './NotificationsStateProvider'
import { NotificationRow } from 'types/rows/NotificationRow'
import { toWarekiDatetime } from 'utils/dateUtil'
import { NotificationStateProvider } from '../notification/NotificationStateProvider'
import { EXEC_INSERT, EXEC_UPDATE } from 'utils/constants'
import NotificationDialog from 'components/common/dialogs/NotificationDialog'
import PageTitle from 'components/common/PageTitle'
import { EXEC_TYPE } from 'types/common/ExecType'
import { useLoginState } from 'components/common/provider/LoginStateProvider'
import EmptyList from 'components/common/tables/EmptyList'
import ErrorDialog from 'components/common/dialogs/messages/ErrorDialog'

/**
 * 通知一覧画面フォーム
 * @returns
 */
const NotificationsForm = ({ forContractor = false }) => {
  const lStates = useLoginState()
  const states = useNotificationsState()

  const [execType, setExecType] = useState<EXEC_TYPE>(EXEC_INSERT)
  const [open, setOpen] = useState<boolean>(false)

  const showInsertButton = lStates.isAdmin()

  const showDialogForInsert = () => {
    setExecType(() => EXEC_INSERT)
    openDialog()
  }

  const selectNotification = (notification: NotificationRow) => {
    setExecType(() => EXEC_UPDATE)
    states.selectNotification(notification)
    openDialog()
  }

  const openDialog = () => {
    setOpen(() => true)
  }

  const closeDialog = () => {
    states.refresh()
    setOpen(() => false)
  }

  /**
   *
   */
  const closeErrorDialog = () => {
    states.closeErrorDialog()
  }

  const emptyMsg = lStates.isAdmin() ? null : 'お知らせはございません。'

  const scrollable = forContractor ? tStyles.scrollable : ''

  return (
    <>
      <ErrorDialog
        title={states.dialogTitle}
        contents={states.dialogContents}
        show={states.showErrorDialog}
        onOk={closeErrorDialog}
      />
      <PageTitle title="お知らせ一覧"></PageTitle>
      {showInsertButton && (
        <div className={tStyles.tablePreInfo}>
          {/* 以下、右寄せのためのダミーのdiv */}
          <div></div>
          <div>
            <button className={tStyles.insertButton} onClick={showDialogForInsert}>
              お知らせ新規登録
            </button>
          </div>
        </div>
      )}
      <div className={`${tStyles.tableFrame} ${scrollable}`}>
        <div className={tStyles.commonTable}>
          <div className={tStyles.headerRow}>
            <div className={`${tStyles.headerColumn} ${styles.col1}`}>#</div>
            <div className={`${tStyles.headerColumn} ${styles.col2}`}>配信日時</div>
            <div className={`${tStyles.headerColumn} ${styles.col3}`}>タイトル</div>
            <div className={`${tStyles.headerColumn} ${styles.col4}`}>詳細</div>
          </div>

          {states.notificationList.map((notification: NotificationRow, index) => {
            return (
              <div
                className={tStyles.dataRow}
                key={notification.notificationId}
                onClick={(_) => selectNotification(notification)}
              >
                <div className={`${tStyles.dataColumn} ${styles.col1}`}>{index + 1}</div>
                <div className={`${tStyles.dataColumn} ${styles.col2}`}>
                  {toWarekiDatetime(new Date(notification.updatedAt))}
                </div>
                <div className={`${tStyles.dataColumn} ${styles.col3}`}>{notification.title}</div>
                <div className={`${tStyles.dataColumn} ${styles.col4}`}>
                  {notification.description}
                </div>
              </div>
            )
          })}
          <EmptyList isEmpty={states.isListEmpty} msg={emptyMsg} />
        </div>
      </div>

      {/* ==== 通知登録ダイアログ ==== */}
      {/* ダイアログ全体でStateを使いたい.ここでProviderで提供しておく */}
      <NotificationStateProvider>
        <NotificationDialog
          execType={execType}
          notificationInfo={states.targetNotification!}
          show={open}
          onClose={closeDialog}
        />
      </NotificationStateProvider>
      {/* ここまで */}
    </>
  )
}

export default NotificationsForm
