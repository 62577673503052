import { useEffect } from 'react'
import ReservationForm from '../../reservations/reservation/ReservationForm'
import { useReservationDialogState } from '../../reservations/reservation/ReservationStateProvider'
import { AppBar, Button, Dialog, Toolbar, Typography } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { ReservationInsertInfo, ReservedInfo } from 'types/ui/ReservedInfo'
import { EXEC_INSERT, EXEC_UPDATE } from 'utils/constants'
import { Transition } from './CommonTransition'
import { EXEC_TYPE } from 'types/common/ExecType'

/**
 *
 */
interface ReservationDialogProps {
  show: boolean
  execType: EXEC_TYPE
  insertParams?: ReservationInsertInfo | null
  updateParams?: ReservedInfo | null
  onClose?: () => void
}

/**
 *
 * @returns
 */
const ReservationDialog = (props: ReservationDialogProps) => {
  //
  const states = useReservationDialogState()
  //
  const { show, execType, insertParams, updateParams, onClose } = props

  // 画面表示時（レンダリング時ではなく、表示される度に実施）
  useEffect(() => {
    // クローズ時は入力値をクリア
    if (show === false) {
      states.clear()
      return
    }

    // 新規作成
    if (execType === EXEC_INSERT) {
      states.setDialogModeOnOpen(EXEC_INSERT)
      // 画面の初期化のみ
      states.init(insertParams!)
      // if (insertParams) {
      // const datetime = insertParams.reservationDatetime
      // states.clearReservationId()
      // states.changeFacilityId(insertParams.facilityId)
      // states.changeReservationDatetime(datetime)
      // states.changeTimeUnit(insertParams.timeUnit!.toString())
      // ログインユーザーで予約者情報を表示する
      // states.initReserver()
      // }
      return
    }

    // 更新
    if (execType === EXEC_UPDATE) {
      states.setDialogModeOnOpen(EXEC_UPDATE)
      // 予約IDを元に、予約情報を取得
      // if (!updateParams) throw new Error('パラメータ不正')
      if (!updateParams) return
      states.refresh(updateParams)

      return
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show])

  /**
   *
   */
  const handleClose = () => {
    onClose!()
  }

  return (
    <div>
      <Dialog fullScreen open={show} onClose={handleClose} TransitionComponent={Transition}>
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              施設予約
            </Typography>
            <Button autoFocus color="inherit" onClick={handleClose}>
              <CloseIcon />
            </Button>
          </Toolbar>
        </AppBar>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <ReservationForm doClose={() => onClose!()} execType={execType} />
        </div>
      </Dialog>
    </div>
  )
}

export default ReservationDialog
