import styles from 'components/common/atoms/Label.module.scss'

interface LabelProps {
  title: string
  required?: boolean
}
const Label = ({ title, required = false }: LabelProps) => {
  const requiredMark = required ? <span className={styles.required}>*</span> : null

  return (
    <div className={styles.labelContainer}>
      <span className={styles.label}>{title}</span>
      {requiredMark}
    </div>
  )
}

export default Label
