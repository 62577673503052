import lodash, { cloneDeep } from 'lodash'
import { UNIT_TO_TIME_ARRAY, WEEKDAY_CAPTIONS } from './constants'

// ======================================================================
interface TERM {
  from: Date | null
  to: Date | null
}

export const INDEX_TO_TIME_ARRAY: string[] = []

/**
 * 予約時間数(30分単位)の表示リストを初期化する。
 */
const initMap = () => {
  let minute: string[] = ['00', '30']
  for (let i = 0; i < 24; i++) {
    for (let j = 0; j < 2; j++) {
      INDEX_TO_TIME_ARRAY.push(i.toString() + ':' + minute[j] + ' ~')
    }
  }
}
initMap()

/**
 *
 * @returns
 */
export const getIndexToTimeMap = (): string[] => {
  if (INDEX_TO_TIME_ARRAY.length === 0) initMap()
  return INDEX_TO_TIME_ARRAY
}

/**
 *
 * @param unit
 * @returns
 */
export const timeUnitToTime = (unit: number): string => {
  return UNIT_TO_TIME_ARRAY[unit].label
}

// ======================================================================
//
// ======================================================================

/**
 * システム日付（年月日部分のみ）をDate型で取得する
 * @returns
 */
export const currentDate = (): Date => {
  const d = new Date()
  d.setHours(0)
  d.setMinutes(0)
  d.setSeconds(0)
  d.setMilliseconds(0)
  return d
}

/**
 * システム日付（現在の時刻を基準に30分単位で切り上げた時刻を保持）をDate型で取得する。
 * @returns
 */
export const currentDatetime = (forEnd: boolean = false): Date => {
  const d = new Date()
  let hours: number = d.getMinutes() < 30 ? d.getHours() : d.getHours() + 1
  if (forEnd) hours++
  const minutes: number = d.getMinutes() < 30 ? 30 : 0
  d.setHours(hours)
  d.setMinutes(minutes)
  d.setSeconds(0)
  d.setMilliseconds(0)
  return d
}

/**
 * 現在の時刻から直近の30分単位の日時を取得す流。
 * @param d
 * @returns
 */
export const nearestDatetime = (d: Date): Date => {
  let hours: number = d.getMinutes() < 30 ? d.getHours() : d.getHours() + 1
  const minutes: number = d.getMinutes() < 30 ? 30 : 0
  d.setHours(hours)
  d.setMinutes(minutes)
  d.setSeconds(0)
  d.setMilliseconds(0)
  return d
}

/**
 * 操作日の「ついたち」のDateを取得（時/分/秒/ミリ秒は0）。
 * @returns
 */
export const currentFirstDate = (): Date => {
  const d = new Date()
  d.setDate(1)
  return clearTimes(d)
}

/**
 * 操作日のデフォルト日時（9:00）を保持したDateを取得する。
 * @returns
 */
export const getDefaultCurrentDate = (): Date => {
  const d = new Date()
  return initTimes(d)
}

/**
 * 指定年月の「ついたち」を取得する。
 * @param date
 * @returns
 */
export const firstDateOfMonth = (date: Date): Date => {
  const newDate = lodash.cloneDeep(date)
  newDate.setDate(1)
  return initTimes(newDate)
}

export const firstDateOfCurrentMonth = (): Date => {
  const date = initTimes(new Date())
  date.setDate(1)
  return date
}
export const firstDateOfNextMonth = (): Date => {
  const currentMonth = firstDateOfCurrentMonth()
  return addMonth(currentMonth, 1)
}
export const firstDateOfAfterNextMonth = (): Date => {
  const currentMonth = firstDateOfCurrentMonth()
  return addMonth(currentMonth, 2)
}

/**
 *
 * @param date
 * @returns
 */
export const lastDateOfMonth = (date: Date): Date => {
  const newDate = lodash.cloneDeep(date)
  newDate.setMonth(newDate.getMonth() + 1, 0)
  return newDate
}

/**
 * 指定されたDateの最初の時刻(つまり、当日の00:00:00となる。時/分/秒/ミリ秒は0)を返却する。
 * @param date
 * @returns
 */
export const startTime = (date: Date): Date => {
  const d = lodash.cloneDeep(date)
  d.setDate(d.getDate())
  updateTimesClear(d)
  return d
}

/**
 * 指定されたDateの最終時刻(つまり、翌日の00:00:00となる。時/分/秒/ミリ秒は0)を返却する。
 * @param date
 * @returns
 */
export const endTime = (date: Date): Date => {
  const d = lodash.cloneDeep(date)
  d.setDate(d.getDate() + 1)
  d.setHours(0)
  d.setMinutes(0)
  d.setSeconds(0)
  d.setMilliseconds(0)
  return d
}

/**
 * 予約（開始）日時と予約時間数単位から、終了時刻を生成・取得する
 * @param startDatetime 予約（開始）日時
 * @param timeUnit 予約時間数単位
 * @returns 終了時刻（string）
 */
export const getEndTime = (startDatetime: Date | null, timeUnit: number): string => {
  if (startDatetime === null) return ''
  if (timeUnit === -1) return ''

  const date = calcEndTime(startDatetime, timeUnit)!
  return date.getHours() + ':' + toTwoChars(date.getMinutes())
}

/**
 * 開始日時（30分単位の値が設定されている前提）に、時間単位（1 -> 0:30, 2-> 1:00, ..）を加算した終了日時を算出する。
 * @param startDatetime 予約（開始）日時
 * @param timeUnit 予約時間数単位
 * @returns 終了日時（Date）
 */
export const calcEndTime = (startDatetime: Date | null, timeUnit: number): Date | null => {
  if (startDatetime === null) return null
  if (timeUnit === -1) return null

  // パラメータをディープコピー
  const newDatetime = lodash.cloneDeep(startDatetime)

  // 時間単位から"H:mm"文字列を取得し、時間数と分数に分割
  const [addHours, newMinutes] = splitHoursAndMinutes(timeUnit)
  // 指定された日時に対して、時間数と分数を設定する
  newDatetime.setHours(startDatetime.getHours() + addHours)
  newDatetime.setMinutes(startDatetime.getMinutes() + newMinutes)

  return newDatetime
}

/**
 * 日またぎで開始日時と終了日時が設定されていないかを判定する。
 *
 * 【前提条件】
 * ・終了日時が「翌日の0:00」の場合は、同一日と判定する。
 * ・開始日と終了日は、三十分単位の時刻が設定されている。
 * ・開始日と終了日は、１日以上の開きはない。
 *
 * @param startDateTime Date 開始日時
 * @param endDateTime Date 終了日時
 * @returns
 */
export const isSameDay = (startDateTime: Date, endDateTime: Date): boolean => {
  // 年の比較
  const years = endDateTime.getFullYear() - startDateTime.getFullYear()
  if (years !== 0) return false
  // 月の比較
  const months = endDateTime.getMonth() - startDateTime.getMonth()
  if (months !== 0) return false

  // 日の比較
  const days = endDateTime.getDate() - startDateTime.getDate()
  // 日にちが同一なら同一日
  if (days === 0) return true
  // 終了日時が翌日の「0:00」なら同一日とみなす（つまり、翌日0:00を当日の24:00と判定する）
  if (days === 1 && endDateTime.getHours() === 0 && endDateTime.getMinutes() === 0) return true
  return false
}

export const isSameDatetime = (from: Date, to: Date): boolean => {
  return (
    from.getFullYear() === to.getFullYear() &&
    from.getMonth() === to.getMonth() &&
    from.getDate() === to.getDate() &&
    from.getHours() === to.getHours() &&
    from.getMinutes() === to.getMinutes()
  )
}

/**
 * 開始日時と予約時間単位から[開始月日、開始時刻、終了時刻]の配列を生成する
 * @param startDatetime
 * @param timeUnit
 */
export const toDateStartTimeEndTime = (startDatetime: Date, timeUnit: number): string[] => {
  //
  const warekiYmd = toWareki(startDatetime)
  const endDatetime = calcEndTime(startDatetime, timeUnit)
  return [warekiYmd, retrieveTime(startDatetime), retrieveTime(endDatetime!)]
}

/**
 *
 * @param dateTime
 * @returns
 */
export const retrieveTime = (dateTime: Date): string => {
  const h = dateTime.getHours()
  const m = dateTime.getMinutes()
  return `${h}:${toTwoChars(m)}`
}

/**
 *
 * @param timeUnit
 * @returns
 */
const splitHoursAndMinutes = (timeUnit: number): number[] => {
  const time = getIndexToTimeMap()[timeUnit]
  const [hoursMinutes, _] = time.split(' ') // 末尾に「 ~」が付いているので取り除いている 要修正
  const [hours, minutes] = hoursMinutes.split(':')
  return [parseInt(hours, 10), parseInt(minutes, 10)]
}

// ======================================================================
// 月日移動系
// ======================================================================

/**
 * 指定された月日の前日を取得する
 * @param data 指定月日
 * @returns 前日（Date）
 */
export const prevDate = (data: Date): Date => {
  return addDate(data, -1)
}

/**
 * 指定された月日の翌日を取得する。
 * @param data 指定月日
 * @returns 翌日（Date）
 */
export const nextDate = (data: Date): Date => {
  return addDate(data, 1)
}

/**
 * 指定月日に指定された日数を加算・減算する。
 * @param data 指定月日
 * @param add 加算日数（減算時は負数を指定）
 * @returns
 */
export const addDate = (date: Date, add: number): Date => {
  const newDate = lodash.cloneDeep(date)
  newDate.setDate(newDate.getDate() + add)
  return newDate
}

/**
 *
 * @param date
 * @param add
 * @returns
 */
export const addMonth = (date: Date, add: number): Date => {
  const newDate = lodash.cloneDeep(date)
  newDate.setMonth(newDate.getMonth() + add)
  return newDate
}

// ======================================================================
// 時間単位系
// ======================================================================

/**
 * 指定されたDateを「0:00から30分単位で分割した単位数」を返却する。
 * @param date
 * @returns
 */
export const toTimeUnit = (date: Date): number => {
  if (!date) return -1
  const hours = date.getHours()
  const minutes = date.getMinutes()
  return hours * 2 + minutes / 30
}

/**
 * ２つのDateの間隔を日にち単位で取得する。
 * @param date1
 * @param date2
 * @returns
 */
export const diffByDay = (date1: Date, date2: Date): number => {
  const diffByMs = clearTimes(date1).getTime() - clearTimes(date2).getTime()
  const diffByDay = diffByMs / 1000 / 60 / 60 / 24
  return diffByDay
}

/**
 * 時間単位：２つのDateの間隔を時間単位数で取得する。
 * @param date1
 * @param date2
 * @returns
 */
export const diffByTimeUnit = (date1: Date, date2: Date): number => {
  const diffByMs = date1.getTime() - date2.getTime()
  const diffByUnit = diffByMs / 1000 / 60 / 30
  return diffByUnit
}

/**
 * クリックされた列INDEX（0:00開始、30分単位で１INDEX）から日時の値を生成する。
 * （施設別予約一覧で使用している）
 * @param colIndex
 * @returns
 */
export const calcSelectDatetime = (date: Date, colIndex: number): Date => {
  const newDate = cloneDeep(date)
  newDate.setHours(colIndex / 2)
  newDate.setMinutes(colIndex % 2 === 0 ? 0 : 30)
  newDate.setSeconds(0)
  return newDate
}

// ======================================================================
// フォーマット系
// ======================================================================

/**
 * Date「年月日(曜日)」を和暦に変換する。
 * @param date
 * @returns
 */
export const toWareki = (date: Date | null, half: boolean = false): string => {
  if (date === null) return ''

  const parentheses = half ? ['(', ')'] : ['（', '）']
  const year = date.getFullYear()
  const month = toTwoChars(date.getMonth() + 1)
  const day = toTwoChars(date.getDate())
  const weekday = WEEKDAY_CAPTIONS[date.getDay()]
  return `${year}/${month}/${day}${parentheses[0]}${weekday}${parentheses[1]}`
}

/**
 * Date「年月日(曜日)」を和暦に変換する。
 * @param date
 * @returns
 */
export const toWarekiDate = (date: Date | null, half: boolean = false): string => {
  if (date === null) return ''

  const year = date.getFullYear()
  const month = toTwoChars(date.getMonth() + 1)
  const day = toTwoChars(date.getDate())
  return `${year}/${month}/${day}`
}

/**
 * Datetime「年月日(曜日)時分」 を和暦に変換する
 * @param date
 * @param half
 * @returns
 */
export const toWarekiDatetime = (date: Date | null, half: boolean = false): string => {
  if (date === null) return ''

  const parentheses = half ? ['(', ')'] : ['（', '）']
  const year = date.getFullYear()
  const month = toTwoChars(date.getMonth() + 1)
  const day = toTwoChars(date.getDate())
  const weekday = WEEKDAY_CAPTIONS[date.getDay()]
  const hours = toTwoChars(date.getHours())
  const minutes = toTwoChars(date.getMinutes())
  return `${year}/${month}/${day}${parentheses[0]}${weekday}${parentheses[1]} ${hours}:${minutes}`
}

/**
 * Datetime「年月日(曜日)時分秒」 を和暦に変換する
 * @param date
 * @param half
 * @returns
 */
export const toWarekiTillSeconds = (date: Date | null, half: boolean = false): string => {
  if (date === null) return ''

  const parentheses = half ? ['(', ')'] : ['（', '）']
  const year = date.getFullYear()
  const month = toTwoChars(date.getMonth() + 1)
  const day = toTwoChars(date.getDate())
  const weekday = WEEKDAY_CAPTIONS[date.getDay()]
  const hours = toTwoChars(date.getHours())
  const minutes = toTwoChars(date.getMinutes())
  const seconds = toTwoChars(date.getSeconds())
  return `${year}/${month}/${day}${parentheses[0]}${weekday}${parentheses[1]} ${hours}:${minutes}:${seconds}`
}

// ======================================================================
//
// ======================================================================

/**
 * 開始日時と終了日時から、日別の開始日時と終了日時の配列に変換する。
 * @param from
 * @param to
 * @returns
 */
export const splitByDay = (from: Date, to: Date): TERM[] => {
  const ary: TERM[] = []
  const days = calcDaysCount(from, to)

  // 日付ごとの「開始時刻 ~ 終了時刻」の配列を生成
  for (let i = 0; i <= days; i++) {
    const current = addDate(from, i)

    if (i === 0) {
      // 複数日の利用停止期間の初日の場合 -> 指定開始日時 ~ 翌日の00:00:00
      ary.push({ from, to: endTime(from) })
      // end if
    } else if (i === days) {
      // 複数日の利用停止期間の最終日の場合 -> 当日の00:00:00 ~ 指定終了日時
      const sTime = startTime(current)
      // 複数日にわたる利用停止の場合、最終日が00:00:00 ~ 00:00:00と設定される場合がある
      if (isSameDatetime(sTime, to) === false) ary.push({ from: sTime, to })
      // end if
    } else {
      // 初日でも最終日でもない場合 -> その日の00:00:00 ~ 翌日の00:00:00
      ary.push({ from: startTime(current), to: endTime(current) })
    }
  }

  return ary
}

// ======================================================================
// ユーティリティ系
// ======================================================================

export const updateTimesClear = (d: Date): void => {
  d.setHours(0)
  d.setMinutes(0)
  d.setSeconds(0)
  d.setMilliseconds(0)
}

/**
 * 指定されたDateの時/分/秒/ミリ秒をクリアして返却する。
 * @param date
 * @returns
 */
export const clearTimes = (date: Date): Date => {
  const d = lodash.cloneDeep(date)
  d.setHours(0)
  d.setMinutes(0)
  d.setSeconds(0)
  d.setMilliseconds(0)
  return d
}

/**
 * 指定されたDateの「業務的な初期時刻 - 9:00」を返却する。
 * @param date
 * @returns
 */
export const initTimes = (date: Date): Date => {
  const d = lodash.cloneDeep(date)
  d.setHours(9) // 時刻は9:00でクリア
  d.setMinutes(0)
  d.setSeconds(0)
  d.setMilliseconds(0)
  return d
}

/**
 *
 * @param from
 * @param to
 * @returns
 */
export const calcDaysCount = (from: Date, to: Date): number => {
  const diffByMs = to.getTime() - from.getTime()
  return Math.ceil(diffByMs / 1000 / 60 / 60 / 24)
}

/**
 * 先頭に"0"を付与した２文字に変換する（古いやり方だな..）
 * @param val
 * @returns
 */
const toTwoChars = (val: number): string => {
  return val < 10 ? '0' + val.toString() : val.toString()
}

/**
 *
 * @param d
 * @returns
 */
export const toJpDate = (d: Date) => {
  d.setHours(d.getHours() + 9)
  return d
}
