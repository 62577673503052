import { insertDownloadFile, removeDownloadFile, updateDownloadFile } from 'api/downloadFiles'
import { useGlobalState } from 'components/common/provider/GlobalStateProvider'
import { createContext, useContext, useState } from 'react'
import { EXEC_TYPE } from 'types/common/ExecType'
import { ProviderProps } from 'types/common/ProviderProps'
import { DownloadFileInfo } from 'types/infos/DownloadFileInfo'
import { EXEC_DELETE, EXEC_INSERT, EXEC_UPDATE } from 'utils/constants'
import {
  COMMON_ERROR_MESSAGE,
  CONTAIN_ERROR_TITLE,
  FATAL_ERROR_MESSAGE,
  FATAL_ERROR_TITLE,
  getConfirmMessage,
  getConfirmTitle,
  getNotifyMessage,
  getNotifyTitle,
  lengthLessThanMessage,
  requiredMessage
} from 'utils/messageUtil'
import { hasNotValue, invalidMaxLength } from 'utils/validators'

interface UploadFileState {
  publicName: string
  filename: string
  originalName: string
  filepath: string
  isSuspended: boolean
  file: File | null

  //
  msg4PublicName: string
  msg4File: string
  msg4IsSuspended: string

  // ダイアログ属性
  dialogTitle: string
  dialogContents: string
  showErrorDialog: boolean
  showNotifyDialog: boolean
  showConfirmDialog: boolean

  changePublicName: (val: string) => void
  changeFilename: (val: string) => void
  changeIsSuspended: (val: boolean) => void
  changeFile: (file: File | null) => void

  closeErrorDialog: () => void
  closeNotifyDialog: () => void
  closeConfirmDialog: () => void

  refresh: (type: EXEC_TYPE, info?: DownloadFileInfo) => void
  insert: () => void
  update: () => void
  remove: () => void
  clear: () => void
  // コールバックメソッド
  doCallback: () => Promise<void>
}

/**
 *
 *
 */
export const UploadFileStateContext = createContext({} as UploadFileState)

/**
 *
 * @returns
 */
export const useUploadFileState = () => useContext(UploadFileStateContext)

/**
 *
 * @param props
 * @returns
 */
export const UploadFileStateProvider = (props: ProviderProps) => {
  const { children } = props
  const gStates = useGlobalState()

  const [openMode, setOpenMode] = useState<EXEC_TYPE>(EXEC_INSERT)
  const [execType, setExecType] = useState<EXEC_TYPE | null>(null)

  const [originalUploadFile, setOriginalUploadFile] = useState<DownloadFileInfo | null>(null)
  const [downloadFileId, setDownloadFileId] = useState<number | null>(null)
  const [publicName, setPublicName] = useState<string>('')
  const [originalName, setOriginalName] = useState<string>('')
  const [filename, setFilename] = useState<string>('')
  const [filepath, setFilepath] = useState<string>('')
  const [isSuspended, setIsSuspended] = useState<boolean>(false)
  const [file, setFile] = useState<File | null>(null)

  const [msg4PublicName, setMsg4PublicName] = useState<string>('')
  const [msg4File, setMsg4File] = useState<string>('')
  const [msg4IsSuspended, setMsg4IsSuspended] = useState<string>('')

  const [dialogTitle, setDialogTitle] = useState<string>('')
  const [dialogContents, setDialogContents] = useState<string>('')
  const [showNotifyDialog, setShowNotifyDialog] = useState<boolean>(false)
  const [showConfirmDialog, setShowConfirmDialog] = useState<boolean>(false)
  const [showErrorDialog, setShowErrorDialog] = useState<boolean>(false)

  const changeFile = (file: File | null) => {
    const name = file ? file.name : ''
    setOriginalName(name)
    setFile(file)
    setMsg4File('')
  }

  const changePublicName = (val: string) => {
    setPublicName(val)
    setMsg4PublicName('')
  }

  const changeFilename = (val: string) => {
    setFilename(val)
    setMsg4File('')
  }

  const changeIsSuspended = (val: boolean) => {
    setIsSuspended(val)
  }

  // ダイアログ定義
  const closeErrorDialog = () => setShowErrorDialog(false)
  const closeConfirmDialog = () => setShowConfirmDialog(false)
  const closeNotifyDialog = () => setShowNotifyDialog(false)

  /**
   * エラーメッセージを表示する
   */
  const showErrorMessage = (): void => {
    setDialogTitle(CONTAIN_ERROR_TITLE)
    setDialogContents(COMMON_ERROR_MESSAGE)
    setShowErrorDialog(true)
  }

  /**
   * システムエラーメッセージを表示する
   */
  const showFatalErrorMessage = (): void => {
    setDialogTitle(FATAL_ERROR_TITLE)
    setDialogContents(FATAL_ERROR_MESSAGE)
    setShowErrorDialog(true)
  }

  /**
   * 確認メッセージを表示する
   * @param type
   */
  const showConfirmMessage = (type: EXEC_TYPE): void => {
    setDialogTitle(getConfirmTitle(type))
    setDialogContents(getConfirmMessage(type, 'アップロードファイル'))
    setShowConfirmDialog(true)
  }

  /**
   * 通知メッセージを表示する
   * @param type
   */
  const showNotifyMessage = (type: EXEC_TYPE): void => {
    setDialogTitle(getNotifyTitle(type))
    setDialogContents(getNotifyMessage(type, 'アップロードファイル'))
    setShowNotifyDialog(true)
  }

  /**
   *
   * @param type
   * @param info
   * @returns
   */
  const refresh = (type: EXEC_TYPE, info?: DownloadFileInfo) => {
    setOpenMode(type)

    if (type === EXEC_INSERT) {
      setOriginalUploadFile(null)

      setDownloadFileId(null)
      setPublicName('')
      setOriginalName('')
      setFilename('')
      setFilepath('')
      setIsSuspended(false)

      return
    }

    if (type === EXEC_UPDATE) {
      setOriginalUploadFile(info!)

      setDownloadFileId(info!.downloadFileId!)
      setPublicName(info!.publicName)
      setOriginalName(info!.originalName)
      setFilename(info!.fileName)
      setFilepath(info!.filePath)
      setIsSuspended(info!.isSuspended)
    }
  }

  /**
   *
   */
  const insert = () => {
    if (checkAll(EXEC_INSERT) === false) {
      showErrorMessage()
      return
    }
    setExecType(EXEC_INSERT)
    showConfirmMessage(EXEC_INSERT)
  }
  const doInsert = async () => {
    try {
      await insertDownloadFile(file!, publicName, originalName, isSuspended)
      showNotifyMessage(EXEC_INSERT)
    } catch (e: unknown) {
      // SessionTimeout処理
      if (gStates.handleSessionExpired(e)) return
      // 致命的エラー処理
      showFatalErrorMessage()
    }
  }

  /**
   *
   */
  const update = () => {
    if (checkAll(EXEC_UPDATE) === false) {
      showErrorMessage()
      return
    }
    setExecType(EXEC_UPDATE)
    showConfirmMessage(EXEC_UPDATE)
  }
  const doUpdate = async () => {
    try {
      await updateDownloadFile(downloadFileId!, file!, publicName, isSuspended)
      showNotifyMessage(EXEC_UPDATE)
    } catch (e: unknown) {
      // SessionTimeout処理
      if (gStates.handleSessionExpired(e)) return
      // 致命的エラー処理
      showFatalErrorMessage()
    }
  }

  /**
   *
   */
  const remove = () => {
    setExecType(EXEC_DELETE)
    showConfirmMessage(EXEC_DELETE)
  }
  const doRemove = async () => {
    try {
      await removeDownloadFile(downloadFileId!)
      showNotifyMessage(EXEC_DELETE)
    } catch (e: unknown) {
      // SessionTimeout処理
      if (gStates.handleSessionExpired(e)) return
      // 致命的エラー処理
      showFatalErrorMessage()
    }
  }

  const doCallback = async () => {
    if (!execType) throw new Error('実行種別が指定されていません。')
    if (execType === EXEC_INSERT) await doInsert()
    if (execType === EXEC_UPDATE) await doUpdate()
    if (execType === EXEC_DELETE) await doRemove()
  }

  /**
   *
   */
  const clear = () => {
    clearMsgs()
    if (openMode === EXEC_INSERT) {
      clearValues()
    }
    if (openMode === EXEC_UPDATE) {
      restoreValues()
    }
  }

  /**
   *
   * @param type
   * @returns
   */
  const checkAll = (type: EXEC_TYPE): boolean => {
    const resultAll: boolean[] = []
    resultAll.push(checkPublicName())
    // 新規登録時のチェック
    if (type === EXEC_INSERT) {
      resultAll.push(checkFile())
    }
    // 更新時のみチェック
    if (type === EXEC_UPDATE) {
      resultAll.push(checkOriginalName())
    }
    return resultAll.includes(false) === false
  }

  const checkPublicName = (): boolean => {
    // 必須入力
    if (hasNotValue(publicName)) {
      setMsg4PublicName(requiredMessage('公開ファイル名'))
      return false
    }
    // 桁数：50文字
    if (invalidMaxLength(publicName, 50)) {
      setMsg4PublicName(lengthLessThanMessage('公開ファイル名', 50))
      return false
    }
    return true
  }

  const checkOriginalName = (): boolean => {
    // 必須入力（更新時のみチェック。登録時はFileでチェック）
    if (hasNotValue(originalName)) {
      setMsg4File('更新するファイルを指定して下さい。')
      return false
    }
    return true
  }

  const checkFile = (): boolean => {
    // 必須入力
    if (hasNotValue(file)) {
      setMsg4File(requiredMessage('ファイル'))
      return false
    }
    return true
  }

  const clearValues = (): void => {
    setPublicName('')
    setFile(null)
    setOriginalName('')
    setFilename('')
    setFilepath('')
    setIsSuspended(false)
  }

  const restoreValues = (): void => {
    setPublicName(originalUploadFile!.publicName)
    setOriginalName(originalUploadFile!.originalName)
    setFilename(originalUploadFile!.fileName)
    setFilepath(originalUploadFile!.filePath)
    setIsSuspended(originalUploadFile!.isSuspended)
  }

  const clearMsgs = (): void => {
    setMsg4PublicName('')
    setMsg4File('')
    setMsg4IsSuspended('')
  }

  const globalStates = {
    publicName,
    filename,
    originalName,
    filepath,
    isSuspended,

    file,
    //
    msg4PublicName,
    msg4File,
    msg4IsSuspended,
    //
    dialogTitle,
    dialogContents,
    showNotifyDialog,
    showConfirmDialog,
    showErrorDialog,

    changePublicName,
    changeFilename,
    changeIsSuspended,
    changeFile,

    closeNotifyDialog,
    closeConfirmDialog,
    closeErrorDialog,

    refresh,
    insert,
    update,
    remove,
    clear,
    doCallback
  }
  return (
    <UploadFileStateContext.Provider value={globalStates}>
      {/*  */}
      {children}
    </UploadFileStateContext.Provider>
  )
}
