import 'App.css'
import { HelmetProvider } from 'react-helmet-async'
import { GlobalStateProvider } from 'components/common/provider/GlobalStateProvider'
import { LoginStateProvider } from 'components/common/provider/LoginStateProvider'
import CommonLayout from 'layouts/CommonLayout'

function App() {
  return (
    <LoginStateProvider>
      <HelmetProvider>
        <GlobalStateProvider>
          <CommonLayout />
        </GlobalStateProvider>
      </HelmetProvider>
    </LoginStateProvider>
  )
}

export default App
