import { Helmet } from 'react-helmet-async'
import FacilityReservationsForm from 'components/reservations/reservations/facility/FacilityReservationsForm'
import { FacilityReservationsStateProvider } from 'components/reservations/reservations/facility/FacilityReservationsStateProvider'
import { TITLE_RESERVE_BY_FACILITY } from 'utils/constants'

/**
 * 施設別予約状況一覧画面
 */
const FacilityReservations = () => {
  return (
    <FacilityReservationsStateProvider>
      <Helmet>
        <title>{TITLE_RESERVE_BY_FACILITY}</title>
      </Helmet>
      <FacilityReservationsForm />
    </FacilityReservationsStateProvider>
  )
}

export default FacilityReservations
