import Label from 'components/common/atoms/Label'
import OutputText from 'components/common/atoms/OutputText'
import ErrorDialog from 'components/common/dialogs/messages/ErrorDialog'
import PageTitle from 'components/common/PageTitle'
import { useLoginState } from 'components/common/provider/LoginStateProvider'
import { useContractorState } from 'components/contractors/contractor/ContractorStateProvider'
import { useEffect } from 'react'
import fStyles from 'styles/utils/form.module.scss'

interface ContractorFormReadonlyProps {}

/**
 *
 * @param props
 * @returns
 */
const ContractorFormReadonly = (props: ContractorFormReadonlyProps) => {
  const lState = useLoginState()
  const states = useContractorState()

  const contractorId = lState.getContractorId()
  useEffect(() => {
    if (contractorId === null) return
    states.refreshById(contractorId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contractorId])

  /**
   *
   */
  const closeErrorDialog = () => {
    states.closeErrorDialog()
  }

  return (
    <>
      <ErrorDialog
        title={states.dialogTitle}
        contents={states.dialogContents}
        show={states.showErrorDialog}
        onOk={closeErrorDialog}
      />
      <PageTitle title="契約者情報" />
      <div style={{ display: 'flex', justifyContent: 'space-around' }}>
        <section className={fStyles.detailForm}>
          <div className={fStyles.noMargin} />
          <div className={fStyles.row}>
            <span className={fStyles.label}>
              <Label title="契約名" />
            </span>
            <span className={fStyles.output}>
              <OutputText value={states.contractorName} width={400} />
            </span>
          </div>
          <div className={fStyles.row}>
            <span className={fStyles.label}>
              <Label title="ログインID" />
            </span>
            <span className={fStyles.output}>
              <OutputText value={states.loginId} width={300} />
            </span>
          </div>

          <div className={fStyles.row}>
            <span className={fStyles.label}>
              <Label title="パスワード" />
            </span>
            <span className={fStyles.output}>
              <OutputText value="（非表示）" width={300} />
            </span>
          </div>

          <div className={fStyles.row}>
            <span className={fStyles.label}>
              <Label title="ルームNo" />
            </span>
            <span className={fStyles.output}>
              <OutputText value={states.roomNo} align="right" width={70} />
              <span className={fStyles.appendLabel}>号室</span>
            </span>
          </div>
          <div className={fStyles.row}>
            <span className={fStyles.label}>
              <Label title="契約開始日" />
            </span>
            <span className={fStyles.output}>
              <OutputText value={states.usageStartDataString} align="center" width={180} />
            </span>
          </div>
          <div className={fStyles.row}>
            <span className={fStyles.label}>
              <Label title="契約終了日" />
            </span>
            <span className={fStyles.output}>
              <OutputText value={states.usageEndDataString} align="center" width={180} />
            </span>
          </div>
          <div className={fStyles.row}>
            <span className={fStyles.label}>
              <Label title="企業種別" />
            </span>
            <span className={fStyles.output}>
              <OutputText value={states.coopTypeString} width={150} />
            </span>
          </div>
          <div className={fStyles.row}>
            <span className={fStyles.label}>
              <Label title="担当メールアドレス" />
            </span>
            <span className={fStyles.output}>
              <OutputText value={states.email} width={400} />
            </span>
          </div>
          <div className={fStyles.row}>
            <span className={fStyles.label}>
              <Label title="担当者名" />
            </span>
            <span className={fStyles.output}>
              <OutputText value={states.name} width={400} />
            </span>
          </div>
          <div className={fStyles.row}>
            <span className={fStyles.label}>
              <Label title="担当電話番号" />
            </span>
            <span className={fStyles.output}>
              <OutputText value={states.tel} width={200} />
            </span>
          </div>
          <div style={{ margin: '20px 0 0 20px', color: 'red', fontSize: '20p' }}>
            情報訂正・パスワード不明などの際は、管理者へお問い合わせ下さい。
          </div>
        </section>
      </div>
    </>
  )
}

export default ContractorFormReadonly
