import { createContext, useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import ReactLoading from 'react-loading'

import { ProviderProps } from 'types/common/ProviderProps'
import { useLoginState } from './LoginStateProvider'

/**
 *
 */
interface StateProps {
  isLoading: boolean
  handleSessionExpired: (e: unknown) => boolean
  handleSessionExpiredCode: (code: number) => boolean
  startLoading: () => void
  stopLoading: () => void
}

/**
 *
 */
export const GlobalStateContext = createContext({} as StateProps)

/**
 *
 * @returns
 */
export const useGlobalState = () => useContext(GlobalStateContext)

/**
 *
 * @param props
 * @returns
 */
export const GlobalStateProvider = (props: ProviderProps) => {
  //
  const { children } = props
  const navigate = useNavigate()
  const lStates = useLoginState()

  //
  const [isLoading, setIsLoading] = useState(false)

  //
  useEffect(() => {
    setIsLoading(false)
  }, [])

  //
  const startLoading = () => {
    setIsLoading(true)
  }

  const stopLoading = () => {
    setIsLoading(false)
  }

  /**
   * SessionTimeoutエラーを処理する。
   * SessionTimeoutの場合、trueを返却する。
   * @param e
   * @returns
   */
  const handleSessionExpired = (e: unknown): boolean => {
    if (e instanceof Error && e.message === '999') {
      lStates.logout()
      navigate('/login?expired=true')
      return true
    }
    return false
  }

  /**
   * SessionTimeoutコードを処理する。
   * SessionTimeoutの場合、trueを返却する。
   * @param code
   * @returns
   */
  const handleSessionExpiredCode = (code: number): boolean => {
    if (code === 999) {
      navigate('/login?expired=true')
      return true
    }
    return false
  }

  //
  const globalStates = {
    isLoading,
    handleSessionExpired,
    handleSessionExpiredCode,
    startLoading,
    stopLoading
  }

  const loadingScreen = (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100%',
        position: 'absolute',
        top: '0px',
        left: '0px',
        backgroundColor: 'gray',
        opacity: '0.4',
        zIndex: '100'
      }}
      onClick={stopLoading}
    >
      <ReactLoading
        type="spin"
        color="yellow"
        height="100px"
        width="100px"
        className=""
      ></ReactLoading>
    </div>
  )

  if (isLoading) {
    return (
      <GlobalStateContext.Provider value={globalStates}>
        {/*  */}
        {loadingScreen}
        {children}
      </GlobalStateContext.Provider>
    )
  } else {
    return (
      <GlobalStateContext.Provider value={globalStates}>
        {/*  */}
        {children}
      </GlobalStateContext.Provider>
    )
  }
}
