import { findAll } from 'api/mailTemplates'
import { useGlobalState } from 'components/common/provider/GlobalStateProvider'
import { createContext, useContext, useEffect, useState } from 'react'
import { ProviderProps } from 'types/common/ProviderProps'
import { MailTemplateRow } from 'types/rows/MailTemplateRow'
import { MAIL_TEMPLATES } from 'utils/constants'
import { FATAL_ERROR_MESSAGE, FATAL_ERROR_TITLE } from 'utils/messageUtil'

/**
 *
 */
interface MailTemplatesState {
  //
  mailTemplateList: MailTemplateRow[]
  targetMailTemplate: MailTemplateRow | null
  isListEmpty: boolean
  //
  selectMailTemplate: (mailTemplate: MailTemplateRow) => void
  refresh: () => void
  dialogTitle: string
  dialogContents: string
  showErrorDialog: boolean
  closeErrorDialog: () => void
}

/**
 *
 */
export const MailTemplatesStateContext = createContext({} as MailTemplatesState)

/**
 *
 * @returns
 */
export const useMailTemplatesState = () => useContext(MailTemplatesStateContext)

/**
 *
 * @returns
 */
const MailTemplatesStateProvider = (props: ProviderProps) => {
  const { children } = props
  const gStates = useGlobalState()

  // 検索結果
  const [mailTemplateList, setMailTemplateList] = useState<MailTemplateRow[]>([])
  const [targetMailTemplate, setTargetMailTemplate] = useState<MailTemplateRow | null>(null)
  // 通知登録ダイアログ
  const [isShowDialog, setIsShowDialog] = useState<boolean>(false)
  const [dialogTitle, setDialogTitle] = useState<string>('')
  const [dialogContents, setDialogContents] = useState<string>('')
  const [showErrorDialog, setShowErrorDialog] = useState<boolean>(false)

  // 導出States
  const isListEmpty: boolean = mailTemplateList.length === 0

  useEffect(() => {
    refresh()
  }, [])

  const selectMailTemplate = (mailTemplate: MailTemplateRow): void => {
    setTargetMailTemplate(mailTemplate)
    setIsShowDialog(true)
  }

  /**
   *
   */
  const refresh = () => {
    findAll()
      .then((list: MailTemplateRow[]) => {
        const listWithTypeLabel = list.map((t) => {
          t.templateTypeLabel = MAIL_TEMPLATES[t.templateType]
          return t
        })
        setMailTemplateList(listWithTypeLabel)
      })
      .catch((e: unknown) => {
        // SessionTimeout処理
        if (gStates.handleSessionExpired(e)) return
        // システムエラーメッセージの表示
        showFatalErrorMessage()
      })
  }

  const closeErrorDialog = () => {
    setShowErrorDialog(false)
  }

  /**
   * システムエラーメッセージを表示する
   */
  const showFatalErrorMessage = (): void => {
    setDialogTitle(FATAL_ERROR_TITLE)
    setDialogContents(FATAL_ERROR_MESSAGE)
    setShowErrorDialog(true)
  }

  const globalStates = {
    mailTemplateList,
    targetMailTemplate,
    isListEmpty,
    selectMailTemplate,
    refresh,
    dialogTitle,
    dialogContents,
    showErrorDialog,
    closeErrorDialog
  }

  return (
    <MailTemplatesStateContext.Provider value={globalStates}>
      {children}
    </MailTemplatesStateContext.Provider>
  )
}

export default MailTemplatesStateProvider
