import { Routes, Route } from 'react-router-dom'

import Footer from 'components/common/Footer'
import Header from 'components/common/Header'
import Contractor from 'pages/Contractor'
import Login from 'pages/common/Login'
import ChangePassword from 'pages/common/ChangePassword'
import Top from 'pages/common/Top'
import DailyReservations from 'pages/reservation/DailyReservations'
import FacilityReservations from 'pages/reservation/FacilityReservations'
import UserReservations from 'pages/reservation/UserReservations'
import styles from 'layouts/CommonLayout.module.scss'

/**
 * 一般ユーザー用画面レイアウト
 */
const CommonLayout = () => {
  return (
    <>
      <header className={styles.header}>
        <Header />
      </header>
      <main className={styles.main}>
        <Routes>
          <Route path="/" element={<Top />}></Route>
          <Route path="/login" element={<Login />}></Route>
          <Route path="/change-password" element={<ChangePassword />}></Route>
          <Route path="/reservations" element={<UserReservations />}></Route>
          <Route path="/reservations/daily" element={<DailyReservations />}></Route>
          <Route path="/reservations/by-facility" element={<FacilityReservations />}></Route>
          <Route path="/contractor" element={<Contractor />}></Route>
        </Routes>
      </main>
      <footer className={styles.footer}>
        <Footer />
      </footer>
    </>
  )
}

export default CommonLayout
