import styles from './OutputText.module.scss'

interface OutputTextProps {
  value: string
  align?: 'left' | 'center' | 'right'
  width?: number
}
const OutputText = (props: OutputTextProps) => {
  const { value, align, width } = props
  const textAlign = align ?? 'left'
  const textWidth = width ?? 100
  return (
    <div className={styles.outputText} style={{ textAlign, width: `${textWidth}px` }}>
      {value}
    </div>
  )
}

export default OutputText
