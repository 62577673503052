/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from 'react'

import styles from 'components/common/atoms/InputFile.module.scss'
import { Tooltip } from 'react-tooltip'

/**
 *
 */
interface InputFileProps {
  id: string | number
  value: File | null
  fileName?: string | null
  onChange: (val: File | null) => void
  errorMsg?: string
}

/**
 *
 * @param props
 * @returns
 */
const InputFile = (props: InputFileProps) => {
  //
  const { id, value, fileName, onChange, errorMsg } = props
  //
  const [file, setFile] = useState<File | null>(null)

  // このコンポーネントの一意識別子
  const uniqueId = `input-file_${id}`
  const bgColor = !!errorMsg ? { backgroundColor: 'pink' } : {}
  const isShowTooltip = !errorMsg

  /**
   * ファイル変更イベントハンドラ
   * @param e
   */
  const selectInputFile = (e: any) => {
    const f = e.target.files[0]
    setFile(f)
    onChange(f)
  }

  /**
   * ファイル選択をクリアする。
   */
  const clear = () => {
    setFile(null)
    clearInput()
    onChange(null)
  }

  /**
   * <input>タグのvalueに対して、値を設定して<input>タグで保持している値を変更できない？
   * 以下の方法だとなぜかクリアできた
   */
  const clearInput = () => {
    const element = document.getElementById(`${uniqueId}`)! as HTMLInputElement
    element.value = ''
  }

  /**
   *
   */
  useEffect(() => {
    setFile(value)
  }, [value])

  return (
    <>
      <section
        className={styles.inputFile}
        data-tooltip-id={`input-file-tooltip_${id}`}
        style={bgColor}
      >
        <label className={styles.uploadFileContainer}>
          <input
            id={uniqueId}
            type="file"
            className={styles.inputFile}
            onChange={(event) => selectInputFile(event)}
          />
          ファイルを選択
        </label>
        <div className={styles.fileName}>{fileName ? fileName : file ? file!.name : '未選択'}</div>
        <div className={styles.clearButton} onClick={clear}>
          [ クリア ]
        </div>
      </section>
      <Tooltip
        id={`input-file-tooltip_${id}`}
        delayShow={300}
        hidden={isShowTooltip}
        place="top-start"
        style={{
          backgroundColor: 'lightgray',
          borderRadius: '10px',
          color: 'red'
        }}
      >
        <h3>{errorMsg}</h3>
      </Tooltip>
    </>
  )
}

export default InputFile
