import { Helmet } from 'react-helmet-async'
import { TITLE_ADMIN_TOP } from 'utils/constants'
const AdminTop = () => {
  return (
    <>
      <Helmet>
        <title>{TITLE_ADMIN_TOP}</title>
      </Helmet>
      管理者トップ
    </>
  )
}

export default AdminTop
