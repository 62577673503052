import { BsFillQuestionCircleFill } from 'react-icons/bs'

import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'

interface ConfirmDialogProps {
  title: string
  contents: string
  show: boolean
  onOk?: () => void
  onCancel?: () => void
}

/**
 *
 * @returns
 */
const ConfirmDialog = ({ title, contents, show, onOk, onCancel }: ConfirmDialogProps) => {
  /**
   *
   */
  const OK = () => {
    if (onOk) onOk()
  }
  /**
   *
   */
  const cancel = () => {
    if (onCancel) onCancel()
  }

  return (
    <Dialog
      open={show}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <div style={{ backgroundColor: '#ffffee' }}>
        <DialogTitle id="alert-dialog-title">
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <BsFillQuestionCircleFill
              style={{ color: '#ff8c00', width: '30px', height: '30px', marginRight: '10px' }}
            />
            {title}
          </div>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{contents}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={cancel}>キャンセル</Button>
          <Button onClick={OK}>OK</Button>
        </DialogActions>
      </div>
    </Dialog>
  )
}

export default ConfirmDialog
