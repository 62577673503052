import { useEffect, useState } from 'react'

import { useMailTemplateState } from './MailTemplateStateProvider'
import PrecedentsForm from './PrecedentsForm'

import fStyles from 'styles/utils/form.module.scss'
import ConfirmDialog from 'components/common/dialogs/messages/ConfirmDialog'
import ErrorDialog from 'components/common/dialogs/messages/ErrorDialog'
import NotifyDialog from 'components/common/dialogs/messages/NotifyDialog'
import { EXEC_TYPE } from 'types/common/ExecType'
import { EXEC_INSERT, EXEC_UPDATE } from 'utils/constants'
import Label from 'components/common/atoms/Label'
import InputText from 'components/common/atoms/InputText'
import TextArea from 'components/common/atoms/TextArea'
import OutputText from 'components/common/atoms/OutputText'
import { useWindowDimension } from 'utils/windowUtils'

interface MailTemplateRow {}

interface MailTemplateFormProps {
  execType: EXEC_TYPE
  mailTemplateInfo?: MailTemplateRow | null
  doClose?: () => void
}

/**
 *
 * @returns
 */
const MailTemplateForm = (props: MailTemplateFormProps) => {
  //
  const { execType, mailTemplateInfo, doClose } = props

  //
  const states = useMailTemplateState()
  const [winWidth] = useWindowDimension()

  const [isOpenPrecedent, setIsOpenPrecedent] = useState<boolean>(false)
  const [precedentCaption, setPrecedentCaption] = useState<string>('開く')
  const [precedentOpenState, setPrecedentOpenState] = useState({
    display: 'none',
    marginLeft: '5px'
  })
  const [textareaCols, setTextareaCols] = useState<number>(0)

  // 画面初期化
  useEffect(() => {
    // 新規登録時
    if (execType === EXEC_INSERT) {
      states.refresh(execType)
      return
    }

    // 更新時
    if (execType === EXEC_UPDATE) {
      states.refresh(execType, mailTemplateInfo!)
    }
  }, [])

  useEffect(() => {
    setPrecedentCaption(isOpenPrecedent ? '閉じる' : '開く')
    if (isOpenPrecedent) {
      setPrecedentOpenState({ marginLeft: '5px', display: 'block' })
    } else {
      setPrecedentOpenState({ marginLeft: '5px', display: 'none' })
    }
  }, [isOpenPrecedent])

  useEffect(() => {
    setTextareaCols(80)
  }, [winWidth])

  /**
   *
   */
  const doOnConfirmOk = async () => {
    await states.doCallback()
  }
  /**
   *
   */
  const doOnConfirmCancel = () => {
    states.closeConfirmDialog()
  }

  /**
   *
   */
  const closeErrorDialog = () => {
    states.closeErrorDialog()
  }
  /**
   *
   */
  const closeNotifyDialog = () => {
    states.closeNotifyDialog()
    doClose!()
  }

  const doOpenPrecedent = () => {
    setIsOpenPrecedent(() => !isOpenPrecedent)
  }

  return (
    <>
      <ConfirmDialog
        title={states.dialogTitle}
        contents={states.dialogContents}
        show={states.showConfirmDialog}
        onOk={doOnConfirmOk}
        onCancel={doOnConfirmCancel}
      />
      <ErrorDialog
        title={states.dialogTitle}
        contents={states.dialogContents}
        show={states.showErrorDialog}
        onOk={closeErrorDialog}
      />
      <NotifyDialog
        title={states.dialogTitle}
        contents={states.dialogContents}
        show={states.showNotifyDialog}
        onOk={closeNotifyDialog}
      />

      <section className={fStyles.detailForm}>
        <div className={fStyles.topMargin} />

        <div className={fStyles.row}>
          <span className={fStyles.label}>
            <Label title="テンプレート種別" />
          </span>
          <span className={fStyles.input}>
            <OutputText width={400} value={states.templateTypeLabel} />
          </span>
        </div>

        <div className={fStyles.row}>
          <span className={fStyles.label}>
            <Label title="タイトル" required />
          </span>
          <span className={fStyles.input}>
            <InputText
              id="title"
              size={50}
              maxLength={200}
              value={states.title}
              onChange={(event) => states.changeTitle(event.target.value)}
              errorMsg={states.msg4Title}
            />
          </span>
        </div>

        <div className={fStyles.row}>
          <span className={fStyles.label}>
            <Label title="本文" required />
          </span>
          <span className={fStyles.input}>
            <TextArea
              id="description"
              rows={18}
              cols={textareaCols}
              value={states.body}
              onChange={(event) => states.changeBody(event.target.value)}
              errorMsg={states.msg4Body}
            />
          </span>
        </div>
        <div className={fStyles.row}>
          <span className={fStyles.label}>
            <Label title="判例" />
            <div style={{ marginRight: '10px' }}>
              <button onClick={doOpenPrecedent}>{precedentCaption}</button>
            </div>
          </span>
          <span className={fStyles.input} style={precedentOpenState}>
            <PrecedentsForm />
          </span>
        </div>
        <div className={fStyles.footerButtons}>
          <button className={fStyles.button} onClick={states.update}>
            更新
          </button>
          <button className={fStyles.button} onClick={states.restore}>
            元に戻す
          </button>
        </div>
      </section>
    </>
  )
}

export default MailTemplateForm
