import { createContext, useContext, useEffect, useState } from 'react'

import { findAll } from 'api/facilities'
import { useGlobalState } from 'components/common/provider/GlobalStateProvider'
import { ProviderProps } from 'types/common/ProviderProps'
import { FacilityInfo } from 'types/infos/FacilityInfo'
import { FATAL_ERROR_MESSAGE, FATAL_ERROR_TITLE } from 'utils/messageUtil'

/**
 *
 */
interface FacilitiesState {
  facilityList: FacilityInfo[]
  targetFacility: FacilityInfo | null
  nextDisplayOrder: number
  isListEmpty: boolean
  //
  selectFacility: (facility: FacilityInfo) => void
  refresh: () => void
  dialogTitle: string
  dialogContents: string
  showErrorDialog: boolean
  closeErrorDialog: () => void
}

/**
 *
 */
export const FacilitiesStateContext = createContext({} as FacilitiesState)

/**
 *
 * @returns
 */
export const useFacilitiesState = () => useContext(FacilitiesStateContext)

/**
 *
 * @param props
 * @returns
 */
export const FacilitiesStateProvider = (props: ProviderProps) => {
  const { children } = props
  const gStates = useGlobalState()

  // State
  const [facilityList, setFacilityList] = useState<FacilityInfo[]>([])
  const [targetFacility, setTargetFacility] = useState<FacilityInfo | null>(null)
  const [nextDisplayOrder, setNextDisplayOrder] = useState<number>(0)
  const [dialogTitle, setDialogTitle] = useState<string>('')
  const [dialogContents, setDialogContents] = useState<string>('')
  const [showErrorDialog, setShowErrorDialog] = useState<boolean>(false)

  const isListEmpty: boolean = facilityList.length === 0

  useEffect(() => {
    refresh()
  }, [])

  const selectFacility = (facility: FacilityInfo) => {
    setTargetFacility(facility)
  }

  const refresh = () => {
    findAll() //
      .then((list) => {
        setFacilityList(list)
        if (list.length === 0) {
          setNextDisplayOrder(1)
        } else {
          setNextDisplayOrder(Math.max(...list.map((e) => e.displayOrder)) + 1)
        }
      })
      .catch((e: unknown) => {
        // SessionTimeout処理
        if (gStates.handleSessionExpired(e)) return
        // システムエラーメッセージの表示
        showFatalErrorMessage()
      })
  }

  const closeErrorDialog = () => {
    setShowErrorDialog(false)
  }

  /**
   * システムエラーメッセージを表示する
   */
  const showFatalErrorMessage = (): void => {
    setDialogTitle(FATAL_ERROR_TITLE)
    setDialogContents(FATAL_ERROR_MESSAGE)
    setShowErrorDialog(true)
  }

  const globalStates = {
    facilityList,
    targetFacility,
    nextDisplayOrder,
    isListEmpty,
    selectFacility,
    refresh,
    dialogTitle,
    dialogContents,
    showErrorDialog,
    closeErrorDialog
  } as FacilitiesState

  return (
    <FacilitiesStateContext.Provider value={globalStates}>
      {/*  */}
      {children}
    </FacilitiesStateContext.Provider>
  )
}
