import tStyles from 'styles/utils/table.module.scss'

/**
 *
 */
interface EmptyListProps {
  isEmpty: boolean
  msg?: string | null
}

/**
 *
 * @param param0
 * @returns
 */
const EmptyList = ({ isEmpty, msg }: EmptyListProps) => {
  const displayNone = isEmpty ? tStyles.isShow : ''
  const displayMsg = msg ?? 'データは登録されていません'
  return <div className={`${tStyles.emptyList} ${displayNone}`}>{displayMsg}</div>
}

export default EmptyList
