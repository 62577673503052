import { Helmet } from 'react-helmet-async'
import NotificationsForm from 'components/notifications/notifications/NotificationsForm'
import { NotificationsStateProvider } from 'components/notifications/notifications/NotificationsStateProvider'
import { TITLE_FACILITY_NOTIFICATION } from 'utils/constants'

const Notifications = () => {
  return (
    <NotificationsStateProvider>
      <Helmet>
        <title>{TITLE_FACILITY_NOTIFICATION}</title>
      </Helmet>
      <NotificationsForm />
    </NotificationsStateProvider>
  )
}

export default Notifications
