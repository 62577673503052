import { createContext, useContext } from 'react'
import { useAtom } from 'jotai'
import { atomWithStorage } from 'jotai/utils'

import { LoginContractorInfo } from 'types/infos/LoginInfo'
import { ProviderProps } from 'types/common/ProviderProps'
import { logout as logoutUser } from 'api/contractors'
import { USER_TYPE_ADMIN } from 'utils/constants'

/**
 * ログイン情報グローバルStateContextのプロパティ定義.
 */
interface StateProp {
  isLogin?: boolean
  loginContractorId?: number
  loginUserType?: number
  // loginLoginId: string
  loginContractorName?: string
  loginName?: string
  loginEmail?: string
  loginTel?: string
  loginRoomNo?: string
  loginUsageStartDate?: Date
  loginUsageEndDate?: Date
  loginCoopType?: number
  loginIsSuspended?: boolean

  isAdmin: () => boolean

  getContractorId: () => number
  setContractorInfo: (info: LoginContractorInfo | null) => void

  logout: () => void
}

/**
 * 「ログイン」情報グローバルStateContext.
 * ログイン成功時に情報を取得し、ここに格納される.
 */
export const LoginStateContext = createContext({} as StateProp)

/**
 * 「ログイン」情報グローバルStateを外部に公開するためのfunction.
 * @returns StatePropインスタンス
 */
export const useLoginState = () => useContext(LoginStateContext)

// ---------- localStorage連携設定 ----------
const loginStateAtom = atomWithStorage<boolean>('isLogin', false)
const loginContractorIdAtom = atomWithStorage<number>('loginContractorId', -1)
const loginUserTypeAtom = atomWithStorage<number>('loginUserType', 0)
// const loginLoginIdAtom = atomWithStorage<string>('loginLoginId', '')
const loginContractorNameAtom = atomWithStorage<string>('loginContractorName', '')
const loginRoomNoAtom = atomWithStorage<string>('loginRoomNo', '')
const loginNameAtom = atomWithStorage<string>('loginName', '')
const loginEmailAtom = atomWithStorage<string>('loginEmail', '')
const loginTelAtom = atomWithStorage<string>('loginTel', '')
const loginUsageStartDateAtom = atomWithStorage<Date>('loginUsageStartDate', new Date())
const loginUsageEndDateAtom = atomWithStorage<Date>('loginUsageEndDate', new Date())
const loginCoopTypeAtom = atomWithStorage<number>('loginCoopType', 0)
const loginIsSuspendedAtom = atomWithStorage<boolean>('loginIsSuspended', false)

/**
 * 「ログイン」情報グローバルStateProvider.
 * @param props ProviderProps
 * @returns
 */
export const LoginStateProvider = (props: ProviderProps) => {
  // 呼出元から渡される本Providerタグで囲まれたタグ群
  const { children } = props

  // --【ステート】------------------------------------------
  // ログインStateは、LocalStorageと連携して保持する（ブラウザリロード対応のため）
  const [isLogin, setIsLogin] = useAtom(loginStateAtom)
  const [loginContractorId, setContractorId] = useAtom(loginContractorIdAtom)
  const [loginUserType, setUserType] = useAtom(loginUserTypeAtom)
  const [loginContractorName, setContractorName] = useAtom(loginContractorNameAtom)
  // const [loginLoginId, setLoginId] = useAtom(loginLoginIdAtom)
  const [loginRoomNo, setRoomNo] = useAtom(loginRoomNoAtom)
  const [loginName, setName] = useAtom(loginNameAtom)
  const [loginEmail, setEmail] = useAtom(loginEmailAtom)
  const [loginTel, setTel] = useAtom(loginTelAtom)
  const [loginUsageStartDate, setUsageStartDate] = useAtom(loginUsageStartDateAtom)
  const [loginUsageEndDate, setUsageEndDate] = useAtom(loginUsageEndDateAtom)
  const [loginCoopType, setCoopType] = useAtom(loginCoopTypeAtom)
  const [loginIsSuspended, setIsSuspended] = useAtom(loginIsSuspendedAtom)

  // 導出ステート
  const isAdmin = (): boolean => {
    if (isLogin === false) return false
    return loginUserType === USER_TYPE_ADMIN
  }

  const getContractorId = (): number => {
    return loginContractorId
  }

  // --【State一括更新】------------------------------------------

  /**
   *
   * @param info
   * @returns
   */
  const setContractorInfo = (info: LoginContractorInfo | null) => {
    //
    if (info === null) {
      clearContractorState()
      return
    }

    setIsLogin(true)
    setContractorId(info.contractorId)
    setUserType(info.userType)
    // setLoginId(info.loginId)
    setContractorName(info.contractorName)
    setRoomNo(info.roomNo)
    setUsageStartDate(info.usageStartDate)
    setUsageEndDate(info.usageEndDate)
    setCoopType(info.coopType)
    setName(info.name)
    setTel(info.tel)
    setEmail(info.email)
    setIsSuspended(info.isSuspended)
  }

  // --【イベントハンドラ】------------------------------------------

  /**
   * ログアウト処理
   */
  const logout = async () => {
    try {
      const result = await logoutUser()
      clearContractorState()
      //
      setIsLogin(false)
    } catch (e: unknown) {
      throw e
    }
  }

  /**
   *
   */
  const clearContractorState = () => {
    setContractorId(() => -1)
    setUserType(() => 0)
    setContractorName(() => '')
    setRoomNo(() => '')
    setName(() => '')
    setEmail(() => '')
    setTel(() => '')
    setUsageStartDate(() => new Date())
    setUsageEndDate(() => new Date())
    setCoopType(() => 0)
    setIsSuspended(() => false)
  }

  // --【出力処理】------------------------------------------

  const globalStates: StateProp = {
    isLogin,
    loginContractorId,
    loginUserType,
    // loginLoginId,
    loginContractorName,
    loginName,
    loginEmail,
    loginTel,
    loginRoomNo,
    loginUsageStartDate,
    loginUsageEndDate,
    loginCoopType,
    loginIsSuspended,

    isAdmin,

    getContractorId,
    setContractorInfo,

    logout
  }

  return (
    <LoginStateContext.Provider value={globalStates}>
      {/*  */}
      {children}
    </LoginStateContext.Provider>
  )
}
