import { Helmet } from 'react-helmet-async'
import UploadFilesForm from 'components/uploads/uploadFiles/UploadFilesForm'
import { UploadFilesStateProvider } from 'components/uploads/uploadFiles/UploadFilesStateProvider'
import { TITLE_MANAGE_FILE } from 'utils/constants'

const UploadsFile = () => {
  return (
    <UploadFilesStateProvider>
      <Helmet>
        <title>{TITLE_MANAGE_FILE}</title>
      </Helmet>
      <UploadFilesForm />
    </UploadFilesStateProvider>
  )
}

export default UploadsFile
